import ApiCall from "./ApiCall";
import Axios from "axios";
import routesPath from "../values/routesPath";
import { CODES } from "../values/translate";
// import { BASE_URL } from "../env";

const API = Axios.create({
    baseURL: `${window.BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});
class authDAO {
    login = (params, data) => new Promise(async (resolve, reject) => {
        try {
            const response = await API.post(`/auth/sessions`, data, { params: params, });
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    logout = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.delete(`/auth/sessions`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    setAuthenticatedUser = (params, data) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.patch(`/auth/sessions`, params, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    authenticatedUser = (params) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await API.get(`/account`, params);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    changePassword = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await ApiCall.post(`/account/passwords`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    passwordRecovery = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await API.post(`/auth/password-recovery`, params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });
    passwordReset = (params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await API.post("/auth/password-reset", params, data);
                resolve(response);
            } catch (error) {
                reject(error);
            }
        });


}
export default new authDAO();