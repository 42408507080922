import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async";
import "./style.css";
import homeDAO from '../../dao/homeDAO';
import { connect } from 'react-redux';
import { BsDashCircleFill } from "react-icons/bs";
import PropTypes from 'prop-types';
import Highlighter from "react-highlight-words";
import { StartSortGlobalStrings } from '../../helpers/sort';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
    selectedItems: [],
    lastSelectedItem: [],
    error: null,
    inputText: "",

}


class MarketViewSelectItemsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
            selectValue: [],
        };
    }
    componentDidMount() {
        this.preload()
    }
    preload = () => {
        this.getAll();
        this.setError();
        this.getValue();
    }
    setError = () => {
        this.setState({ error: this.props.error })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setError();
            this.getValue();
        }
    }
    getValue = () => {
        const { searchMarketView } = this.props;
        this.setState({ selectValue: searchMarketView?.items });

    }
    render() {
        const { data, error } = this.state;
        const { onChange, searchItems } = this.props;
        const { isCreatable, onClear, label, valueKey } = this.props;
        return (
            <Form.Group className="SelectItemsInput__container" controlId="formBasicEmail" >
                {isCreatable && <button onClick={onClear} className="SelectItemsInput__clear-btn">
                    <BsDashCircleFill size={16} />
                </button>}
                <Form.Label>{label}</Form.Label>
                <AsyncSelect
                    isLoading={this.state.loading}
                    inputValue={this.state.inputText}
                    onInputChange={(Value) => { this.setState({ inputText: Value }) }}
                    formatOptionLabel={(option) => {
                        return (
                            <Highlighter
                                highlightClassName="SelectItemsInput__hight-lighter"
                                searchWords={[this.state.inputText]}
                                autoEscape={true}
                                textToHighlight={option.label}
                            />
                        )
                    }}
                    // isDisabled={this.state.loading}
                    value={this.state.selectValue}
                    defaultValue={this.state.selectValue}
                    name="items"
                    loadOptions={this.getAll}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sélectionner"
                    onChange={(selectedOption) => {
                        onChange(selectedOption);
                        this.refreshData(null, () => { }, selectedOption);
                    }}
                    defaultOptions={data?.map((item, index) => ({ value: item.id, label: item.name }))}
                    isClearable
                />
                {error && <span className="SelectItemsInput__error">{error?.message ?? ""}</span>}
            </Form.Group>
        );
    }

    getAll = async (inputValue = null, callback = () => { }) => {
        try {
            const { additionalParams } = this.props;
            this.setState({ loading: true, data: [] })

            let params = {
                ...additionalParams,
            };
            // console.log("this.getExcludedItems()", this.getExcludedItems())
            // let params = {
            //     excluded_items: this.getExcludedItems(),
            // };
            if (inputValue) {
                params = {
                    q: inputValue,
                    ...params,
                }
            }
            const response = await homeDAO.getItemsList(params)
            // this.setState({ data: StartSortGlobalStrings(response?.data?.data?.filter((item, index) => { if (item?.isActive) { return item } }), "name", "ASC") ?? [] }, () => {
            //     callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            // })
            this.setState({ data: StartSortGlobalStrings(response?.data?.data, "name", "ASC") ?? [] }, () => {
                callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            })
            this.setState({ loading: false })
        } catch (error) {
            this.setState({ loading: false })
        }
    }
    refreshData = async (inputValue = null, callback = () => { }, selectedOption) => {
        try {
            const { additionalParams } = this.props;
            let excluded_items_string = selectedOption?.reduce((accumulator, item) => item.value + "," + accumulator, "").slice(0, -1)
            let params = {
                excluded_items: excluded_items_string,
                ...additionalParams,
            };
            // let params = {
            //     excluded_items: this.getExcludedItems(),
            // };
            if (inputValue) {
                params = {
                    q: inputValue,
                    ...params,
                }
            } else {
                params = {
                    q: "",
                    ...params,
                }
            }

            this.setState({ loading: true })
            const response = await homeDAO.getItemsList(params)
            // this.setState({ data: StartSortGlobalStrings(response?.data?.data?.filter((item, index) => { if (item?.isActive) { return item } }), "name", "ASC") ?? [] }, () => {
            //     callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            // })
            this.setState({ data: StartSortGlobalStrings(response?.data?.data, "name", "ASC") ?? [] }, () => {
                callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            })
            this.setState({ loading: false })
        } catch (error) {
            this.setState({ loading: false })
        }
    }
}

const mapStateToProps = (state) => {
    return ({
        comparisonItems: state.SEARCH_ITEMS.searchItems.comparisonItems,
        searchItems: state.SEARCH_ITEMS.searchItems,
        searchMarketView: state.SEARCH_MARKET_VIEW.searchMarketView,
    })
}

MarketViewSelectItemsInput.propTypes = {
    onChange: PropTypes.func,
    searchItems: PropTypes.array,
    isCreatable: PropTypes.bool,
    onClear: PropTypes.func,
    type: PropTypes.oneOf(["comparison", "normal"]),
    label: PropTypes.string,
};
MarketViewSelectItemsInput.defaultProps = {
    onChange: () => { },
    searchItems: [],
    isCreatable: false,
    onClear: () => { },
    type: "normal",
    label: "Item(s)",
};

export default connect(mapStateToProps)(MarketViewSelectItemsInput);


