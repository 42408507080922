import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import authService from "../services/authService";
import routesPath from "../values/routesPath";
import DAheader from "../components/DAheader/DAheader";
import HashLoader from "react-spinners/HashLoader";

class ProtectedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isAuthenticated: true,
        };
    }
    componentWillMount() {
        this.checkAuthentication();
    }
    checkAuthentication = async () => {
        try {
            this.setState({ loading: true, isAuthenticated: false })
            await authService.preloadDataAfterAuthentication();
            this.setState({ loading: false, isAuthenticated: true });
        } catch (error) {
            console.log(error)
            this.setState({ loading: false, isAuthenticated: false });
        }
    }
    render() {
        const { loading, isAuthenticated } = this.state;
        const { CustomComponent } = this.props;
        if (loading) {
            return <div style={{ display: "flex", height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center" }}>
                <HashLoader color={"#337ab7"} loading={true} size={100} />
            </div>
        } else {
            if (isAuthenticated) {
                return (
                    <Route exact {...this.props} render={(props) => {
                        return (
                            <div>
                                <DAheader />
                                <CustomComponent {...props}></CustomComponent>
                            </div>
                        );
                    }}
                    />
                );
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: routesPath.Login.list,
                            state: {
                                from: this.props.location,
                            },
                        }}
                    />
                );
            }
        }
    }
}
export default ProtectedRoute;

