import React, { Component } from 'react'
import DAlogo from './images/DAlogo.png'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import './Login.css'
import { BsFillEyeFill } from 'react-icons/bs'
import { IconContext } from "react-icons";
import FlashMessage from 'react-flash-message'
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import * as yup from 'yup';
import messageText from '../../../values/messageText'
import authDAO from '../../../dao/authDAO'
import routesPath from '../../../values/routesPath'
import Logo from './images/864c6fd8e948bd6df5410250e2502cf7.png'
import HashLoader from "react-spinners/HashLoader";


let loginValidationSchema = yup.object().shape({
    email: yup.string().required(messageText.Login.validationMessages.requiredEmail).email(messageText.Login.validationMessages.emailValid),
    password: yup.string().required(messageText.Login.validationMessages.requiredPassword),
});
const ERROR_DURATION = 5000;
const FIELDS = {
    email: "",
    password: "",
    error: "",
    loading: false,
}
export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...FIELDS,
            type: 'password',
        }
        this.clearErrorTimeOut = undefined;
    }

    handleClick = () => this.setState(({ type }) => ({
        type: type === 'text' ? 'password' : 'text'
    }))
    render() {
        const { email, password, error, loading } = this.state;
        return (
            <div className="windowContainer">
                <div className="loginCountainer">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
                        <img src={window.APP_PARAMS?.logoURL?.length !== 0 ? window.APP_PARAMS?.logoURL : Logo} style={{ width: 40, height: "auto" }} /><h1 style={{ textAlign: "center", margin: 0, marginLeft: 12 }}>{window.APP_PARAMS?.appTitle ?? ""}</h1>
                    </div>
                    <Form>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control onKeyDown={this.handleKeyDown.bind(this)} value={email} onChange={(text => this.setState({ email: text.target.value }))} type="email" placeholder="Email ici …" />
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Mot de passe</Form.Label>
                            <div className="Login__password-input-container">
                                <Form.Control onKeyDown={this.handleKeyDown.bind(this)} value={password} onChange={(text => this.setState({ password: text.target.value }))} type={this.state.type} placeholder="Mot de passe ici …" />
                                <span className="password__show login__password-show" onClick={this.handleClick}>
                                    <IconContext.Provider value={{ color: "#E0E0E6", size: '20px' }}>
                                        <BsFillEyeFill />
                                    </IconContext.Provider>
                                </span>
                            </div>
                            <div className="forgetPwd">
                                <a href='#'>Mot de passe oublié ?</a>
                            </div>

                        </Form.Group>
                        <Button disabled={loading} className={loading ? "login-btn-loading" : ""} onClick={this.onSubmit} variant="primary" type="button">
                            {loading ? <HashLoader color={"#fff"} loading={true} size={20} /> : "Se connecter"}
                        </Button>
                    </Form>
                    {error?.length !== 0 && <FlashMessage duration={ERROR_DURATION} >
                        <div className="flashMsgCount">
                            <div className="exclamationCount">
                                <IconContext.Provider value={{ color: "#FF5050", size: '45px' }}>
                                    <BsFillExclamationCircleFill />
                                </IconContext.Provider>
                            </div>
                            <p>{error}</p>
                        </div>
                    </FlashMessage>}
                </div>
            </div>
        )
    }
    handleKeyDown(e) {
        if (e.key === "Enter") {
            this.onSubmit();
        }
    }
    onSubmit = () => {
        const { email, password } = this.state;
        loginValidationSchema.validate({
            email: email,
            password: password,
        }).then(async (values) => {
            try {
                const data = {
                    ...values,
                };
                const params = {}
                this.setState({ loading: true });
                const response = await authDAO.login(params, data);
                if (response) {
                    window.location.pathname = routesPath.Home.list
                    // this.props.history.push(routesPath.Home.list)
                }
                this.setState({ loading: false });

            } catch (error) {
                this.setState({ error: error?.response?.data?.errorMessage ?? "" }, this.clearError)
                this.setState({ loading: false });
            }

        }).catch(err => this.setState({ loading: false, error: err?.errors?.length > 0 ? err?.errors[0] : "" }, this.clearError));
    }
    clearError = () => {
        this.clearErrorTimeOut !== undefined && clearTimeout(this.clearErrorTimeOut);
        this.clearErrorTimeOut = setTimeout(() => this.setState({ error: "" }), ERROR_DURATION)
    }
}
