
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReduser";
import searchItemsReducer from "./reducers/searchItemsReducer";
import dataAnalyticsReducer from "./reducers/dataAnalyticsReducer";
import locationReducer from "./reducers/locationReducer";
import comparisonDataAnalyticsReducer from "./reducers/comparisonDataAnalyticsReducer";
import sellersStatsByLocation from "./reducers/sellersStatsByLocation";
import searchMarketViewReducer from "./reducers/searchMarketViewReducer";
import marketViewReducer from "./reducers/marketViewReducer";
import savedSearchReducer from "./reducers/savedSearchReducer";

const rootReducer = combineReducers({
    AUTH: authReducer,
    SEARCH_ITEMS: searchItemsReducer,
    DATA_ANALYTICS: dataAnalyticsReducer,
    LOCATION: locationReducer,
    COMPARISON_DATA: comparisonDataAnalyticsReducer,
    SELLERS_STATS: sellersStatsByLocation,
    SEARCH_MARKET_VIEW: searchMarketViewReducer,
    MARKET_VIEW: marketViewReducer,
    SAVED_SEARCH: savedSearchReducer
});
export default createStore(rootReducer, applyMiddleware(thunk));