import ApiCall from "./ApiCall";
import routesPath from "../values/routesPath";
import { CODES } from "../values/translate";
import { getOrderByName } from "../services/helpersServices";

class savedSearchDAO {

    getAll = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/saved-searches`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getElementById = (id, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/users/${id}`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    add = (params, data) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.post(`/saved-searches`, params, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })

    delete = (id, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.delete_2(`/saved-searches/${id}`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })

    disable = (id) => new Promise(async (resolve, reject) => {
        try {
            let data = {
                "data": {
                    "user": {
                        "status": "inactive"
                    }
                }
            }
            const response = await ApiCall.patch(`/users/${id}`, {}, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    enable = (id) => new Promise(async (resolve, reject) => {
        try {
            let data = {
                "data": {
                    "user": {
                        "status": "active"
                    }
                }
            }
            const response = await ApiCall.patch(`/users/${id}`, {}, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })

}
export default new savedSearchDAO();