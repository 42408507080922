import React, { Component } from 'react'
import './DAsearchResult.css'
import { Table } from 'react-bootstrap'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import $ from "jquery";
import { connect } from 'react-redux';
import { StartSortNumbers, StartSortStrings, StartSortTotalNumbers } from '../../helpers/sort';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import GroupeItems from '../groupeItems/GroupeItems';



class ComparisonDAsearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstRow: 0,
            pageTable: 1,
            orderBy: 0,
            key: "",
        }
    }



    componentDidMount() {
        $(document).on('click', '#tableBtnForward', (e) => {
            let x = this.state.firstRow + 5
            let y = this.state.pageTable + 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
        $(document).on('click', '#tableBtnBack', (e) => {
            let x = this.state.firstRow - 5
            let y = this.state.pageTable - 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    sortNumbers() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortNumbers(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC") } } })
    }
    sortStrings() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortStrings(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC") } } })
    }
    sortTotalNumber() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortTotalNumbers(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC", tableDataAnalytics) } } })
    }
    getThOrder = (paramKey) => {
        const { key, orderBy } = this.state;
        if (key.toLocaleLowerCase() === paramKey.toLocaleLowerCase()) {
            if (orderBy === -1) {
                return "up"
            } else if (orderBy === 1) {
                return "down"
            } else {
                return "none"
            }
        } else {
            return "none"
        }
    }
    getThActive = (paramKey) => {
        const { key, orderBy } = this.state;
        return key.toLocaleLowerCase() === paramKey.toLocaleLowerCase() && (orderBy === 1 || orderBy === -1)
    }
    render() {
        const { tableDataAnalytics, comparisonTableDataAnalytics, groupeItems } = this.props;
        const { key, orderBy } = this.state;
        function numberWithSpaces(x) {
            if (x && x !== 0) {
                var parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        function floatWithSpaces(x) {
            if (x && x !== 0) {
                let parsedX = parseFloat(x).toFixed(2)
                var parts = parsedX.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        const { searchItems } = this.state;
        let analyticsTypeIsValue = (searchItems && searchItems?.analyticsType && searchItems?.analyticsType?.toLocaleLowerCase() === "value" ? true : false);
        return (
            <div className="DAresearchResultCard">
                <h1 className="DAresearchResultCard-title">
                    <span>{`Résultat de recherche`}</span>
                    <GroupeItems />
                </h1>
                {/* <h1>{`Résultat de recherche${groupeItems?.name ? " (Groupe: " + groupeItems?.name + ")" : ""}`}</h1> */}
                <hr />
                <div className="tableFixHead" style={{ padding: '0% 2%', }}>
                    <Table striped bordered   >
                        <thead>
                            <tr>
                                <col />
                                {
                                    comparisonTableDataAnalytics?.header?.map((item, index) => (
                                        <>
                                            <Th colspan="3" order={this.getThOrder("name")} isActive={this.getThActive("name")} onClick={() => this.setState({ key: "name", orderBy: this.getOrderBy() }, this.sortStrings)}>{
                                                item?.items?.map((item2, index) => <span>{item2?.label + (index !== (item?.items?.length - 1) ? " - " : "")}</span>)
                                            }</Th>
                                        </>

                                    ))
                                }
                            </tr>

                            <tr>
                                <Th order={this.getThOrder("name")} isActive={this.getThActive("name")} onClick={() => this.setState({ key: "name", orderBy: this.getOrderBy() }, this.sortStrings)}>zone</Th>
                                {
                                    comparisonTableDataAnalytics?.header?.map((item, index) => (
                                        <>
                                            <Th order={this.getThOrder("period_va")} isActive={this.getThActive("period_va")} onClick={() => this.setState({ key: "period_va", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA (N)</Th>
                                            <Th order={this.getThOrder("lastPeriod_va")} isActive={this.getThActive("lastPeriod_va")} onClick={() => this.setState({ key: "lastPeriod_va", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA (N-1)</Th>
                                            <Th order={this.getThOrder("period_evol_percent")} isActive={this.getThActive("period_evol_percent")} onClick={() => this.setState({ key: "period_evol_percent", orderBy: this.getOrderBy() }, this.sortNumbers)}>Evol (%)</Th>
                                        </>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                <td style={{ backgroundColor: '#EBEBED', textAlign: "left" }}>Total*</td>
                                {
                                    comparisonTableDataAnalytics?.header?.map((item, index) => (
                                        <>
                                            <td>{numberWithSpaces(comparisonTableDataAnalytics?.total && comparisonTableDataAnalytics?.total[index] ? comparisonTableDataAnalytics?.total[index]?.period_va ?? 0 : 0) ?? 0}</td>
                                            <td>{numberWithSpaces(comparisonTableDataAnalytics?.total && comparisonTableDataAnalytics?.total[index] ? comparisonTableDataAnalytics?.total[index]?.lestPeriod_va ?? 0 : 0) ?? 0}</td>
                                            <td>{`${comparisonTableDataAnalytics?.total && comparisonTableDataAnalytics?.total[index] ? comparisonTableDataAnalytics?.total[index]?.period_evol_percent ?? 0 : 0}%`}</td>
                                        </>
                                    ))
                                }
                            </tr>
                            {
                                comparisonTableDataAnalytics?.body?.map((item, index) => {
                                    return (
                                        <tr key={item?.location_id} style={{ textAlign: 'right' }}>
                                            <td style={{ backgroundColor: '#EBEBED', textAlign: "left" }}>{`${item?.location?.name} (${item?.location?.id})`}</td>
                                            {
                                                comparisonTableDataAnalytics?.header.map((item2, index) => (
                                                    <>
                                                        <td>{numberWithSpaces(item?.items && item?.items[index] ? item?.items[index]?.period_va ?? 0 : 0)}</td>
                                                        <td>{numberWithSpaces(item?.items && item?.items[index] ? item?.items[index]?.lastPeriod_va ?? 0 : 0)}</td>
                                                        <td>{`${item?.items && item?.items[index] ? item?.items[index]?.period_evol_percent ?? 0 : 0}%`}</td>
                                                    </>
                                                ))
                                            }
                                        </tr>
                                    );

                                })
                            }
                            {/* <tr className="paginationRow" style={{ textAlign: 'right' }}>
                                <td className="paginationCol" colSpan="10" style={{ border: '0px' }}>
                                    <div className="paginationCountainer">
                                        {this.state.firstRow == 0
                                            ? <button disabled className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                            : <button className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                        }

                                        <div className="tableNbr">{this.state.pageTable}</div>

                                        {resData.slice(this.state.firstRow, this.state.firstRow + 5).length < 5
                                            ? <button disabled className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                            : <button className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                        }
                                    </div>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        comparisonTableDataAnalytics: state.COMPARISON_DATA.comparisonTableDataAnalytics,
    })
}
export default connect(mapStateToProps)(ComparisonDAsearchResult);


const Th = ({ order, isActive, children, ...attributes }) => {
    return (
        <th className={`Th text-left`} {...attributes}>
            {children}
        </th>

    )
}