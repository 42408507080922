import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import moment from 'moment';
import { connect } from 'react-redux';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
}

class SemesterSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
            targetedYear: undefined,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.setState({ targetedYear: this.props.targetedYear }, this.getAll)
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.targetedYear !== this.props?.targetedYear) {
            this.preload();
        }
    }
    render() {
        const { data } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Semestre</Form.Label>
                <SearchSelect
                    name="SemesterSearchSelect"
                    options={data}
                    defaultValue={searchItems?.date?.semester}
                    value={searchItems?.date?.semester}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        const { targetedYear } = this.state;
        if (targetedYear) {
            let startDateFirstSemester = moment(`${targetedYear}-01-01`);
            let startDateSecondSemester = moment(`${targetedYear}-01-01`);
            let firstSemester = { startDate: startDateFirstSemester.format("YYYY-MM-DD"), endDate: startDateFirstSemester.add(6, "month").subtract(1, "day").format("YYYY-MM-DD") }
            let secondSemester = { startDate: startDateSecondSemester.add(6, "month").format("YYYY-MM-DD"), endDate: startDateSecondSemester.add(6, "month").subtract(1, "day").format("YYYY-MM-DD") }
            this.setState({ data: [firstSemester, secondSemester].map((item, index) => ({ value: item, label: `${item?.startDate} / ${item?.endDate}` })) })
        } else {
            this.setState({ data: [] })

        }

    }
}

const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(SemesterSearchSelect);
