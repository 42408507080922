import axios from "axios";
import axiosRetry from "axios-retry";
import HttpErrors from "./HttpErrors";
import authDAO from "./authDAO";
import routesPath from "../values/routesPath";
import { CODES } from "../values/translate";
// import { BASE_URL } from "../env";
import { getCookie } from "../services/helpersServices";

const API = axios.create({
    baseURL: `${window.BASE_URL}/`,
    responseType: "json",
    withCredentials: true,
});
API.all = axios.all

// Response interceptor for API calls
// axiosRetry(API, { retryDelay: axiosRetry.exponentialDelay });
// axiosRetry(API, { retries: 3 });


API.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error?.config;
        if (401 === error?.response?.status) {
            try {
                const response = await authDAO.logout({});
                localStorage.clear();
                window.location.pathname = routesPath.Login.list;
            } catch (error) {

            }
        }
        return Promise.reject(error);
    }
);

API.interceptors.request.use(async function (config) {
    config.headers["X-CSRF-Token"] = getCookie("csrfToken");
    return config;
});

const HEADER = {
    "Content-type": "application/json",
};

class ApiCall {
    constructor(props) { }
    static get = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.get(url, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static get_v2 = (url, params) => {
        return API.get(url, {
            params: params,
            headers: HEADER,
        });
    }
    static all = (array) => {
        return API.all(array);
    }

    static post = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.post(url, data, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static patch = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.patch(url, data, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });

    static upload = (url, params, data) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "multipart/form-data",
                };
                let response = await API.post(url, data, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static delete = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                const header = {
                    "Content-type": "multipart/form-data",
                };
                let response = await API.delete(url, {
                    params: params,
                    headers: header,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
    static delete_2 = (url, params) =>
        new Promise(async (resolve, reject) => {
            try {
                let response = await API.delete(url, {
                    params: params,
                    headers: HEADER,
                });
                resolve(response);
            } catch (error) {
                handleError(error, reject);
            }
        });
}

const handleError = (error, reject) => {
    if (error.response) {
        reject({ response: false, request: false, message: error.errorMessage });
    } else if (error.request) {
        reject({ response: false, request: true, message: "Aucune Reponse reçu, merci de vérifier que vous êtes bien connecté à l'internet" });
    } else {
        reject({ response: false, request: false, message: error.errorMessage });
    }
};

export default ApiCall;