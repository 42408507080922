
const initialState = {
    regions: [],
    subRegions: [],
    sectors: [],

    allRegions: [],
    allSubRegions: [],
    allSectors: [],
};

function locationReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_REGIONS":
            nextState = {
                ...state,
                regions: action.value.regions,
            };
            return nextState || state;
        case "SET_SUB_REGIONS":
            nextState = {
                ...state,
                subRegions: action.value.subRegions,
            };
            return nextState || state;
        case "SET_SECTORS":
            nextState = {
                ...state,
                sectors: action.value.sectors,
            };
            return nextState || state;


        case "SET_ALL_REGIONS":
            nextState = {
                ...state,
                allRegions: action.value.regions,
            };
            return nextState || state;
        case "SET_ALL_SUB_REGIONS":
            nextState = {
                ...state,
                allSubRegions: action.value.subRegions,
            };
            return nextState || state;
        case "SET_ALL_SECTORS":
            nextState = {
                ...state,
                allSectors: action.value.sectors,
            };
            return nextState || state;

        default:
            return state;
    }
}
export default locationReducer;