export const DAheaderStyle = {
    headerStyle: () => {
        return {
            padding: '10px',
            paddingTop: '0',
            height: '10px',
        }
    },
    logoStyle: () => {
        return {
            height: '30px',
            width: '30px',
            marginRight: '10px',
            marginTop: '7px',
        }
    },
    leftHeader: () => {
        return {
            display: 'inline-flex',
            float: 'left',
            marginTop: '10px'
        }
    },
    headerTitle: () => {
        return {
            margin: '0',
            marginTop: '10px',
            fontSize: '20px',
        }
    },
    navicons: () => {
        return {
            height: '55px',
            marginRight: '5%',
            padding: '17px 15px  5px',
        }
    },
    middleHeader: () => {
        return {
            float: 'left',
            marginLeft: '5%',
            width: '40%'
        }
    },
    rightHeader: () => {
        return {
            float: 'right',
            width: '15%',
            marginTop: '10px',

        }
    },
    avatarHeader: () => {
        return {
            height: '40px',
        }
    },
    notifHader: () => {
        return {
            height: '35px',
            marginRight: '10px',
        }
    },
}