import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import moment from 'moment';
import { connect } from 'react-redux';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
}

class monthSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
            targetDate: undefined,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.setState({ targetedDate: this.props.targetedDate }, this.getAll)
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.targetedDate !== this.props?.targetedDate) {
            this.preload();
        }
    }
    render() {
        const { data } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Mois</Form.Label>
                <SearchSelect
                    name="monthSearchSelect"
                    options={data}
                    defaultValue={searchItems?.date?.month}
                    value={searchItems?.date?.month}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        const { targetedDate } = this.props;
        if (targetedDate) {
            let startDate = moment(targetedDate?.startDate);
            let endDate = moment(targetedDate?.endDate);
            // let quarter = [];
            // while (endDate.diff(startDate, 'month') >= 0) {
            //     quarter.push({ startDate: startDate.format("YYYY-MM-DD"), endDate: startDate.add(1, 'month').subtract(1, "day").format("YYYY-MM-DD") })
            //     startDate.add(1, 'day');
            // }
            var timeValues = [];

            while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
                timeValues.push({ startDate: startDate.format("YYYY-MM-DD"), endDate: startDate.add(1, 'month').subtract(1, "day").format("YYYY-MM-DD") });
                startDate.add(1, 'day');
            }
            this.setState({ data: timeValues.map((item, index) => ({ value: item, label: `${moment(item?.startDate).format("MMMM")}` })) })
        } else {
            this.setState({ data: [] })
        }
    }
}

const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(monthSearchSelect);


