import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import { Form } from 'react-bootstrap'
import "./style.css";
import { connect } from 'react-redux';
import homeDAO from '../../dao/homeDAO';
import { StartSortGlobalStrings } from '../../helpers/sort';

const INITIAL_FIELDS = {
    data: [],
    loading: false,
}
class GroupeType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.getAll();
        this.setError();
    }
    setError = () => {
        this.setState({ error: this.props.error })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setError();
        }
    }
    render() {
        const { data, value, error } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Type de groupe</Form.Label>
                <SearchSelect
                    name="GroupeType"
                    options={StartSortGlobalStrings(data?.map((item, index) => ({ value: item?.id, label: item?.name ?? "" })), "label", "ASC")}
                    defaultValue={searchItems?.groupeType}
                    value={searchItems?.groupeType}
                    onChange={onChange}
                />
                {error && <span className="SelectItemsInput__error">{error?.message ?? ""}</span>}
            </Form.Group>
        );
    }
    getAll = async () => {
        try {
            let params = {};
            this.setState({ loading: true })
            const response = await homeDAO.getGroupeTypes(params);
            this.setState({ loading: false })
            this.setState({ data: response?.data?.data ?? [] });
            // default item must be the first one
            let firstItem = { value: response?.data?.data[0]?.id, label: response?.data?.data[0]?.name ?? "" }
            this.props.dispatch({ type: "SET_GROUPE_TYPES", value: { groupeType: firstItem } });
            //end  default item must be the first one

        } catch (error) {
            this.setState({ loading: false })
        }
    }
}


const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(GroupeType);

