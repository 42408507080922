
import moment from "moment";


const initialState = {
    searchMarketView: {
        date: {
            year: { value: moment().format("YYYY"), label: moment().format("YYYY") },
            semester: null,
            quarter: null,
            month: null,
            startDate: moment().subtract(1, 'year'),
            endDate: moment(),
        },
        sellerType: undefined,
        items: undefined,
        filterType: undefined,
        groupe: undefined,
    }
};

function searchMarketViewReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_FILTER_TYPE_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    filterType: action?.value?.filterType
                }
            };
            return nextState || state;
        case "SET_GROUPE_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    groupe: action?.value?.groupe
                }
            };
            return nextState || state;
        case "SET_ALL_SEARCH_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: action?.value?.searchMarketView
            };
            return nextState || state;
        case "RESET_ALL_SEARCH_MARKET_VIEW":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        case "SET_SELLER_TYPES_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    sellerType: action?.value?.sellerType
                }
            };
            return nextState || state;
        case "SET_ITEMS_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    items: action?.value?.items
                }
            };
            return nextState || state;
        case "SET_DATE_YEAR_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        year: action?.value?.year
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_SEMESTER_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        semester: action?.value?.semester
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_QUARTER_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        quarter: action?.value?.quarter
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_MONTH_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        month: action?.value?.month
                    }
                }
            };
            return nextState || state;

        case "SET_START_DATE_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        startDate: action?.value?.startDate
                    }
                }
            };
            return nextState || state;
        case "SET_END_DATE_MARKET_VIEW":
            nextState = {
                ...state,
                searchMarketView: {
                    ...state.searchMarketView,
                    date: {
                        ...state.searchMarketView.date,
                        endDate: action?.value?.endDate
                    }
                }
            };
            return nextState || state;
        default:
            return state;
    }
}
export default searchMarketViewReducer;
