import React from 'react';
import "./style.css";

const MapMarker = ({ children, bgColor, ...rest }) => {
    return (
        <div style={{ backgroundColor: bgColor ? bgColor : "" }} className="MapMarker__container">
            {children}
            <span style={{ backgroundColor: bgColor ? bgColor : "" }} className="MapMarker__bottom-flesh"></span>
        </div>
    );
}

export default MapMarker;
