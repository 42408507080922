// import { APP_PARAMS } from "../env";

class appConfig {
    setFavicon = () => {

    }
    setHeadTitle = () => {
        // document.querySelector("title")?.textContent = "test"
        document.querySelector("title").textContent = window.APP_PARAMS?.headTitle
        const favIcon = document.getElementById('favicon');
        favIcon.setAttribute("href", window.APP_PARAMS?.faviconURL)
    }
    setUiConfig = () => {
        this.setFavicon();
        this.setHeadTitle();
    }
}

export default new appConfig();