
const initialState = {
    mapDataAnalytics: undefined,
    loadingMapDataAnalytics: false,
    tableDataAnalytics: undefined,
    loadingTableDataAnalytics: false,
    loadingDataAnalytics: false,
    groupeItems: undefined,
    noGroupData: false,
};

function dataAnalyticsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_NO_GROUP_DATA_ANALYTICS":
            nextState = {
                ...state,
                noGroupData: action.value.noGroupData,
            };
            return nextState || state;
        case "SET_MAP_DATA_ANALYTICS":
            nextState = {
                ...state,
                mapDataAnalytics: action.value.mapDataAnalytics,
            };
            return nextState || state;
        case "RESET_DATA_ANALYTICS":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        case "SET_GROUPE_ITEMS":
            nextState = {
                ...state,
                groupeItems: action.value.groupeItems,
            };
            return nextState || state;
        case "SET_TABLE_DATA_ANALYTICS":
            nextState = {
                ...state,
                tableDataAnalytics: action.value.tableDataAnalytics,
            };
            return nextState || state;
        case "SET_LOADING_DATA_ANALYTICS":
            nextState = {
                ...state,
                loadingDataAnalytics: action.value.loadingDataAnalytics,
            };
            return nextState || state;
        case "SET_LOADING_TABLE_DATA_ANALYTICS":
            nextState = {
                ...state,
                loadingTableDataAnalytics: action.value.loadingTableDataAnalytics,
            };
            return nextState || state;
        case "SET_LOADING_MAP_DATA_ANALYTICS":
            nextState = {
                ...state,
                loadingMapDataAnalytics: action.value.loadingMapDataAnalytics,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default dataAnalyticsReducer;