import React, { Component } from 'react';
import "./SelectInput.css";
import Select from 'react-select';
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { Field, ErrorMessage } from "formik";
import { HiXCircle } from "react-icons/hi";

const customStyle = {
    loadingIndicator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    container: (provided, state) => ({
        ...provided,
        minWidth: "159px",
        width: "100%"
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: 15,
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: "#dadada",
        boxShadow: "none",
        backgroundColor: "transparent",
        borderRadius: 20.5,
        height: 40,
        ":hover": {
            borderColor: "#dadada",
        },

    }),
    input: (provided, state) => ({
        ...provided,
        fontFamily: "inherit",
        fontWeight: "400",
        fontSize: 12,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected && "#f4f4f4",
        color: state.isSelected && "#66787f",
        fontFamily: "inherit",
        fontWeight: "400",
        fontSize: 12,
        ":hover": {
            color: "#66787f",
            backgroundColor: "#f4f4f4",
        },
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        paddingRight: 0,
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        paddingLeft: 0,
    }),
}

class SelectInput extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { options, onChange, value, placeholder, label, id, name, disabled } = this.props;
        return (
            <Form.Group controlId="formBasicNom">
                <Form.Label>{label}</Form.Label>
                <Select
                    isDisabled={disabled ?? false}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    options={options}
                    styles={customStyle}
                />
                <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
                {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
            </Form.Group>

        );
    }
}
const Error = ({ message }) => (
    <div className="error-area">
        <HiXCircle style={{ marginRight: 2 }} color="#da3025" size={17} />
        <span className="error-message">{message}</span>
    </div>
);
export default SelectInput;