export const DAmapStyle = {
    mapCard: () => {
        return {
            backgroundColor: 'white',
            padding: '5px',
            borderRadius: '11px',
            width: '97%',
            boxShadow: ' 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
            textAlign: 'left',
            margin: '3% 1% 0 2%',
        }
    },
    mapTitle: () => {
        return {
            fontSize: '20px',
            padding: '0 0 0 10px',
            display: 'inline-block',
        }
    },
}