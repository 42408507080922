import React from 'react'
import FlashMessage from 'react-flash-message'
import { FaCheck } from 'react-icons/fa'
import { GrClose } from 'react-icons/gr'
import { IconContext } from 'react-icons'
import './FlashMsg.css'

export default function FlashMsg(props) {
    return (
        <FlashMessage duration={2000} >
            <div className="flashMsgContainer">
                <div>
                    <IconContext.Provider value={{ color: "#fff" }}>
                        <FaCheck />
                    </IconContext.Provider>
                </div>
                <p>{props?.message ?? ""}</p>
                <button onClick={props.flashMsgHandler}>
                    <IconContext.Provider value={{ color: "#1BBAAC" }}>
                        <GrClose />
                    </IconContext.Provider>
                </button>
            </div>
        </FlashMessage>
    )
}
