import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { BsFillEyeFill } from 'react-icons/bs'
import { HiXCircle } from "react-icons/hi";
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { IconContext } from "react-icons";

import "./Input.css";

const Input = ({ id, className, name, required, label, type, placeholder, disabled, ...props }) => {
    const [hidePassword, setHidePassword] = useState(true);
    if (type?.toLocaleLowerCase() === "password") {
        return (
            <Form.Group controlId="formBasicNom">
                <Form.Label>{label}</Form.Label>
                <div style={{ position: "relative" }}>
                    <Field disabled={disabled ?? false} placeholder={placeholder ?? ""} id={id} className={`input NUinput ${className}`} type={hidePassword ? type : "text"} name={name ?? "input"} required={required ?? false} />
                    <button className="btn password__show clickable" onClick={() => setHidePassword(!hidePassword)}>
                        <IconContext.Provider value={{ color: "#E0E0E6" }}>
                            <BsFillEyeFill />
                        </IconContext.Provider>
                    </button>
                    <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
                </div>
                {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
            </Form.Group>
        );
    }
    if (type && type.toLocaleLowerCase() === "textarea") {
        return (
            <Form.Group controlId="formBasicNom">
                <Form.Label>{label}</Form.Label>
                <Field disabled={disabled ?? false} as="textarea" placeholder={placeholder ?? ""} id={id} className={`textarea NUinput ${className}`} type={type} name={name ?? "input"} required={required ?? false} />
                <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
                {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
            </Form.Group>
        )
    }
    return (
        <Form.Group controlId="formBasicNom">
            <Form.Label>{label}</Form.Label>
            <Field disabled={disabled ?? false} placeholder={placeholder ?? ""} id={id} className={`input NUinput ${className}`} type={type} name={name ?? "input"} required={required ?? false} {...props} />
            <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
            {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
        </Form.Group>
    );
};
const Error = ({ message }) => (
    <div className="error-area">
        <HiXCircle style={{ marginRight: 2 }} color="#da3025" size={17} />
        <span className="error-message">{message}</span>
    </div>
);
export default Input;
