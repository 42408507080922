
const initialState = {
    loadingMarketView: false,
    tableMarketView: [],
    statsMarketView: undefined,
    metadataMarketView: undefined,
}

function marketViewReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_META_DATA_MARKET_VIEW":
            nextState = {
                ...state,
                metadataMarketView: action.value.metadataMarketView,
            };
            return nextState || state;
        case "SET_TABLE_MARKET_VIEW":
            nextState = {
                ...state,
                tableMarketView: action.value.tableMarketView,
            };
            return nextState || state;
        case "RESET_MARKET_VIEW":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        case "SET_LOADING_MARKET_VIEW":
            nextState = {
                ...state,
                loadingMarketView: action.value.loadingMarketView,
            };
            return nextState || state;
        case "SET_STATS_MARKET_VIEW":
            nextState = {
                ...state,
                statsMarketView: action.value.statsMarketView,
            };
            return nextState || state;

        default:
            return state;
    }
}
export default marketViewReducer;