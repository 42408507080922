
import React, { Component } from 'react';
import "./MarketViewRepport .css"
import DAsearch from '../../components/DAsearch/DAsearch';
import DAmap from '../../components/DAmap/DAmap';
import ComparisonDAsearchResult from '../../components/ComparisonDAsearchResult/ComparisonDAsearchResult';
import ComparisonDAsearch from '../../components/DAsearch/ComparisonDAsearch';
import { connect } from 'react-redux';
import dataAnalyticsService from '../../services/dataAnalyticsService';
import MarketViewDAsearch from '../../components/DAsearch/MarketViewDAsearch';
import MarketViewDAsearchResult from '../../components/MarketViewDAsearchResult/MarketViewDAsearchResult';


class MarketViewRepport extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.configuration();
    }
    preload = () => {
        this.getAll();
    }
    configuration = () => {
        // clear all redux data for not affect other pages 
        this.props.dispatch({ type: "RESET_ALL_SEARCH_ITEMS", value: {} });
        this.props.dispatch({ type: "RESET_COMPARISON_TABLE_DATA_ANALYTICS", value: {} });
        dataAnalyticsService.setSearchFiltersFromParamsToRedux(dataAnalyticsService.comparisonSearch);

    }
    render() {
        return (
            <div className="Home__container">
                <div style={{ display: 'flex' }}>
                    <MarketViewDAsearch type="comparison" />
                </div>
                <MarketViewDAsearchResult />
            </div>
        );
    }
    getAll = async () => {

    }
}
const mapStateToProps = (state) => {
    return {}
}
export default connect(mapStateToProps)(MarketViewRepport);