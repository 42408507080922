import React, { Component, useState, useEffect } from 'react'
import { DAsearchStyle } from './DAsearchStyle'
import { BsPlus } from "react-icons/bs";
import './DAsearch.css'
import Modal from 'react-modal';
import SearchForm from './SearchForm/SearchForm'
import { connect } from 'react-redux';
import homeDAO from '../../dao/homeDAO';
import DateRangePicker from '../dateRangePicker';
import moment from "moment";
import SellerType from '../sellerType/SellerType';
import HashLoader from "react-spinners/HashLoader";
import marketViewService from "../../services/marketViewService";
import messageText from '../../values/messageText';
import MarketViewDatePicker from '../marketViewDatePicker';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SavedSearchForm from '../savedSearchForm/SavedSearchForm';
import { IconContext } from "react-icons";
import { FaRegTrashAlt } from 'react-icons/fa'
import savedSearchDAO from '../../dao/savedSearchDAO';
import savedSearchService from '../../services/savedSearchService';
import { notify } from '../flashMessage/FlashMessage';
import ee from "event-emitter";
import { Row, Col, Container } from 'react-bootstrap'
import SelectItemsInput from '../selectItemsInput/SelectItemsInput';
import MarketViewSelectItemsInput from '../marketViewSelectItemsInput/MarketViewSelectItemsInput';
import MarketViewGroupeType from '../marketViewGroupeType';
import MarketViewGroupes from '../marketViewGroupes';


const FIELDS = {
    loading: false,
    selectSellerTypeError: null,
    filter: "infos",
}
class MarketViewDAsearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearchModalOpen: false,
            setSearchModalIsOpen: false,
            initialSearchItemsValue: undefined,

            ...FIELDS,
        }
    }

    openSearchModeal = () => {
        document.querySelector("body").style.overflowY = "scroll"
        const { searchItems } = this.props;
        this.setState({
            setSearchModalIsOpen: true,
            initialSearchItemsValue: searchItems
        })
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        }, () => this.props.dispatch({ type: "SET_ALL_SEARCH_ITEMS", value: { searchItems: this.state.initialSearchItemsValue } }))
    }

    componentDidMount() {
    }

    selectSellerType = (selectedOption) => {
        const { selectSellerTypeError } = this.state;
        // if (selectSellerTypeError) this.setState({ selectSellerTypeError: null })
        this.props.dispatch({ type: "SET_SELLER_TYPES_MARKET_VIEW", value: { sellerType: selectedOption } })
    }

    setEndDate = (value) => {
        this.props.dispatch({ type: "SET_END_DATE_MARKET_VIEW", value: { endDate: value } });
    }
    setStartDate = (value) => {
        this.props.dispatch({ type: "SET_START_DATE_MARKET_VIEW", value: { startDate: value } })
    }
    selectItems = (selectedOption) => {
        this.props.dispatch({ type: "SET_ITEMS_MARKET_VIEW", value: { items: selectedOption } });
    }

    onSubmit = () => {
        const { searchMarketView } = this.props;
        // if (searchMarketView?.sellerType && searchMarketView?.sellerType?.value && searchMarketView?.sellerType?.value?.length !== 0) {
        try {
            this.setState({ loading: true });
            marketViewService.getMarketView(() => {
                this.setState({ loading: false });
            }, null);
        } catch (error) {
            this.setState({ loading: false });
        }
        // } else {
        //     if (!searchMarketView?.sellerType || !searchMarketView?.sellerType?.value || searchMarketView?.sellerType?.value?.length === 0) {
        //         this.setState({ selectSellerTypeError: { message: messageText?.Home?.searchRequiredItem } })
        //     }
        // }

    }
    render() {
        const { loadingMarketView } = this.props;
        const { searchMarketView } = this.props;
        const { date } = this.props.searchMarketView;
        const { loading, selectSellerTypeError } = this.state;
        const { filter } = this.state;

        return (
            <div className="searchCard ComparisonDAsearch__container MarketViewDAsearch-container" style={DAsearchStyle.searchCard()}>
                <div className="searchTitleCountainer">
                    <h2 style={DAsearchStyle.searchTitle()}>{filter === "mySavedSearch" ? "Mes recherches enregistrées" : "Recherche actuelle"}</h2>
                </div>
                <div className="BtnSearchModalCountainer BtnSearchModalCountainer-comparison">
                    {/* <button type="button" onClick={() => this.setState({ filter: filter === "mySavedSearch" ? "infos" : "mySavedSearch" })} className="btn btn-link">{filter === "mySavedSearch" ? "Recherche actuelle" : "Mes recherches enregistrées"}</button> */}
                </div>
                {filter === "infos" && <div>
                    <hr />
                    <Formik initialValues={{}} validationSchema={Yup.object({})} >
                        {/* login form */}
                        {
                            (formik) => {
                                return (
                                    <Form>
                                        <div style={{ display: 'flex', paddingLeft: '2%', }}>
                                            <div className="month-picker" style={{ width: '20%', padding: 7 }}>
                                                <MarketViewDatePicker
                                                    label="Mois"
                                                    selected={new Date(Date.parse(date?.startDate))}
                                                    onChange={this.setStartDate}
                                                    showMonthYearPicker={true}
                                                    dateFormat="MMMM"
                                                />

                                            </div>
                                            <div style={{ width: '20%', padding: 7 }}>
                                                <MarketViewDatePicker
                                                    label="Année"
                                                    selected={new Date(Date.parse(date?.endDate))}
                                                    onChange={this.setEndDate}
                                                    showYearPicker={true}
                                                    dateFormat="yyyy"

                                                />
                                                {/* <DateRangePicker
                                                label="Période de référence"
                                                onChange={this.setComparisonDate}
                                                value={date?.comparisonDate}
                                            /> */}

                                            </div>

                                            <div style={{ width: '20%', padding: 7 }}>
                                                <SellerType
                                                    onChange={this.selectSellerType}
                                                    error={null}
                                                />
                                            </div>

                                            <button type="button" style={{ marginTop: 22, marginLeft: 10 }} disabled={loading} onClick={this.onSubmit} id="submit">{loading ? <HashLoader color={"#fff"} loading={true} size={20} /> : "Appliquer"}</button>
                                            {/* <div style={{ marginTop: 22, marginLeft: 10 }}>
                                                <SavedSearchForm openSearchModeal={() => this.setState({ filter: "infos" })} type={"marketView"} />
                                            </div> */}
                                        </div>
                                        <div style={{ display: 'flex', paddingLeft: '2%', }}>
                                            <div style={{ width: '20%', padding: 7 }}>
                                                <MarketViewGroupeType
                                                    onChange={this.selectFilterType}
                                                    error={null}
                                                />
                                            </div>
                                            <div style={{ width: '20%', padding: 7 }}>
                                                {searchMarketView?.filterType?.value === "0" && <MarketViewSelectItemsInput
                                                    type={"normal"}
                                                    onChange={this.selectItems}
                                                    error={null}
                                                    additionalParams={{
                                                        onlyActiveItems: 0
                                                    }}
                                                />}
                                                {searchMarketView?.filterType?.value !== "0" && <MarketViewGroupes
                                                    onChange={this.selectGroup}
                                                    error={null}
                                                />}

                                            </div>

                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                    <hr className="hrNoMargging" />
                </div>}
                {filter === "mySavedSearch" && <SavedSearch />}
            </div>
        )
    }
    selectFilterType = (selectedOption, ) => {
        this.props.dispatch({ type: "SET_FILTER_TYPE_MARKET_VIEW", value: { filterType: selectedOption } })
    }
    selectGroup = (selectedOption, ) => {
        this.props.dispatch({ type: "SET_GROUPE_MARKET_VIEW", value: { groupe: selectedOption } })
    }
}

const mapStateToProps = (state) => {
    return ({
        searchMarketView: state.SEARCH_MARKET_VIEW.searchMarketView,
        loadingMarketView: state.MARKET_VIEW.loadingMarketView,
        ...state,
    })
}

export default connect(mapStateToProps)(MarketViewDAsearch);




const emitter = new ee();

export const refreshSavedSearchMarketView = () => {
    emitter.emit("refreshSavedSearchMarketView", {});
};

const SavedSearch = ({ ...props }) => {

    const [data, setData] = useState([]);
    useEffect(() => {
        emitter.on("refreshSavedSearchMarketView", ({ }) => {
            getData();
        });
        getData();
        return () => {

        };
    }, []);

    const getData = async () => {
        try {
            const response = await savedSearchDAO.getAll();
            setData(response?.data?.data ? response?.data?.data?.filter((item) => (item?.report === "marketView")).reverse() : []);
        } catch (error) {
            console.log(error)
        }
    }
    const affectSavedSearch = (item) => {
        savedSearchService.affectSavedSearchMarketView(item, () => {
            notify({ msg: messageText?.Common?.affectedSavedSearch, type: "success" });
        })
        props.openSearchModeal();
    }
    return (
        <div className="my-saved-search">
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                {
                    data.map((item, index) => (
                        <Col className="my-saved-search-col">
                            <SavedSearchItem affectSavedSearch={affectSavedSearch} refreshData={getData} key={`saved-search-${index}`} data={item} />
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
}

const SavedSearchItem = ({ data, affectSavedSearch, ...props }) => {
    return (
        <div className="my-saved-search-item-container my-saved-search-item-container-comparison">
            <span onClick={() => affectSavedSearch(data)} className="my-saved-search-item-title">{data?.label ?? ""}</span>
            <div>
                <IconContext.Provider value={{ color: "#FF5050", size: '15px' }}>
                    <button style={{ marginLeft: 30 }} onClick={() => savedSearchService.deleteSearch(data?.id, () => {
                        notify({ msg: messageText?.Common?.deleteSavedSearch, type: "success" });
                        props.refreshData();
                    })} className="deleteUserTable clickable"><FaRegTrashAlt /></button>
                </IconContext.Provider>
            </div>
        </div>
    );
}