import React, { Component } from 'react';
import { Form, Col } from 'react-bootstrap'
import AsyncSelect from "react-select/async";
import "./style.css";
import homeDAO from '../../dao/homeDAO';
import { connect } from 'react-redux';
import { BsDashCircleFill } from "react-icons/bs";
import PropTypes from 'prop-types';
import Highlighter from "react-highlight-words";
import { StartSortGlobalStrings } from '../../helpers/sort';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
    selectedItems: [],
    lastSelectedItem: [],
    error: null,
    inputText: "",

}


class SelectItemsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
            selectValue: [],
        };
    }
    componentDidMount() {
        this.preload()
    }
    preload = () => {
        this.getAll();
        this.setError();
        this.getValue();
    }
    setError = () => {
        this.setState({ error: this.props.error })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setError();
        }
        if (prevProps.searchItems?.comparisonItems !== this.props.searchItems?.comparisonItems) {
            this.getValue();
        }
        if (prevProps.searchItems?.items !== this.props.searchItems?.items) {
            this.getValue();
        }
        if (prevProps.searchItems?.groupeType !== this.props.searchItems?.groupeType) {
            this.refreshData(null, () => { }, [])
        }
    }
    getValue = () => {
        const { searchItems, type } = this.props;
        if (type === "comparison") {
            const { valueKey } = this.props;
            this.setState({ selectValue: searchItems?.comparisonItems?.filter((item, index) => item?.key === parseInt(valueKey))[0]?.items });
        } else if (type === "normal") {
            this.setState({ selectValue: searchItems?.items });
        } else {
            this.setState({ selectValue: [] });
        }
    }
    render() {
        const { data, error } = this.state;
        const { onChange, searchItems } = this.props;
        const { isCreatable, onClear, label, valueKey } = this.props;
        return (
            <Form.Group className="SelectItemsInput__container" controlId="formBasicEmail" >
                {isCreatable && <button onClick={onClear} className="SelectItemsInput__clear-btn">
                    <BsDashCircleFill size={16} />
                </button>}
                <Form.Label>{label}</Form.Label>
                <AsyncSelect
                    isLoading={this.state.loading}
                    inputValue={this.state.inputText}
                    onInputChange={(Value) => { this.setState({ inputText: Value }) }}
                    formatOptionLabel={(option) => {
                        return (
                            <Highlighter
                                highlightClassName="SelectItemsInput__hight-lighter"
                                searchWords={[this.state.inputText]}
                                autoEscape={true}
                                textToHighlight={option.label}
                            />
                        )
                    }}
                    // isDisabled={this.state.loading}
                    value={this.state.selectValue}
                    defaultValue={this.state.selectValue}
                    isMulti
                    name="items"
                    loadOptions={this.getAll}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Sélectionner"
                    onChange={(selectedOption) => {
                        onChange(selectedOption, () => this.refreshData(null, () => { }, selectedOption));
                    }}
                    defaultOptions={data?.map((item, index) => ({ value: item.id, label: item.name, groups: item?.groups ?? [] }))}
                />
                {error && <span className="SelectItemsInput__error">{error?.message ?? ""}</span>}
            </Form.Group>
        );
    }

    getAll = async (inputValue = null, callback = () => { }) => {
        try {
            const { additionalParams } = this.props;
            this.setState({ loading: true, data: [] })
            const { searchItems, comparisonItems } = this.props;

            let params = {
                onlyActiveItems: 1,
                excluded_items: this.state.selectValue.reduce((accumulator, item) => item.value + "," + accumulator, ""),
                ...additionalParams,
            };
            // console.log("this.getExcludedItems()", this.getExcludedItems())
            // let params = {
            //     excluded_items: this.getExcludedItems(),
            // };
            if (inputValue) {
                params = {
                    q: inputValue,
                    ...params,
                }
            }
            if (searchItems?.groupeType?.value) {
                params = {
                    groupType: searchItems?.groupeType?.value,
                    ...params,
                }
                if (searchItems?.groups && searchItems?.groups?.length !== 0) {
                    let filterItem = searchItems?.groups?.filter((item, index) => parseInt(item?.type) === parseInt(searchItems?.groupeType?.value));
                    if (filterItem?.length !== 0) {
                        params = {
                            groupId: filterItem[0].id,
                            ...params,
                        }
                    }
                }
            }

            const response = await homeDAO.getItemsList(params)
            // this.setState({ data: StartSortGlobalStrings(response?.data?.data?.filter((item, index) => { if (item?.isActive) { return item } }), "name", "ASC") ?? [] }, () => {
            //     callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            // })
            this.setState({ data: StartSortGlobalStrings(response?.data?.data, "name", "ASC") ?? [] }, () => {
                callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name, groups: item?.groups })))
            })
            this.setState({ loading: false })
        } catch (error) {
            this.setState({ loading: false })
        }
    }
    refreshData = async (inputValue = null, callback = () => { }, selectedOption) => {
        try {
            const { searchItems, comparisonItems } = this.props;
            const { additionalParams } = this.props;
            let excluded_items_string = selectedOption?.reduce((accumulator, item) => item.value + "," + accumulator, "").slice(0, -1)
            let params = {
                onlyActiveItems: 1,
                excluded_items: excluded_items_string,
                ...additionalParams,
            };
            // let params = {
            //     excluded_items: this.getExcludedItems(),
            // };
            if (inputValue) {
                params = {
                    q: inputValue,
                    ...params,
                }
            } else {
                params = {
                    q: "",
                    ...params,
                }
            }
            if (searchItems?.groupeType?.value) {
                params = {
                    groupType: searchItems?.groupeType?.value,
                    ...params,
                }
                console.log("searchItems?.groups", searchItems?.groups)
                if (searchItems?.groups && searchItems?.groups?.length !== 0) {
                    let filterItem = searchItems?.groups?.filter((item, index) => parseInt(item?.type) === parseInt(searchItems?.groupeType?.value));
                    if (filterItem?.length !== 0) {
                        params = {
                            groupId: filterItem[0].id,
                            ...params,
                        }
                    }
                }
            }


            this.setState({ loading: true })
            const response = await homeDAO.getItemsList(params)
            // this.setState({ data: StartSortGlobalStrings(response?.data?.data?.filter((item, index) => { if (item?.isActive) { return item } }), "name", "ASC") ?? [] }, () => {
            //     callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name })))
            // })
            this.setState({ data: StartSortGlobalStrings(response?.data?.data, "name", "ASC") ?? [] }, () => {
                callback(this.state.data?.map((item, index) => ({ value: item.id, label: item.name, groups: item?.groups })))
            })
            this.setState({ loading: false })
        } catch (error) {
            this.setState({ loading: false })
        }
    }
}

const mapStateToProps = (state) => {
    return ({
        comparisonItems: state.SEARCH_ITEMS.searchItems.comparisonItems,
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

SelectItemsInput.propTypes = {
    onChange: PropTypes.func,
    searchItems: PropTypes.array,
    isCreatable: PropTypes.bool,
    onClear: PropTypes.func,
    type: PropTypes.oneOf(["comparison", "normal"]),
    label: PropTypes.string,
};
SelectItemsInput.defaultProps = {
    onChange: () => { },
    searchItems: [],
    isCreatable: false,
    onClear: () => { },
    type: "normal",
    label: "Item(s)",
};

export default connect(mapStateToProps)(SelectItemsInput);


