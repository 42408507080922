import React, { Component } from 'react';
import "./PageNotFound";

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>page not found</div>
        );
    }
}

export default PageNotFound;