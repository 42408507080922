import $ from "jquery";

const REGIONS_ORDERS = [
    { id: "12", name: "Tanger - Tétouan - Al Hoceima", order: "1" },
    { id: "7", name: "L'Oriental", order: "2" },
    { id: "5", name: "Fès - Meknès", order: "3" },
    { id: "10", name: "Rabat - Salé - Kénitra ", order: "4" },
    { id: "1", name: "Béni  Mellal - Khénifra ", order: "5" },
    { id: "2", name: "Casablanca- Settat", order: "6" },
    { id: "9", name: "Marrakech - Safi", order: "7" },
    { id: "4", name: "Drâa- Tafilalet", order: "8" },
    { id: "11", name: "Souss - Massa ", order: "9" },
    { id: "6", name: "Guelmim - Oued Noun", order: "10" },
    { id: "8", name: "Laâyoune - Sakia El Hamra", order: "11" },
    { id: "3", name: "Dakhla - Oued Ed-Dahab ", order: "12" },
]

const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const myRecize = () => {
    //11
    let r11 = $("#R11").position()
    $("#R11tltp").css({
        'position': 'absolute',
        "top": r11.top + 10,
        "left": ((r11.left) / $(window).width() * 100) + 4 + "%"
    })
    //10
    let r10 = $("#R10").position()
    $("#R10tltp").css({
        'position': 'absolute',
        "top": r10.top + 10,
        "left": ((r10.left) / $(window).width() * 100) + 2 + "%"
    })
    //9
    let r9 = $("#R9").position()
    $("#R9tltp").css({
        'position': 'absolute',
        "top": r9.top + 10,
        "left": ((r9.left) / $(window).width() * 100) + 1 + "%"
    })
    //8
    let r8 = $("#R8").position()
    $("#R8tltp").css({
        'position': 'absolute',
        "top": (r8.top + 20),
        "left": ((r8.left) / $(window).width() * 100) + 3 + "%"
    })
    //7
    let r7 = $("#R7").position()
    $("#R7tltp").css({
        'position': 'absolute',
        "top": (r7.top),
        "left": ((r7.left) / $(window).width() * 100) + 1 + "%"
    })
    //6
    let r6 = $("#R6").position()
    $("#R6tltp").css({
        'position': 'absolute',
        "top": (r6.top - 15),
        "left": ((r6.left) / $(window).width() * 100) + 1 + "%"
    })
    //5
    let r5 = $("#R5").position()
    $("#R5tltp").css({
        'position': 'absolute',
        "top": r5.top,
        "left": ((r5.left) / $(window).width() * 100) + "%"
    })
    //4
    let r4 = $("#R4").position()
    $("#R4tltp").css({
        'position': 'absolute',
        "top": r4.top - 10,
        "left": (r4.left / $(window).width() * 100) + "%"
    })
    //3
    let r3 = $("#R3").position()
    $("#R3tltp").css({
        'position': 'absolute',
        "top": r3.top,
        "left": ((r3.left) / $(window).width() * 100) + 1 + "%"
    })
    //2
    let r2 = $("#R2").position()
    $("#R2tltp").css({
        'position': 'absolute',
        "top": r2.top + 30,
        "left": ((r2.left) / $(window).width() * 100) + 1.5 + "%"
    })
    //1
    let r1 = $("#R1").position()
    $("#R1tltp").css({
        'position': 'absolute',
        "top": r1.top - 20,
        "left": r1.left
    })
    //12
    let r12 = $("#R12").position()
    $("#R12tltp").css({
        'position': 'absolute',
        "top": r12.top + 15,
        "left": ((r12.left) / $(window).width() * 100) + 2 + "%"
    })

}
const getOrderByName = (name) => {
    return REGIONS_ORDERS.filter((item, index) => name === item?.name)[0]?.order ?? ""
}
const getOrderById = (id) => {
    return REGIONS_ORDERS.filter((item, index) => parseInt(id) === parseInt(item?.id))[0]?.order ?? ""

}
export { getCookie, myRecize, getOrderByName, getOrderById };

