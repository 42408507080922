
const initialState = {
    comparisonTableDataAnalytics: {},
};

function comparisonDataAnalyticsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_COMPARISON_TABLE_DATA_ANALYTICS":
            nextState = {
                ...state,
                comparisonTableDataAnalytics: action.value.comparisonTableDataAnalytics,
            };
            return nextState || state;
        case "RESET_COMPARISON_TABLE_DATA_ANALYTICS":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default comparisonDataAnalyticsReducer;