
import React, { Component } from 'react'
import './App.css';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import MainNavigator from './navigation/MainNavigator';
import authService from './services/authService';
import appConfig from './config/appConfig';
import FlashMessage from './components/flashMessage/FlashMessage';


//const DAstore = createStore(()=>[],applyMiddleware())




export default class App extends Component {
  componentDidMount() {
    appConfig?.setUiConfig();
  }
  render() {
    return (
      <div className="App">
        <MainNavigator />
        <FlashMessage />
      </div>
    );
  }
}
