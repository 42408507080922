import React, { Component } from 'react';
import "./style.css";
import DefaultDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { IoIosClose } from "react-icons/io";
import { BsCalendarFill } from "react-icons/bs";
import { Form } from 'react-bootstrap'

class DateRangePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { label, onChange, value } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>{label ?? ""}</Form.Label>
                <DefaultDateRangePicker
                    // clearIcon={<IoIosClose size={25} color="hsl(0, 0%, 80%)" />}
                    clearIcon={null}
                    calendarIcon={<BsCalendarFill color="hsl(0, 0%, 80%)" />}
                    className="DateRangePicker"
                    calendarClassName="DateRangePicker-calendar"
                    clearAriaLabel
                    isOpen={false}
                    onChange={onChange}
                    value={value}
                    locale="fr"
                    format="dd/MM/yyyy"
                />
            </Form.Group>
        );
    }
}

export default DateRangePicker;