import React from 'react'
import Logo from './images/864c6fd8e948bd6df5410250e2502cf7.png'
import Folder from './images/folder (1).png'
import { DAheaderStyle } from './headerStyle'
import Diagram from './images/diagram.png'
import UserLogo from './images/user.png'
import Settings from './images/settings.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import Avatar from './images/image-profil-defaut.jpeg'
import comparison from '../../img/comparison.png';

import './headerStyle.css'
import {
    Link, NavLink
} from "react-router-dom";

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { RiLogoutBoxLine } from 'react-icons/ri'
import routePath from '../../values/routesPath'
import authDAO from '../../dao/authDAO'
import routesPath from '../../values/routesPath'
// import { APP_PARAMS } from '../../env'

export default function DAheader() {
    const logout = async () => {
        try {
            const response = await authDAO.logout({});
            localStorage.clear();
            window.location.pathname = routesPath.Login.list;
        } catch (error) {
            console.log(error)
        }
    }
    return (

        <>
            <Navbar bg="light" className="DAnavbar" variant="light">
                <Navbar.Brand className="imageBrand"><img src={window.APP_PARAMS?.logoURL?.length !== 0 ? window.APP_PARAMS?.logoURL : Logo} className="DAlogo" /></Navbar.Brand>
                <Navbar.Brand id="DAwebTitle" >{window.APP_PARAMS?.appTitle ?? ""}</Navbar.Brand>
                <Nav className="mr-auto">
                    <Link to={routePath.marketView.list} className={`header__nav-link ${routePath.marketView.list === window.location.pathname ? "active" : ""}`}>
                        Market View
                    </Link>
                    <Link to={routePath.ComparisonReport.list} className={`header__nav-link ${routePath.ComparisonReport.list === window.location.pathname ? "active" : ""}`}>
                        Comparative Report
                    </Link>
                    <Link to={routePath.Home.list} className={`header__nav-link ${routePath.Home.list === window.location.pathname ? "active" : ""}`}>
                        Location View
                    </Link>
                    <Link to={routePath.Users.list} className={`header__nav-link ${routePath.Users.list === window.location.pathname ? "active" : ""}`}>
                        Settings
                    </Link>
                </Nav>
                <NavDropdown alignRight title={
                    <img src={Avatar} style={DAheaderStyle.avatarHeader()} />
                } id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={logout}><RiLogoutBoxLine style={{ marginRight: '8px' }} />Déconnexion</NavDropdown.Item>
                </NavDropdown>
            </Navbar>
        </>
    )
}

