import ApiCall from "./ApiCall";
import routesPath from "../values/routesPath";
import { CODES } from "../values/translate";
import { getOrderByName } from "../services/helpersServices";
import Store from "../store/configureStore";

class homeDAO {

    getItemsList = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/items`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getGroupeTypes = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/groups/types`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getGroupesByGroupeType = (groupTypeId, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/groups/${groupTypeId}`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getSellerTypes = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/sellers/types`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getSellersStatsByLocation = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/sellers/stats/locations/count`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getItemsByGroup = (type, id, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/groups/${type}/${id}/items`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getGroupeItems = (itemId, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/items/${itemId}/groups`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getRegionsList = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/regions`, params);

            resolve({
                ...response,
                data: {
                    ...response?.data,
                    data: response?.data?.data?.map((item, index) => ({
                        ...item,
                        order: getOrderByName(item?.name)
                    })).sort((a, b) => (parseInt(a?.order) - parseInt(b?.order)))
                }
            });
        } catch (error) {
            reject(error)
        }
    })
    getSubRegionsList = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/subregions`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getSectorsList = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/broad-sectors`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getLocationObject = (location_id, isForMap = false) => {
        const locationSearch = Store.getState().SEARCH_ITEMS.searchItems?.location
        const locationArrays = Store.getState().LOCATION;
        if (!isForMap) {
            if (locationSearch?.subRegion) {
                return locationArrays?.allSectors?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else if (locationSearch?.region) {
                return locationArrays?.allSubRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else {
                return locationArrays?.allRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            }
        } else {
            return locationArrays?.allRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
        }

    }
    prepareDataAnalyticsRequest = (params) => {
        return ApiCall.get_v2(`/reports/sold-items`, params)
    }
    isForMap = (index) => {
        switch (index) {
            case 0:
                return true;
                break;
            case 1:
                return true;
                break;
            case 2:
                return true;
                break;
            case 3:
                return true;
                break;
            case 4:
                return false;
                break;
            case 5:
                return false;
                break;
            case 6:
                return false;
                break;
            case 7:
                return false;
                break;
        }
    }
    convertArrayToObject = (array) => (
        {
            location_id: "0",
            location: {
                id: "0",
                name: "Autre",
            },
            num_of_sellers: array.reduce((accumulator, item) => accumulator + parseInt(item?.num_of_sellers), 0),
            total_quantity: array.reduce((accumulator, item) => accumulator + parseInt(item?.total_quantity), 0),
            total_value: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.total_value), 0)).toFixed(2),
        }
    )
    allDataAnalyticsRequests = (array) => new Promise(async (resolve, reject) => {
        try {
            const encodedArray = array?.map((item, index) => (JSON.stringify(item)));
            const encodedFilteredArray = encodedArray?.filter((ele, pos) => encodedArray?.indexOf(ele) == pos);
            const filteredArray = encodedFilteredArray?.map((item, index) => (JSON.parse(item)));

            const responses = await ApiCall.all(filteredArray?.map((item, index) => (this.prepareDataAnalyticsRequest(item))));

            let providedResponses = encodedArray?.map((item, index) => {
                return responses?.filter((ele, pos) => (item === JSON.stringify(ele.config.params)))[0]
            })

            // inject locations object from id location
            providedResponses = [...providedResponses.map((response, responseIndex) => {
                let data = [];
                if (Object.values(response?.data?.data)?.length !== 0) {
                    data = Object.values(response.data.data).map((item, index) => {
                        const location = this.getLocationObject(item.location_id, false)[0];
                        return {
                            ...item,
                            location_id: location && location?.name?.toLocaleLowerCase() !== "autre" ? item.location_id : "0",
                            location: location && location?.name?.toLocaleLowerCase() !== "autre" ? location : { id: "0", name: "Autre", }
                            // location_id: item.location_id,
                            // location: location ? location : { id: item.location_id, name: item.location_id, }
                        }
                    })
                } else {
                    data = [{
                        location_id: "0",
                        location: { id: "0", name: "Autre", },
                        num_of_sellers: 0,
                        total_quantity: 0,
                        total_value: "0",
                    }]
                }


                let dataForOtherLocations = data.filter((item) => {
                    return (!item?.location || (item?.location && item?.location?.name?.toLocaleLowerCase() === "autre") || (!item?.num_of_sellers) || (item?.num_of_sellers && parseInt(item?.num_of_sellers) <= 5))
                    // return (!item?.location || (item?.location && item?.location?.name?.toLocaleLowerCase() === "autre"))

                });
                let objectForOtherLocations = undefined
                if (dataForOtherLocations.length !== 0) {
                    objectForOtherLocations = this.convertArrayToObject(dataForOtherLocations)
                    // console.log("autre object : ", objectAnalyticsResultForOtherLocations)

                }
                let dataWithOutOtherLocations = data.filter((item) => (item?.location && item?.location?.name?.toLocaleLowerCase() !== "autre" && item?.num_of_sellers && parseInt(item?.num_of_sellers) > 5));
                // let dataWithOutOtherLocations = data.filter((item) => (item?.location && item?.location?.name?.toLocaleLowerCase() !== "autre"));


                let providedData = [];
                if (objectForOtherLocations) {
                    providedData = [...dataWithOutOtherLocations, objectForOtherLocations]
                } else {
                    providedData = [...dataWithOutOtherLocations]
                }

                return {
                    ...response,
                    data: {
                        ...response.data,
                        data: providedData,
                    }
                }
                // return {
                //     ...response,
                //     data: {
                //         ...response.data,
                //         data: data,
                //     }
                // }
            })]
            resolve(providedResponses)
        } catch (error) {
            reject(error)
        }

    })
    // allDataAnalyticsRequests = (array) => new Promise(async (resolve, reject) => {
    //     try {
    //         const encodedArray = array?.map((item, index) => (JSON.stringify(item)));
    //         const encodedFilteredArray = encodedArray?.filter((ele, pos) => encodedArray?.indexOf(ele) == pos);
    //         const filteredArray = encodedFilteredArray?.map((item, index) => (JSON.parse(item)));

    //         const responses = await ApiCall.all(filteredArray?.map((item, index) => (this.prepareDataAnalyticsRequest(item))));
    //         resolve(encodedArray?.map((item, index) => {
    //             return responses?.filter((ele, pos) => (item === JSON.stringify(ele.config.params)))[0]
    //         }))
    //     } catch (error) {
    //         reject(error)
    //     }

    // })
    getDataAnalytics = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/reports/sold-items`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getMarketView = (params = {}, url = null) => new Promise(async (resolve, reject) => {
        try {
            if (!url) {
                const response = await ApiCall.get(`/reports/market-view`, params);
                resolve(response);
            } else {
                const response = await ApiCall.get(url, {});
                resolve(response);
            }

        } catch (error) {
            reject(error)
        }
    })
}
export default new homeDAO();