import React, { Component } from 'react'
import './DAsearchResult.css'
import { Table } from 'react-bootstrap'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import $ from "jquery";
import { connect } from 'react-redux';
import { StartSortNumbers, StartSortStrings, StartSortTotalNumbers } from '../../helpers/sort';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import GroupeItems from '../groupeItems/GroupeItems';



class ComparisonDAsearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstRow: 0,
            pageTable: 1,
            orderBy: 1,
            key: "period_va",
            dataIsLoadedFirstTime: true,
        }
    }



    componentDidMount() {
        this.setState({ dataIsLoadedFirstTime: true });
        $(document).on('click', '#tableBtnForward', (e) => {
            let x = this.state.firstRow + 5
            let y = this.state.pageTable + 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
        $(document).on('click', '#tableBtnBack', (e) => {
            let x = this.state.firstRow - 5
            let y = this.state.pageTable - 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    sortNumbers() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortNumbers(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC") } } })
    }
    sortStrings() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortStrings(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC") } } })
    }
    sortTotalNumber() {
        const { tableDataAnalytics } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: { ...tableDataAnalytics, analyticsData: StartSortTotalNumbers(tableDataAnalytics?.analyticsData, key, orderBy === 1 ? "ASC" : "DESC", tableDataAnalytics) } } })
    }
    getThOrder = (paramKey) => {
        const { key, orderBy } = this.state;
        if (key.toLocaleLowerCase() === paramKey.toLocaleLowerCase()) {
            if (orderBy === -1) {
                return "up"
            } else if (orderBy === 1) {
                return "down"
            } else {
                return "none"
            }
        } else {
            return "none"
        }
    }
    getThActive = (paramKey) => {
        const { key, orderBy } = this.state;
        return key.toLocaleLowerCase() === paramKey.toLocaleLowerCase() && (orderBy === 1 || orderBy === -1)
    }
    isEmptyData = () => {
        const { searchItems, tableDataAnalytics } = this.props;

        if ((searchItems?.location?.region && (searchItems?.location?.region?.value?.length !== 0))
            && (searchItems?.location?.subRegion && (searchItems?.location?.subRegion?.value?.length !== 0))
            && (tableDataAnalytics?.total?.num_of_sellers < 7)) {
            return true;
        } else {
            return false;
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.tableDataAnalytics !== this.props?.tableDataAnalytics) {
            if (this.props?.tableDataAnalytics?.analyticsData?.length !== 0 && this.state.dataIsLoadedFirstTime) {
                this.setState({ dataIsLoadedFirstTime: false })
                this.sortNumbers()
            }
        }
    }
    render() {
        const { tableDataAnalytics, groupeItems, noGroupData } = this.props;
        console.log("noGroupData", noGroupData)
        const { key, orderBy } = this.state;
        function numberWithSpaces(x) {
            if (x && x !== 0) {
                var parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        function floatWithSpaces(x) {
            if (x && x !== 0) {
                let parsedX = parseFloat(x).toFixed(2)
                var parts = parsedX.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        const { searchItems } = this.state;
        let analyticsTypeIsValue = (searchItems && searchItems?.analyticsType && searchItems?.analyticsType?.toLocaleLowerCase() === "value" ? true : false);

        return (
            <div className="DAresearchResultCard">
                <h1 className="DAresearchResultCard-title">
                    <span>{`Résultat de recherche`}</span>
                    <GroupeItems />
                </h1>
                <hr />
                <div className="tableFixHead" style={{ padding: '0% 2%', }}>
                    {this.isEmptyData() ? <div className="no-result-container">
                        <h3>Not enough data</h3>
                    </div> : ""}
                    {!this.isEmptyData() ? <Table striped bordered >
                        <thead>
                            <tr>
                                <Th order={this.getThOrder("name")} isActive={this.getThActive("name")} onClick={() => this.setState({ key: "name", orderBy: this.getOrderBy() }, this.sortStrings)}>zone</Th>
                                <Th order={this.getThOrder("period_va")} isActive={this.getThActive("period_va")} onClick={() => this.setState({ key: "period_va", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA (N)</Th>
                                {/* <th onClick={() => {
                                    // StartSort(tableDataAnalytics?.analyticsData, "period_va", "asc").map((item, index) => item?.result)
                                    console.log("asc")
                                    console.log(StartSortNumbers(tableDataAnalytics?.analyticsData, "period_va", "asc").map((item, index) => item?.result))
                                    console.log("desc")
                                    console.log(StartSortNumbers(tableDataAnalytics?.analyticsData, "period_va", "desc").map((item, index) => item?.result))
                                }} className="text-left">VA (N)</th> */}

                                <Th order={this.getThOrder("lastPeriod_va")} isActive={this.getThActive("lastPeriod_va")} onClick={() => this.setState({ key: "lastPeriod_va", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA (N-1)</Th>

                                {/* <Th order={this.getThOrder("period_evol")} isActive={this.getThActive("period_evol")} onClick={() => this.setState({ key: "period_evol", orderBy: this.getOrderBy() }, this.sortNumbers)}>Evol (VA)</Th> */}
                                <Th order={this.getThOrder("period_evol_percent")} isActive={this.getThActive("period_evol_percent")} onClick={() => this.setState({ key: "period_evol_percent", orderBy: this.getOrderBy() }, this.sortNumbers)}>Evol (%)</Th>


                                {!noGroupData && <Th order={this.getThOrder("groupedPeriodData")} isActive={this.getThActive("groupedPeriodData")} onClick={() => this.setState({ key: "groupedPeriodData", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA groupe (N)</Th>}
                                {!noGroupData && <Th order={this.getThOrder("groupedLastPeriodData")} isActive={this.getThActive("groupedLastPeriodData")} onClick={() => this.setState({ key: "groupedLastPeriodData", orderBy: this.getOrderBy() }, this.sortNumbers)}>VA groupe (N-1)</Th>}
                                {!noGroupData && <Th order={this.getThOrder("grouped_period_evol_percent")} isActive={this.getThActive("grouped_period_evol_percent")} onClick={() => this.setState({ key: "grouped_period_evol_percent", orderBy: this.getOrderBy() }, this.sortNumbers)}>Evol groupe (%)</Th>}


                                {!noGroupData && <Th order={this.getThOrder("poids")} isActive={this.getThActive("poids")} onClick={() => this.setState({ key: "poids", orderBy: this.getOrderBy() }, this.sortTotalNumber)}>Poids</Th>}
                                {!noGroupData && <Th order={this.getThOrder("period_pdm")} isActive={this.getThActive("period_pdm")} onClick={() => this.setState({ key: "period_pdm", orderBy: this.getOrderBy() }, this.sortNumbers)}>PDM (N)</Th>}
                                {!noGroupData && <Th order={this.getThOrder("lastPeriod_pdm")} isActive={this.getThActive("lastPeriod_pdm")} onClick={() => this.setState({ key: "lastPeriod_pdm", orderBy: this.getOrderBy() }, this.sortNumbers)}>PDM (N-1)</Th>}
                                {!noGroupData && <Th order={this.getThOrder("ie")} isActive={this.getThActive("ie")} onClick={() => this.setState({ key: "ie", orderBy: this.getOrderBy() }, this.sortNumbers)}>IE</Th>}


                                {!noGroupData && <Th order={this.getThOrder("ip")} isActive={this.getThActive("ip")} onClick={() => this.setState({ key: "ip", orderBy: this.getOrderBy() }, this.sortNumbers)}>IP (N)</Th>}
                                <Th order={this.getThOrder("num_of_sellers")} isActive={this.getThActive("num_of_sellers")} onClick={() => this.setState({ key: "num_of_sellers", orderBy: this.getOrderBy() }, this.sortNumbers)}>Nombre PDV</Th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ fontWeight: 'bold', textAlign: 'right' }}>
                                <td style={{ backgroundColor: '#EBEBED', textAlign: "left" }}>Total*</td>
                                <td>{numberWithSpaces(tableDataAnalytics?.total?.period_va) ?? 0}</td>
                                <td>{numberWithSpaces(tableDataAnalytics?.total?.lestPeriod_va) ?? 0}</td>

                                {/* <td>{floatWithSpaces(tableDataAnalytics?.total?.period_evol) ?? 0}</td> */}
                                <td>{`${numberWithSpaces(tableDataAnalytics?.total?.period_evol_percent) ?? 0}%`}</td>

                                {!noGroupData && <td>{numberWithSpaces(tableDataAnalytics?.total?.groupedPeriodData) ?? 0}</td>}
                                {!noGroupData && <td>{numberWithSpaces(tableDataAnalytics?.total?.groupedLastPeriodData) ?? 0}</td>}
                                {!noGroupData && <td>{`${numberWithSpaces(tableDataAnalytics?.total?.grouped_period_evol_percent) ?? 0}%`}</td>}


                                {!noGroupData && <td>{`${tableDataAnalytics?.total?.period_va > 0 ? numberWithSpaces((tableDataAnalytics?.total?.period_va / tableDataAnalytics?.total?.period_va) * 100) + "%" : ""}`}</td>}
                                {!noGroupData && <td>{`${floatWithSpaces(tableDataAnalytics?.total?.period_pdm) ?? 0}${tableDataAnalytics?.total?.period_pdm && tableDataAnalytics?.total?.period_pdm !== 0 ? "%" : ""}`}</td>}
                                {!noGroupData && <td>{`${floatWithSpaces(tableDataAnalytics?.total?.lastPeriod_pdm) ?? 0}${tableDataAnalytics?.total?.lastPeriod_pdm && tableDataAnalytics?.total?.lastPeriod_pdm !== 0 ? "%" : ""}`}</td>}
                                {!noGroupData && <td>{`${floatWithSpaces(tableDataAnalytics?.total?.ie) ?? 0}${tableDataAnalytics?.total?.ie && tableDataAnalytics?.total?.ie !== 0 ? "" : ""}`}</td>}



                                {!noGroupData && <td>{`${tableDataAnalytics?.total?.ip ?? 0}`}</td>}
                                <td>{`${numberWithSpaces(tableDataAnalytics?.total?.num_of_sellers) ?? 0} (${numberWithSpaces(tableDataAnalytics?.total?.num_of_sellers_percent ?? 0) + " %"})`}</td>
                            </tr>
                            {
                                tableDataAnalytics?.analyticsData?.map((item, index) => {
                                    return (
                                        <tr key={item?.location_id} style={{ textAlign: 'right' }}>
                                            <td style={{ backgroundColor: '#EBEBED', textAlign: "left" }}>{item?.location?.name}</td>
                                            <td>{numberWithSpaces(item?.result?.period_va)}</td>
                                            <td>{numberWithSpaces(item?.result?.lastPeriod_va)}</td>

                                            {/* <td>{numberWithSpaces(item?.result?.period_evol)}</td> */}
                                            <td>{`${numberWithSpaces(item?.result?.period_evol_percent)}${item?.result?.period_evol_percent?.length !== 0 ? "%" : ""}`}</td>


                                            {!noGroupData && <td>{numberWithSpaces(item?.result?.groupedPeriodData)}</td>}
                                            {!noGroupData && <td>{numberWithSpaces(item?.result?.groupedLastPeriodData)}</td>}
                                            {!noGroupData && <td>{`${numberWithSpaces(item?.result?.grouped_period_evol_percent)}${item?.result?.grouped_period_evol_percent?.length !== 0 ? "%" : ""}`}</td>}


                                            {!noGroupData && <td>{`${tableDataAnalytics?.total?.period_va > 0 ? floatWithSpaces(((item?.result?.period_va ?? 0) / tableDataAnalytics?.total?.period_va) * 100) + "%" : ""}`}</td>}
                                            {!noGroupData && <td>{`${numberWithSpaces(item?.result?.period_pdm)}${item?.result?.period_pdm && item?.result?.period_pdm !== 0 ? "%" : ""}`}</td>}
                                            {!noGroupData && <td>{`${numberWithSpaces(item?.result?.lastPeriod_pdm)}${item?.result?.lastPeriod_pdm && item?.result?.lastPeriod_pdm !== 0 ? "%" : ""}`}</td>}
                                            {!noGroupData && <td>{`${numberWithSpaces(item?.result?.ie)}${item?.result?.ie && item?.result?.ie !== 0 ? "" : ""}`}</td>}



                                            {!noGroupData && <td>{`${item?.result?.ip ?? 0}`}</td>}
                                            <td>{`${numberWithSpaces(item?.result?.num_of_sellers)} ${item?.location_id !== "0" ? `(${numberWithSpaces(item?.result?.num_of_sellers_percent ?? 0) + " %"})` : ""}`}</td>
                                        </tr>
                                    );

                                })
                            }
                            {/* <tr className="paginationRow" style={{ textAlign: 'right' }}>
                                <td className="paginationCol" colSpan="10" style={{ border: '0px' }}>
                                    <div className="paginationCountainer">
                                        {this.state.firstRow == 0
                                            ? <button disabled className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                            : <button className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                        }

                                        <div className="tableNbr">{this.state.pageTable}</div>

                                        {resData.slice(this.state.firstRow, this.state.firstRow + 5).length < 5
                                            ? <button disabled className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                            : <button className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                        }
                                    </div>
                                </td>
                            </tr> */}
                        </tbody>
                    </Table> : ""}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        mapDataAnalytics: state.DATA_ANALYTICS.mapDataAnalytics,
        loadingMapDataAnalytics: state.DATA_ANALYTICS.loadingMapDataAnalytics,
        tableDataAnalytics: state.DATA_ANALYTICS.tableDataAnalytics,
        loadingTableDataAnalytics: state.DATA_ANALYTICS.loadingTableDataAnalytics,
        loadingDataAnalytics: state.DATA_ANALYTICS.loadingDataAnalytics,
        searchItems: state.SEARCH_ITEMS.searchItems,
        groupeItems: state.DATA_ANALYTICS.groupeItems,
        noGroupData: state.DATA_ANALYTICS.noGroupData,
    })
}
export default connect(mapStateToProps)(ComparisonDAsearchResult);


const Th = ({ order, isActive, children, ...attributes }) => {
    return (
        <th className={`Th text-left${isActive ? " table-th-is-active" : ""}`} {...attributes}>
            {children}
            <span className={`table-order-container${isActive ? " table-order-is-active" : ""}`}>
                {order !== "down" ? <a className="sprite-table-up-order"><FiChevronUp size={12} /></a> : <a></a>}
                {order !== "up" ? <a className="sprite-table-down-order"><FiChevronDown size={12} /></a> : <a></a>}
            </span>
        </th>

    )
}