export default {
    Home: {
        list: "/"
    },
    ComparisonReport: {
        list: "/comparison-report"
    },
    marketView: {
        list: "/market-view"
    },
    Login: {
        list: "/login"
    },
    Users: {
        list: "/users"
    },
}