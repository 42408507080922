import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import { Form } from 'react-bootstrap'
import "./style.css";
import { connect } from 'react-redux';
import homeDAO from '../../dao/homeDAO';
import { StartSortGlobalStrings } from '../../helpers/sort';

const INITIAL_FIELDS = {
    data: [],
    loading: false,
}
class MarketViewGroupeType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.getAll();
        this.setError();
    }
    setError = () => {
        this.setState({ error: this.props.error })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.error !== this.props.error) {
            this.setError();
        }
    }
    render() {
        const { data, value, error } = this.state;
        const { searchMarketView, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Filtrer par</Form.Label>
                <SearchSelect
                    name="MarketViewGroupeType"
                    options={StartSortGlobalStrings(data?.map((item, index) => ({ value: item?.id, label: item?.name ?? "" })), "label", "ASC")}
                    defaultValue={searchMarketView?.filterType}
                    value={searchMarketView?.filterType}
                    onChange={onChange}
                />
                {error && <span className="SelectItemsInput__error">{error?.message ?? ""}</span>}
            </Form.Group>
        );
    }
    getAll = async () => {
        try {
            let params = {};
            this.setState({ loading: true })
            const response = await homeDAO.getGroupeTypes(params);
            this.setState({ loading: false })
            this.setState({ data: [...response?.data?.data, { id: "0", name: "Item(s)" }] ?? [{ id: "0", name: "Item(s)" }] });
            this.props.dispatch({ type: "SET_FILTER_TYPE_MARKET_VIEW", value: { filterType: { value: "0", label: "Item(s)" } } })

        } catch (error) {
            this.setState({ loading: false })
        }
    }
}


const mapStateToProps = (state) => {
    return ({
        searchMarketView: state.SEARCH_MARKET_VIEW.searchMarketView,
    })
}

export default connect(mapStateToProps)(MarketViewGroupeType);

