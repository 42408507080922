
import moment from "moment";


const initialState = {
    searchItems: {
        items: [],
        comparisonItems: [],
        location: {
            country: { value: "MA", label: "Maroc" },
            region: null,
            subRegion: null,
            sector: null,
        },
        date: {
            year: { value: moment().format("YYYY"), label: moment().format("YYYY") },
            semester: null,
            quarter: null,
            month: null,
            comparisonDate: [moment().subtract(2, "year").subtract(1, "day"), moment().subtract(1, 'year').subtract(1, "day")],
            referenceDate: [moment().subtract(1, 'year'), moment()],
        },
        analyticsType: "volume",
        mapDisplayFilter: "period_va",
        groupeType: undefined,
        sellerType: undefined,
        groups: [],
    }
};

function searchItemsReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_GROUPS_ITEM":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    groups: action?.value.groups
                }
            };
            return nextState || state;
        case "SET_ALL_SEARCH_ITEMS":
            nextState = {
                ...state,
                searchItems: action?.value?.searchItems
            };
            return nextState || state;
        case "RESET_ALL_SEARCH_ITEMS":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        case "RESET_ALL_COMPARISON_ITEMS":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    comparisonItems: []
                }
            };
            return nextState || state;
        case "ADD_ITEM_IN_COMPARISON_ITEMS":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    comparisonItems: [...state.searchItems.comparisonItems, action?.value]
                }
            };
            return nextState || state;
        case "REMOVE_ITEM_IN_COMPARISON_ITEMS":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    comparisonItems: state.searchItems.comparisonItems.filter((item, index) => item.key !== action?.value?.key)
                }
            };
            return nextState || state;
        case "UPDATE_ITEM_IN_COMPARISON_ITEMS":
            const index = state.searchItems.comparisonItems.findIndex((item) => item.key === action?.value?.key);
            if (index !== -1) {
                let tmpArray = [...state.searchItems.comparisonItems];
                tmpArray[index] = { key: action?.value?.key, items: action.value.newItems };
                nextState = {
                    ...state,
                    searchItems: {
                        ...state.searchItems,
                        comparisonItems: [...tmpArray]
                    }
                };
            }
            return nextState || state;
        case "SET_GROUPE_TYPES":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    groupeType: action?.value?.groupeType
                }
            };
            return nextState || state;
        case "SET_SELLER_TYPES":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    sellerType: action?.value?.sellerType
                }
            };
            return nextState || state;
        case "SET_ITEMS":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    items: action?.value?.items
                }
            };
            return nextState || state;
        case "SET_LOCATION_COUNTRY":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    location: {
                        ...state.searchItems.location,
                        country: action?.value?.country
                    }
                }
            };
            return nextState || state;
        case "SET_LOCATION_REGION":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    location: {
                        ...state.searchItems.location,
                        region: action?.value?.region
                    }
                }
            };
            return nextState || state;
        case "SET_LOCATION_SUB_REGION":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    location: {
                        ...state.searchItems.location,
                        subRegion: action?.value?.subRegion
                    }
                }
            };
            return nextState || state;
        case "SET_LOCATION_SECTOR":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    location: {
                        ...state.searchItems.location,
                        sector: action?.value?.sector
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_YEAR":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        year: action?.value?.year
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_SEMESTER":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        semester: action?.value?.semester
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_QUARTER":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        quarter: action?.value?.quarter
                    }
                }
            };
            return nextState || state;
        case "SET_DATE_MONTH":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        month: action?.value?.month
                    }
                }
            };
            return nextState || state;
        case "SET_ANALYTICS_TYPE":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    analyticsType: action?.value?.analyticsType
                }
            };
            return nextState || state;
        case "SET_MAP_DISPLAY_FILTER":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    mapDisplayFilter: action?.value?.mapDisplayFilter
                }
            };
            return nextState || state;
        case "SET_COMPARISON_DATE":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        comparisonDate: action?.value?.comparisonDate
                    }
                }
            };
            return nextState || state;
        case "SET_REFERENCE_DATE":
            nextState = {
                ...state,
                searchItems: {
                    ...state.searchItems,
                    date: {
                        ...state.searchItems.date,
                        referenceDate: action?.value?.referenceDate
                    }
                }
            };
            return nextState || state;
        default:
            return state;
    }
}
export default searchItemsReducer;
