import React, { Component, useState, useEffect } from 'react'
import { DAsearchStyle } from './DAsearchStyle'
import { BsPlus } from "react-icons/bs";
import './DAsearch.css'
import Modal from 'react-modal';
import SearchForm from './SearchForm/SearchForm'
import { connect } from 'react-redux';
import homeDAO from '../../dao/homeDAO';
import DateRangePicker from '../dateRangePicker';
import moment from "moment";
import { IconContext } from "react-icons";
import { FaRegTrashAlt } from 'react-icons/fa'
import savedSearchDAO from '../../dao/savedSearchDAO';
import savedSearchService from '../../services/savedSearchService';
import { notify } from '../flashMessage/FlashMessage';
import messageText from '../../values/messageText';
import ee from "event-emitter";
import { Row, Col, Container } from 'react-bootstrap'
import { StartSortGlobalStrings } from '../../helpers/sort';

const FIELDS = {
    loading: false,
    filter: "infos",

}
class ComparisonDAsearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearchModalOpen: false,
            setSearchModalIsOpen: false,
            initialSearchItemsValue: undefined,
            ...FIELDS,
        }
    }

    openSearchModeal = () => {
        document.querySelector("body").style.overflowY = "scroll"
        const { searchItems } = this.props;
        this.setState({
            setSearchModalIsOpen: true,
            initialSearchItemsValue: searchItems
        })
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        }, () => this.props.dispatch({ type: "SET_ALL_SEARCH_ITEMS", value: { searchItems: this.state.initialSearchItemsValue } }))
    }

    componentDidMount() {
    }

    render() {
        const { searchItems } = this.props;
        const { type } = this.props;
        const comparisonItems = searchItems.comparisonItems;
        const { filter } = this.state;

        return (
            <div className="searchCard ComparisonDAsearch__container" style={DAsearchStyle.searchCard()}>
                <div className="searchTitleCountainer">
                    <h2 style={DAsearchStyle.searchTitle()}>{filter === "mySavedSearch" ? "Mes recherches enregistrées" : "Recherche actuelle"}</h2>
                </div>
                <div className="BtnSearchModalCountainer BtnSearchModalCountainer-comparison">
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button type="button" onClick={() => this.setState({ filter: filter === "mySavedSearch" ? "infos" : "mySavedSearch" })} className="btn btn-link">{filter === "mySavedSearch" ? "Recherche actuelle" : "Mes recherches enregistrées"}</button>
                        <button type="button" onClick={this.openSearchModeal} className="btn btn-link"><BsPlus style={{ fontSize: '25px', }} />Nouvelle recherche</button>
                    </div>

                    <Modal
                        isOpen={this.state.setSearchModalIsOpen}
                        onAfterOpen={() => { }}
                        onRequestClose={this.closeSearchModal}
                        ariaHideApp={false}
                        style={{}}
                        className="SearchModal"
                        contentLabel="Example Modal">
                        <SearchForm type={type ?? ""} closeFromApply={() => this.setState({ setSearchModalIsOpen: false })} close={this.closeSearchModal} />

                    </Modal>
                </div>
                {filter === "infos" && <div>
                    <hr />
                    <div style={{ display: 'flex', paddingLeft: '2%', }}>
                        <div style={{ flex: '1' }}>
                            {comparisonItems?.length === 0 &&
                                <>
                                    <h3>Item(s)</h3>
                                    <p>--</p>
                                </>
                            }
                            {
                                comparisonItems.map((item, index) => (
                                    <>
                                        <h3>{"Item(s) - " + (index + 1)}</h3>
                                        {
                                            item?.items?.map((item, index) => (
                                                <p key={`category__${index}`}>{item.label}</p>
                                            ))
                                        }
                                    </>
                                ))
                            }
                            {/* <h3>Item(s)</h3>
                        {
                            searchItems?.items?.map((item, index) => (
                                <p key={`category__${index}`}>{item.label}</p>
                            ))
                        } */}
                        </div>
                        <div style={{ flex: '1' }}>
                            {/* <h3>Comparer à</h3>
                        <p>Catego-1</p> */}
                        </div>
                    </div>
                    <hr className="hrNoMargging" />

                    <div style={{ display: 'flex', paddingLeft: '2%', }}>
                        <div style={{ width: '15%' }}>
                            <h3 style={{ fontSize: '20px' }}>Pays</h3>
                            <p>{searchItems?.location?.country?.label ?? "--"}</p>
                        </div>
                        <div style={{ width: '15%' }}>
                            <h3 style={{ fontSize: '20px' }}>Région</h3>
                            <p>{searchItems?.location?.region?.label ?? "--"}</p>
                        </div>
                        <div style={{ width: '20%' }}>
                            <h3 style={{ fontSize: '20px' }}>Sous-région</h3>
                            <p>{searchItems?.location?.subRegion?.label ?? "--"}</p>
                        </div>
                        <div style={{ width: '15%' }}>
                            <h3 style={{ fontSize: '20px' }}>Secteur</h3>
                            <p>{searchItems?.location?.sector?.label ?? "--"}</p>
                        </div>
                    </div>
                    <hr className="hrNoMargging" />

                    <div style={{ display: 'flex', paddingLeft: '2%', }}>
                        <div style={{ margin: '0 3% 0 0', }}>
                            <h3 style={{ fontSize: '15px' }}>Période de référence</h3>
                            <p>{moment(searchItems?.date?.referenceDate[0]).format("DD/MM/YYYY") + " - " + moment(searchItems?.date?.referenceDate[1]).format("DD/MM/YYYY") ?? "--"}</p>
                        </div>
                        <div style={{ margin: '0 3% 0 0' }}>
                            <h3 style={{ fontSize: '15px' }}>Période de comparaison</h3>
                            <p>{moment(searchItems?.date?.comparisonDate[0]).format("DD/MM/YYYY") + " - " + moment(searchItems?.date?.comparisonDate[1]).format("DD/MM/YYYY") ?? "--"}</p>

                        </div>
                        <div style={{ margin: '0 3% 0 0' }}>
                            <h3 style={{ fontSize: '15px' }}>Type de résultat</h3>
                            <p>{searchItems?.analyticsType ?? "--"}</p>
                        </div>
                        <div style={{ margin: '0 3% 0 0' }}>
                            <h3 style={{ fontSize: '15px' }}>Type de groupe</h3>
                            <p>{searchItems?.groupeType?.label ?? "--"}</p>
                        </div>
                    </div>
                </div>}
                {filter === "mySavedSearch" && <SavedSearch openSearchModeal={this.openSearchModeal} />}
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems,
        ...state,
    })
}

export default connect(mapStateToProps)(ComparisonDAsearch);


const emitter = new ee();

export const refreshSavedSearchComparison = () => {
    emitter.emit("refreshSavedSearchComparison", {});
};

const SavedSearch = ({ ...props }) => {

    const [data, setData] = useState([]);
    useEffect(() => {
        emitter.on("refreshSavedSearchComparison", ({ }) => {
            getData();
        });
        getData();
        return () => {

        };
    }, []);

    const getData = async () => {
        try {
            const response = await savedSearchDAO.getAll();
            setData(response?.data?.data ? StartSortGlobalStrings(response?.data?.data?.filter((item) => (item?.report === "comparison")).reverse(), "label", "ASC") : []);
        } catch (error) {
            console.log(error)
        }
    }
    const affectSavedSearch = (item) => {
        savedSearchService.affectSavedSearch(item, () => {
            notify({ msg: messageText?.Common?.affectedSavedSearch, type: "success" });
            props.openSearchModeal();
        })
    }
    return (
        <div className="my-saved-search">
            <Row style={{ paddingLeft: 15, paddingRight: 15 }}>
                {
                    data.map((item, index) => (
                        <Col className="my-saved-search-col">
                            <SavedSearchItem affectSavedSearch={affectSavedSearch} refreshData={getData} key={`saved-search-${index}`} data={item} />
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
}

const SavedSearchItem = ({ data, affectSavedSearch, ...props }) => {
    return (
        <div className="my-saved-search-item-container my-saved-search-item-container-comparison">
            <span onClick={() => affectSavedSearch(data)} className="my-saved-search-item-title">{data?.label ?? ""}</span>
            <div>
                <IconContext.Provider value={{ color: "#FF5050", size: '15px' }}>
                    <button style={{ marginLeft: 30 }} onClick={() => savedSearchService.deleteSearch(data?.id, () => {
                        notify({ msg: messageText?.Common?.deleteSavedSearch, type: "success" });
                        props.refreshData();
                    })} className="deleteUserTable clickable"><FaRegTrashAlt /></button>
                </IconContext.Provider>
            </div>
        </div>
    );
}