import React, { Component } from 'react';
import "./Checkbox.css";
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import { Field, ErrorMessage } from "formik";
import { HiXCircle } from "react-icons/hi";

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { label, id, checked, onChange, name, disabled } = this.props;
        return (
            <Form.Group controlId="formBasicNom">
                <label htmlFor={id} className="Checkbox__container">
                    {label && <span className="Checkbox__label-text">{label ?? ""}</span>}
                    {this.props.children}
                    <input disabled={disabled ?? false} checked={checked} onChange={onChange} id={id} type="checkbox" />
                    <span className="Checkbox__checkmark"></span>
                </label>
                <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
                {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
            </Form.Group>
        );
    }
}
const Error = ({ message }) => (
    <div className="error-area">
        <HiXCircle style={{ marginRight: 2 }} color="#da3025" size={17} />
        <span className="error-message">{message}</span>
    </div>
);

export default Checkbox;