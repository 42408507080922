import React, { Component } from 'react'
import './MarketViewDAsearchResult.css'
import { Table } from 'react-bootstrap'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import $ from "jquery";
import { connect } from 'react-redux';
import { StartSortNumbers, StartSortStrings, StartSortTotalNumbers, StartSortGlobalStrings, StartSortGlobalNumbers } from '../../helpers/sort';
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import marketViewService from '../../services/marketViewService';
import { TableLoader } from '../tableLoader/TableLoader';
import { FiChevronLeft } from "react-icons/fi";
import homeDAO from '../../dao/homeDAO';



class MarketViewDAsearchResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstRow: 0,
            pageTable: 1,
            orderBy: 1,
            key: "label",
            dataIsLoadedFirstTime: true,
            groupTypes: [],
        }
    }

    // get groupe types
    getAll = async () => {
        try {
            let params = {};
            const response = await homeDAO.getGroupeTypes(params);
            this.setState({ groupTypes: response?.data?.data ?? [] });
        } catch (error) {
            this.setState({ loading: false })
        }
    }
    findGroupeType = (id) => {
        const { groupTypes } = this.state;
        let filteredArray = groupTypes.filter((item, index) => parseInt(item.id) === parseInt(id));
        if (filteredArray && filteredArray?.length !== 0) {
            return filteredArray[0].name;
        } else {
            return null;
        }
    }
    // end get groupe types

    componentDidMount() {
        this.getAll();
        $(document).on('click', '#tableBtnForward', (e) => {
            let x = this.state.firstRow + 5
            let y = this.state.pageTable + 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
        $(document).on('click', '#tableBtnBack', (e) => {
            let x = this.state.firstRow - 5
            let y = this.state.pageTable - 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
    }
    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    sortNumbers() {
        const { tableMarketView } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_MARKET_VIEW", value: { tableMarketView: StartSortGlobalNumbers(tableMarketView, key, orderBy === 1 ? "ASC" : "DESC") } })
    }
    sortStrings() {
        const { tableMarketView } = this.props;
        const { key, orderBy } = this.state;
        this.props.dispatch({ type: "SET_TABLE_MARKET_VIEW", value: { tableMarketView: StartSortGlobalStrings(tableMarketView, key, orderBy === 1 ? "ASC" : "DESC") } })
    }

    getThOrder = (paramKey) => {
        const { key, orderBy } = this.state;
        if (key.toLocaleLowerCase() === paramKey.toLocaleLowerCase()) {
            if (orderBy === -1) {
                return "up"
            } else if (orderBy === 1) {
                return "down"
            } else {
                return "none"
            }
        } else {
            return "none"
        }
    }
    getThActive = (paramKey) => {
        const { key, orderBy } = this.state;
        return key.toLocaleLowerCase() === paramKey.toLocaleLowerCase() && (orderBy === 1 || orderBy === -1)
    }
    onClickRow = (url) => {
        if (url) {
            marketViewService.getMarketView(() => { }, url);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.tableMarketView !== this.props?.tableMarketView) {
            if (this.props?.tableMarketView.length !== 0 && this.state.dataIsLoadedFirstTime) {
                this.setState({ dataIsLoadedFirstTime: false })
                this.sortStrings();
            }
        }
    }

    render() {
        const { loadingMarketView, tableMarketView, statsMarketView, metadataMarketView } = this.props;
        const { key, orderBy } = this.state;
        function numberWithSpaces(x) {
            if (x && x !== 0) {
                var parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        function floatWithSpaces(x) {
            if (x && x !== 0) {
                let parsedX = parseFloat(x).toFixed(2)
                var parts = parsedX.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            } else if (x === 0) {
                return ""
            }
            return undefined;
        }
        const { searchItems } = this.state;
        console.log("metadataMarketView", metadataMarketView)
        return (
            <div className="DAresearchResultCard">

                <div style={{ display: 'flex', paddingLeft: '2%', }}>
                    {metadataMarketView?.parentUrl && <div style={{ paddingRight: 20, display: "flex", alignItems: "center" }}>
                        <button onClick={() => this.onClickRow(metadataMarketView?.parentUrl)} className="MaketView__btn">
                            <FiChevronLeft color="#9D9D9D" size={29} />
                        </button>
                    </div>}
                    <div style={{ width: '11%' }}>
                        <h3 style={{ fontSize: '20px' }}>Résultat pour:</h3>
                        <p>{metadataMarketView?.label ?? "--"}</p>
                    </div>
                    <div style={{ width: '11%' }}>
                        <h3 style={{ fontSize: '20px' }}>Volume</h3>
                        <p>{statsMarketView?.total_quantity ? numberWithSpaces(statsMarketView?.total_quantity) : "--"}</p>
                    </div>
                    <div style={{ width: '11%' }}>
                        <h3 style={{ fontSize: '20px' }}>Valeur</h3>
                        <p>{statsMarketView?.total_value ? numberWithSpaces(statsMarketView?.total_value) : "--"}</p>
                    </div>
                    <div style={{ width: '15%' }}>
                        <h3 style={{ fontSize: '20px' }}>Nombre de PDV</h3>
                        <p>{statsMarketView?.num_of_sellers ? numberWithSpaces(statsMarketView?.num_of_sellers) : "--"}</p>
                    </div>
                    {
                        metadataMarketView?.groups?.map((item) => (
                            <>
                                {this.findGroupeType(item?.type) && <div style={{ width: '11%' }}>
                                    <h3 style={{ fontSize: '20px' }}>{this.findGroupeType(item?.type) ?? "--"}</h3>
                                    <p>{item?.name ?? "--"}</p>
                                </div>}
                            </>

                        ))
                    }

                </div>
                <hr className="hrNoMargging" />
                <br />
                <div className="tableFixHead" style={{ padding: '0% 2%', }}>
                    <Table striped bordered >
                        <thead>
                            <tr>
                                <Th order={this.getThOrder("label")} isActive={this.getThActive("label")} onClick={() => this.setState({ key: "label", orderBy: this.getOrderBy() }, this.sortStrings)}>label</Th>
                                <Th order={this.getThOrder("total_quantity")} isActive={this.getThActive("total_quantity")} onClick={() => this.setState({ key: "total_quantity", orderBy: this.getOrderBy() }, this.sortNumbers)}>Volume</Th>

                                <Th order={this.getThOrder("total_value")} isActive={this.getThActive("total_value")} onClick={() => this.setState({ key: "total_value", orderBy: this.getOrderBy() }, this.sortNumbers)}>Valeur</Th>

                                {/* <Th order={this.getThOrder("period_evol")} isActive={this.getThActive("period_evol")} onClick={() => this.setState({ key: "period_evol", orderBy: this.getOrderBy() }, this.sortNumbers)}>Evol (VA)</Th> */}
                                <Th order={this.getThOrder("num_of_sellers")} isActive={this.getThActive("num_of_sellers")} onClick={() => this.setState({ key: "num_of_sellers", orderBy: this.getOrderBy() }, this.sortNumbers)}>Nombre de PDV</Th>
                            </tr>
                        </thead>
                        {!loadingMarketView && <tbody>

                            {
                                tableMarketView?.map((item, index) => {
                                    return (
                                        <tr className={`${item?.url ? "clickable-row" : ""}`} onClick={() => this.onClickRow(item?.url)} key={item?.location_id} style={{ textAlign: 'right' }}>
                                            <td style={{ backgroundColor: '#EBEBED', textAlign: "left" }}>{item?.label}</td>
                                            <td>{numberWithSpaces(item?.total_quantity)}</td>
                                            <td>{numberWithSpaces(parseFloat(item?.total_value ?? 0).toFixed(0))}</td>
                                            {/* <td>{numberWithSpaces(item?.total_value)}</td> */}
                                            <td>{numberWithSpaces(item?.num_of_sellers)}</td>
                                        </tr>
                                    );

                                })
                            }

                        </tbody>}
                        <TableLoader loading={loadingMarketView} columns={[
                            { type: "extra-small", },
                            { type: "large", },
                            { type: "small", },
                            { type: "medium", },
                        ]} />
                    </Table>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        loadingMarketView: state.MARKET_VIEW.loadingMarketView,
        tableMarketView: state.MARKET_VIEW.tableMarketView,
        statsMarketView: state.MARKET_VIEW.statsMarketView,
        metadataMarketView: state.MARKET_VIEW.metadataMarketView,

    })
}
export default connect(mapStateToProps)(MarketViewDAsearchResult);


const Th = ({ order, isActive, children, ...attributes }) => {
    return (
        <th className={`Th text-left`} {...attributes}>
            {children}
            <span className={`table-order-container${isActive ? " table-order-is-active" : ""}`}>
                {order !== "down" ? <a className="sprite-table-up-order"><FiChevronUp size={12} /></a> : <a></a>}
                {order !== "up" ? <a className="sprite-table-down-order"><FiChevronDown size={12} /></a> : <a></a>}
            </span>
        </th>

    )
}