import React, { Component } from 'react'
import { DAmapStyle } from './DAmapStyle'
//import OverlayTrigger from 'react-overlay-trigger'
import './DAmap.css'
import $ from "jquery";
import { connect } from 'react-redux';
import { myRecize } from "../../services/helpersServices";
import MapMarker from '../mapMarker';





class DAmap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ListReg: [],
            InfoBoxSohow: false,
            infoBox: { id: 0, nom: '' }
        }
    }


    componentWillUnmount() {
        window.removeEventListener("resize", myRecize)
    }



    componentDidMount() {

        //tooltip position
        myRecize();
        window.addEventListener("resize", myRecize)


        //end tooltip position

        /*$(document).on('mouseover','.bullePer',(e)=>{
            console.log($(e.target).data('reg'));
            this.state.ListReg.map(Reg=>{
            })
            this.setState({
                InfoBoxSohow:true
            })
        })

        $('.bullePer').hover((e)=>{
            //console.log(e.target.getAttribute("id"));
        })*/
        //$('.mapInfoBox').show();


    }


    bullHover = (x) => {
        const { mapDataAnalytics } = this.props;
        mapDataAnalytics?.analyticsData?.filter((item, index) => item?.location_id !== '0' && (item?.period?.order || item?.lastPeriod?.order))?.find((item) => {
            if (parseInt(item.location_id) === parseInt(x)) {
                this.setState({
                    infoBox: item
                })
            }
        })
        $('.mapInfoBox').stop().show(150);
    }
    isActive = (filter) => {
        const { searchItems } = this.props;
        return searchItems?.mapDisplayFilter === filter
    }
    setMapFilter = (filter) => {
        this.props.dispatch({ type: "SET_MAP_DISPLAY_FILTER", value: { mapDisplayFilter: filter } })
    }
    numberWithSpaces(x) {
        if (x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return parts.join(".");
        }
        return undefined;
    }
    render() {
        const { mapDataAnalytics } = this.props;
        console.log("mapDataAnalytics?.analyticsData", mapDataAnalytics?.analyticsData?.filter((item, index) => item?.location_id !== '0'))
        const { infoBox } = this.state;
        const renderMapMarkerText = (item) => {
            const { searchItems } = this.props;
            switch (searchItems?.mapDisplayFilter) {
                case "period_va":
                    return `${this.numberWithSpaces(item?.result?.period_va) ?? 0}`
                case "lastPeriod_va":
                    return `${this.numberWithSpaces(item?.result?.lastPeriod_va) ?? 0}`
                case "period_evol":
                    return `${this.numberWithSpaces(item?.result?.period_evol) ?? 0}`
                case "period_evol_percent":
                    return `${this.numberWithSpaces(item?.result?.period_evol_percent)}${item?.result?.period_evol_percent?.length !== 0 ? "%" : ""}`
                case "num_of_sellers":
                    return `${this.numberWithSpaces(item?.result?.num_of_sellers) ?? 0}`
                case "period_pdm":
                    return `${this.numberWithSpaces(item?.result?.period_pdm) ?? 0}%`
                case "ie":
                    return `${this.numberWithSpaces(item?.result?.ie) ?? 0}`
                case "ip":
                    return `${this.numberWithSpaces(item?.result?.ip) ?? 0}`
                default:
                    return `${this.numberWithSpaces(item?.result?.period_evol_percent)}${item?.result?.period_evol_percent?.length !== 0 ? "%" : ""}`
            }
        }
        const checkMapMarkerColor = (item) => {
            const { searchItems } = this.props;
            console.log('item' + Math.sign(item?.result?.ie), parseFloat(item?.result?.ie) > 50)
            switch (searchItems?.mapDisplayFilter) {
                case "period_va":
                    return Math.sign(item?.result?.period_va) > 0 ? "#1BBAAC" : "#F05A29"
                case "lastPeriod_va":
                    return Math.sign(item?.result?.lastPeriod_va) > 0 ? "#1BBAAC" : "#F05A29"
                case "period_evol":
                    return Math.sign(item?.result?.period_evol) > 0 ? "#1BBAAC" : "#F05A29"
                case "period_evol_percent":
                    return Math.sign(item?.result?.period_evol_percent) > 0 ? "#1BBAAC" : "#F05A29"
                case "num_of_sellers":
                    return Math.sign(item?.result?.num_of_sellers) > 0 ? "#1BBAAC" : "#F05A29"
                case "period_pdm":
                    return Math.sign(item?.result?.period_pdm) > 0 ? "#1BBAAC" : "#F05A29"
                case "ie":
                    return parseFloat(item?.result?.ie) >= 100 ? "#1BBAAC" : "#F05A29"
                case "ip":
                    return parseFloat(item?.result?.ip) >= 100 ? "#1BBAAC" : "#F05A29"
                default:
                    return Math.sign(item?.result?.period_evol_percent) > 0 ? "#1BBAAC" : "#F05A29"
            }
        }
        return (
            <div style={DAmapStyle.mapCard()} >
                <div className="DAmap__header-container">
                    <h2 style={DAmapStyle.mapTitle()}>Map</h2>
                    <div className="DAmap__header-filters-container">
                        <button onClick={() => this.setMapFilter("period_va")} className={`btn DAmap__header-filter-btn ${this.isActive("period_va") ? "DAmap__header-filter-btn-active" : ""}`}>VA(N)</button>
                        <button onClick={() => this.setMapFilter("lastPeriod_va")} className={`btn DAmap__header-filter-btn ${this.isActive("lastPeriod_va") ? "DAmap__header-filter-btn-active" : ""}`}>VA(N-1)</button>
                        <button onClick={() => this.setMapFilter("period_evol")} className={`btn DAmap__header-filter-btn ${this.isActive("period_evol") ? "DAmap__header-filter-btn-active" : ""}`}>Evol VA(N)</button>
                        <button onClick={() => this.setMapFilter("period_evol_percent")} className={`btn DAmap__header-filter-btn ${this.isActive("period_evol_percent") ? "DAmap__header-filter-btn-active" : ""}`}>Evol(%)</button>
                        <button onClick={() => this.setMapFilter("num_of_sellers")} className={`btn DAmap__header-filter-btn ${this.isActive("num_of_sellers") ? "DAmap__header-filter-btn-active" : ""}`}>Nombre PDV</button>
                        <button onClick={() => this.setMapFilter("period_pdm")} className={`btn DAmap__header-filter-btn ${this.isActive("period_pdm") ? "DAmap__header-filter-btn-active" : ""}`}>PDM (N)</button>
                        <button onClick={() => this.setMapFilter("ie")} className={`btn DAmap__header-filter-btn ${this.isActive("ie") ? "DAmap__header-filter-btn-active" : ""}`}>IE</button>
                        <button onClick={() => this.setMapFilter("ip")} className={`btn DAmap__header-filter-btn ${this.isActive("ip") ? "DAmap__header-filter-btn-active" : ""}`}>IP (N)</button>

                    </div>
                </div>

                <hr />



                <div className="shadow p-3 p-3 mb-5 bg-light rounded float-left mapInfoBox" style={{ display: this.state.InfoBoxSohow ? 'block !importante' : 'none' }}>
                    <h3 className="titleInfoBox">{infoBox?.location?.name ?? ""}</h3>
                    <hr />
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>VA (N)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.period_va}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>VA (N-1)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.lastPeriod_va}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>Evol (VA)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.period_evol}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>Evol (%)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{`${infoBox?.result?.period_evol_percent}${infoBox?.result?.period_evol_percent?.length !== 0 ? "%" : ""}`}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>Nombre PDV</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.num_of_sellers}</div>

                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>PDM (N)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.period_pdm + "%"}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>IE</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.ie}</div>
                        <div style={{ width: '50%', display: 'inline-block', color: '#5063B9', fontWeight: 'bold', }}>IP (N)</div>
                        <div style={{ width: '50%', display: 'inline-block', textAlign: 'right', }}>{infoBox?.result?.ip}</div>
                    </div>
                </div>
                <div style={{ textAlign: 'right' }}>


                    <svg version="1.1" id="Calque_1" x="0px" y="0px"
                        viewBox="0 0 385 402" stroke="#fff" strokeWidth="1.5" fill="#ccc"
                        style={{ width: '65%' }} className="mapSvg">

                        <polygon id="R12" className="st0" data-num="12" points="125.5,302.5 125.5,329.5 122.5,329.5 117.5,332.5 112.5,332.5 108.5,335.5 104.5,338.5 100.5,342.5 
                                100.5,348.5 100.5,350.5 101.5,352.5 101.5,357.5 103.5,379.5 17.5,380.5 13.5,392.5 14.5,379.5 14.5,369.5 17.5,361.5 21.5,357.5 
                                25.5,357.5 29.5,349.5 28.5,345.5 30.5,342.5 33.5,340.5 34.5,338.5 32.5,337.5 33.5,335.5 37.5,328.5 40.5,323.5 43.5,320.5 
                                42.5,318.5 37.5,324.5 36.5,323.5 38.5,319.5 41.5,316.5 44.5,315.5 48.5,311.5 52.5,307.5 54.5,304.5 56.5,303.5 57.5,303.5 
                                60.5,299.5 64.5,299.5 68.5,303.5 71.5,303.5 72.5,302.5 76.5,302.5 77.5,301.5 81.5,301.5 81.5,302.5 85.5,302.5 85.5,303.5 
                                92.5,303.5 97.5,300.5 102.5,301.5 107.5,301.5 111.5,303.5 117.5,302.5 "/>


                        <polygon id="R11" className="st0" data-num="11" points="197.5,244.5 198.5,265.5 125.5,267.5 125.5,302.5 118.3,302.5 111.5,303.5 107.5,301.5 102.5,301.5 
                97.5,300.5 92.5,303.5 85.5,303.5 85.5,302.5 81.5,302.5 81.5,301.5 77.5,301.5 76.5,302.5 72.5,302.5 71.5,303.5 68.5,303.5 
                64.5,299.5 60.5,299.5 62.5,294.5 62.5,285.5 63.5,279.5 64.5,279.5 69.5,268.5 69.5,262.5 73.5,260.5 77.5,256.5 80.5,256.5 
                83.5,254.5 87.5,251.5 90.5,248.5 92.5,244.5 94.5,238.5 96.5,234.5 97.5,230.5 98.5,227.5 98.5,224.5 101.5,224.5 105.5,218.5 
                113.5,216.5 119.5,216.5 126.5,213.5 128.5,213.5 128.5,216.5 129.5,221.5 131.5,223.5 134.5,223.5 138.5,224.5 143.5,224.5 
                147.5,226.5 152.5,227.5 154.5,228.5 160.5,225.5 169.5,225.5 173.5,226.5 176.5,231.5 179.5,233.5 183.5,232.5 186.5,230.5 "/>



                        <polygon id="R10" className="st0" data-num="10" points="198.5,199.5 198.5,243.5 197.5,244.5 186.5,230.5 179.5,233.5 173.5,226.5 169.5,225.5 160.5,225.5 
                154.5,228.5 152.5,227.5 147.5,226.5 143.5,224.5 138.5,224.5 134.5,223.5 131.5,223.5 129.5,222.5 128.5,216.5 128.5,213.5 
                137.5,209.5 140.5,204.5 145.5,199.5 156.5,193.5 162.5,185.5 169.5,177.5 173.5,180.5 180.5,180.5 183.5,180.5 184.5,179.5 
                187.5,180.5 189.5,185.5 189.5,188.5 188.5,191.5 186.5,194.5 185.5,196.5 187.5,199.5 189.5,202.5 194.5,201.5 "/>






                        <path id="R9" className="st0" data-num="9" d="M245.5,177.5l-4,2h-3c0,0-3-1-4-1s-5,3-5,3l-6,1l-25,17l-4,2l-5,1l-4-6l3-5l1-3v-3l-2-5l-3-1l-1,1h-10l-4-3l5-8
                l3-5l1-2v-6l-1-3l-5-4l1-3l4-1l2-1h6l1,1h4l4-3h3v2l2-2l3,1h6l1-1l3,1l2-4l2-2h3c0,0,1,6,0,6s2,1,2,1l1,4l3,2l-2,2l-2,2l1,2l1,3l3,1
                l3,1l1-4h5h3l3-3l3,1l2,3l-1,3l1,4l4,1l1,3l-3,2l-3,1V177.5z"/>





                        <polygon id="R8" className="st0" data-num="8" points="268.5,179.5 272.5,172.5 273.5,169.5 281.5,162.5 289.5,158.5 295.5,152.5 305.5,150.5 309.5,148.5 
                310.5,143.5 312.5,141.5 312.5,140.5 310.5,136.5 307.5,137.5 307.5,133.5 310.5,130.5 310.5,124.5 319.5,121.5 321.5,121.5 
                322.5,119.5 320.5,118.5 319.5,116.5 318.5,112.5 320.5,110.5 316.5,111.5 313.5,110.5 310.5,111.5 308.5,107.5 303.5,98.5 
                299.5,99.5 297.5,98.5 293.5,93.5 292.5,94.5 291.5,90.5 286.5,91.5 286.5,88.5 284.5,85.5 280.5,86.5 278.5,88.5 278.5,89.5 
                275.5,91.5 272.5,95.5 273.5,97.5 272.5,100.5 270.5,100.5 268.5,102.5 268.5,106.5 267.5,108.5 264.5,109.5 262.5,111.5 
                261.5,113.5 261.5,115.5 261.5,117.5 259.5,119.5 255.5,121.5 249.5,124.5 245.5,127.5 242.5,127.5 241.5,129.5 240.5,131.5 
                237.5,131.5 234.5,129.5 232.5,129.5 230.5,132.5 226.5,132.5 223.5,135.5 221.5,135.5 219.5,137.5 219.9,142.6 219.5,143.5 
                221.5,144.5 222.5,148.5 225.5,150.5 221.5,154.5 223.5,159.5 229.5,161.5 230.5,157.5 238.5,157.5 241.5,154.5 244.5,155.5 
                246.5,158.5 245.5,161.5 246.5,165.5 250.5,166.5 251.5,169.5 245.5,172.5 245.5,177.5 248.5,177.5 254.5,177.5 257.5,177.5 
                259.5,176.5 264.5,176.5 264.5,179.5 "/>






                        <polygon id="R7" className="st0" data-num="7" points="233.5,106.5 228.5,103.5 221.5,102.5 221.5,99.5 219.5,97.5 214.5,94.5 212.5,94.5 212.5,100.5 
                205.5,105.5 201.5,105.5 200.5,102.5 194.5,101.5 191.5,96.5 185.5,100.5 186.5,110.5 177.5,122.5 177.5,126.5 173.5,129.5 
                173.5,146.5 179.5,144.5 185.5,144.5 186.5,145.5 190.5,145.5 194.5,142.5 197.5,142.5 197.5,144.5 199.5,142.5 202.5,143.5 
                208.5,143.5 209.5,142.5 212.5,143.5 214.5,139.5 216.5,137.5 219.5,137.5 221.5,135.5 223.5,135.5 226.5,132.5 230.5,132.5 
                232.5,129.5 233.5,129.5 233.5,125.5 229.5,122.5 234.5,121.5 235.5,118.5 235.5,114.5 233.5,113.5 "/>




                        <polygon id="R6" className="st0" data-num="6" points="234.5,66.5 225.5,71.5 216.5,74.5 207.5,80.5 202.5,81.5 199.5,85.5 194.5,91.5 191.5,96.5 
                194.5,101.5 200.5,102.5 201.5,105.5 205.5,105.5 212.5,100.5 212.5,94.5 214.5,94.5 219.5,97.5 221.5,99.5 221.5,102.5 
                228.5,103.5 233.5,106.5 235.5,104.5 235.5,98.5 235.5,92.5 239.5,85.5 240.5,78.5 240.5,74.5 235.5,70.5 "/>






                        <polygon id="R5" className="st0" data-num="5" points="275.5,91.5 273.5,86.5 273.5,83.5 270.5,83.5 269.5,79.5 264.5,77.5 261.5,79.5 255.5,80.5 255.5,77.5 
                251.5,77.5 249.5,79.5 249.5,83.5 248.5,84.5 240.5,83.5 235.5,92.5 235.5,104.5 233.5,106.5 233.5,113.5 235.5,114.5 235.5,118.5 
                234.5,121.5 229.5,122.5 233.5,125.5 233.5,129.5 234.5,129.5 237.5,131.5 240.5,131.5 242.5,127.5 245.5,127.5 258.3,120.5 
                261.5,117.5 261.5,113.5 262.5,111.5 264.5,109.5 267.5,108.5 268.5,106.5 268.5,102.5 270.5,100.5 272.5,100.5 273.5,97.5 
                272.5,95.5 "/>






                        <polygon id="R4" className="st0" data-num="4" points="234.5,66.5 242.5,59.5 246.5,50.5 251.5,39.5 252.5,35.5 259.5,35.5 261.5,36.5 263.5,41.5 267.5,43.5 
                270.5,46.5 273.5,49.5 271.5,51.5 269.5,52.5 271.5,54.5 271.5,57.5 267.5,58.5 263.5,57.5 261.5,58.5 262.5,61.5 263.5,63.5 
                263.5,66.5 265.5,68.5 265.5,70.5 263.5,72.5 264.5,77.5 261.5,79.5 255.5,80.5 255.5,77.5 252.5,77.5 249.5,79.5 249.5,83.5 
                248.5,84.5 240.5,83.5 240.5,74.5 235.5,70.5 "/>




                        <polygon id="R3" className="st0" data-num="3" points="277.5,46.5 277.5,41.5 285.5,39.5 287.5,43.5 292.5,43.5 297.5,39.5 300.5,43.5 302.5,39.5 307.5,37.5 
                312.5,39.5 312.5,43.5 310.5,45.5 308.5,45.5 306.5,50.5 306.5,53.5 305.5,53.5 305.5,55.5 307.5,57.5 307.5,59.5 305.5,59.5 
                305.5,62.5 304.5,62.5 303.5,69.5 301.5,70.5 300.5,73.5 303.5,73.5 306.5,72.5 312.5,65.5 316.5,66.5 322.5,68.5 322.5,70.5 
                319.5,73.5 319.5,75.5 326.5,83.5 324.5,85.5 310.5,86.5 308.5,88.5 309.5,91.5 306.5,93.5 303.5,98.5 299.5,99.5 297.5,98.5 
                294.3,94.5 292.5,94.5 291.5,90.5 286.5,91.5 286.5,88.5 284.5,85.5 279.8,87.3 278.5,89.5 275.5,91.5 273.5,86.5 273.5,84.5 
                270.5,83.5 269.5,79.5 264.5,77.5 263.5,72.5 265.5,70.5 265.5,68.5 263.5,66.5 263.5,63.5 261.5,58.5 263.5,57.5 267.5,58.5 
                270.5,57.5 270.5,54.5 269.5,52.5 273.5,49.5 "/>







                        <polygon id="R2" className="st0" data-num="2" points="307.5,29.5 310.5,27.5 311.5,29.5 315.5,30.5 320.5,29.5 325.5,24.5 326.5,28.5 327.5,32.5 332.5,32.5 
                335.5,32.5 337.5,31.5 345.5,37.5 352.5,41.5 350.5,44.5 351.5,47.5 352.5,49.5 352.5,51.5 353.5,54.5 354.5,62.5 353.5,65.5 
                352.5,68.5 353.5,70.5 356.5,73.5 354.5,77.5 354.5,82.5 359.5,86.5 357.5,90.5 359.5,93.5 369.5,101.5 367.5,103.5 363.5,107.5 
                363.5,111.5 365.5,112.5 361.5,112.5 346.5,111.5 337.5,111.5 334.5,110.5 326.5,112.5 327.5,115.5 329.5,118.5 326.5,120.5 
                321.5,121.5 322.5,119.5 320.5,118.5 318.7,113.4 321.5,110.5 316.5,111.5 313.5,110.5 310.5,111.5 303.5,98.5 306.5,93.5 
                309.5,91.5 308.5,88.5 310.5,86.5 324.5,85.5 326.5,83.5 319.5,75.5 319.5,73.5 322.5,70.5 322.5,68.5 312.5,65.5 306.5,73.5 
                303.5,73.5 300.5,73.5 301.5,70.5 303.5,69.5 304.5,62.5 305.5,60.5 307.5,60.5 307.5,57.5 305.5,55.5 305.5,53.5 306.5,50.5 
                308.5,45.5 310.5,45.5 312.5,43.5 312.5,39.5 307.5,37.5 "/>

                        <polygon id="R1" className="st0" data-num="1" points="275.5,10.5 273.5,12.5 274.5,17.5 282.5,24.5 289.5,29.5 298.5,29.5 302.5,27.5 304.5,27.5 307.5,29.5 
                307.5,37.5 302.5,39.5 300.5,43.5 297.5,39.5 292.5,43.5 287.5,43.5 285.5,39.5 277.5,41.5 277.5,46.5 273.5,49.5 267.5,43.5 
                263.5,41.5 261.5,37.5 259.5,35.5 252.5,35.5 258.5,23.5 259.5,14.5 264.5,12.5 270.5,9.5 "/>

                    </svg>
                </div>



                {mapDataAnalytics?.analyticsData?.filter((item, index) => item?.location_id !== '0' && (item?.period?.order || item?.lastPeriod?.order))?.map((item, index) => (<div id={`R${item?.period?.order ? item?.period?.order : item?.lastPeriod?.order}tltp`} className="bullePer" data-reg={item?.period?.order} >
                    <div className="bullecover" onMouseEnter={() => { this.bullHover(item.location_id); }} onMouseLeave={() => { $('.mapInfoBox').stop().hide(150); }}></div>
                    {/* <svg stroke-width="10" xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{ position: 'absolute' }} className="svgTltp">
                        <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9" />
                    </svg>
                    <p className="tltpTxt" >{`${item?.result?.period_evol_percent}${item?.result?.period_evol_percent?.length !== 0 ? "%" : ""}`}</p> */}
                    <MapMarker bgColor={checkMapMarkerColor(item)}> {renderMapMarkerText(item)}</MapMarker>
                </div>))
                }
                {/* <div id="R11tltp" className="bullePer" data-reg="11">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(11);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">7%</p>
                </div>
                <div id="R10tltp" className="bullePer" data-reg="10">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(10);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">4%</p>
                </div>
                <div id="R9tltp" className="bullePer" data-reg="9">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(9);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">14%</p>
                </div>
                <div id="R8tltp" className="bullePer" data-reg="8">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(8);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">110%</p>
                </div>
                <div id="R7tltp" className="bullePer" data-reg="7">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(7);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">15%</p>
                </div>
                <div id="R6tltp" className="bullePer" data-reg="6">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(6);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">25%</p>
                </div>
                <div id="R5tltp" className="bullePer" data-reg="5">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(5);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">15%</p>
                </div>
                <div id="R4tltp" className="bullePer" data-reg="4">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(4);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">9%</p>
                </div>
                <div id="R3tltp" className="bullePer" data-reg="3">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(3);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">10%</p>
                </div>
                <div id="R2tltp" className="bullePer" data-reg="2">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(2);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">15%</p>
                </div>
                <div id="R1tltp" className="bullePer" data-reg="1">
                <div className="bullecover" onMouseEnter={()=>{this.bullHover(1);}} onMouseLeave={()=>{$('.mapInfoBox').stop().hide(150);}}></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="86" height="57" viewBox="0 0 86 57" style={{position:'absolute'}} className="svgTltp">
                <path id="Union_4" data-name="Union 4" d="M11521.875-865H11511a24,24,0,0,1-24-24,24,24,0,0,1,24-24h38a24,24,0,0,1,24,24,24,24,0,0,1-24,24h-9.875l-8.627,9Z" transform="translate(-11487 912.999)" fill="#5063b9"/>
                </svg>
                    <p className="tltpTxt">5%</p>
                </div> */}

            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        mapDataAnalytics: state.DATA_ANALYTICS.mapDataAnalytics,
        loadingMapDataAnalytics: state.DATA_ANALYTICS.loadingMapDataAnalytics,
        loadingDataAnalytics: state.DATA_ANALYTICS.loadingDataAnalytics,
        regions: state.LOCATION.regions,
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}
export default connect(mapStateToProps)(DAmap)