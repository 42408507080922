import authDAO from "../dao/authDAO";
import Store from "../store/configureStore";
import homeDAO from "../dao/homeDAO";

class authService {
    logOut = () => {
        authDAO.then((response) => {
            localStorage.clear();
        })
    }
    isAuthenticatedUser = (callBack) => {
        const authenticatedUser = Store.getState().AUTH.authenticatedUser;
        if (!authenticatedUser) {
            // notify({ msg: messageText.loggedBefore, type: "warning" })
        } else {
            callBack();
        }
    }
    getAuthenticatedUser = () => new Promise(async (resolve, reject) => {
        try {
            const params = {};
            const response = await authDAO.authenticatedUser(params);
            Store.dispatch({ type: "SET_AUTHENTICATED_USER", value: response?.data })
            resolve()
        } catch (error) {
            reject(error)
        }
    })
    getRegions = async () => {
        try {
            let params = {};
            const response = await homeDAO.getRegionsList(params);
            if (response) {
                Store.dispatch({ type: "SET_REGIONS", value: { regions: response?.data?.data ?? [] } });
                Store.dispatch({ type: "SET_ALL_REGIONS", value: { regions: response?.data?.data ?? [] } });

            }
        } catch (error) {
            Store.dispatch({ type: "SET_REGIONS", value: { regions: [] } })
            Store.dispatch({ type: "SET_ALL_REGIONS", value: { regions: [] } })
        }
    }
    getSubRegions = async () => {
        try {
            let params = {};
            const response = await homeDAO.getSubRegionsList(params)
            if (response) {
                Store.dispatch({ type: "SET_SUB_REGIONS", value: { subRegions: response?.data?.data ?? [] } });
                Store.dispatch({ type: "SET_ALL_SUB_REGIONS", value: { subRegions: response?.data?.data ?? [] } });
            }
        } catch (error) {
            Store.dispatch({ type: "SET_SUB_REGIONS", value: { subRegions: [] } });
            Store.dispatch({ type: "SET_ALL_SUB_REGIONS", value: { subRegions: [] } });

        }
    }
    getSectors = async () => {
        try {
            let params = {};
            const response = await homeDAO.getSectorsList(params)
            if (response) {
                Store.dispatch({ type: "SET_SECTORS", value: { sectors: response?.data?.data ?? [] } });
                Store.dispatch({ type: "SET_ALL_SECTORS", value: { sectors: response?.data?.data ?? [] } });
            }
        } catch (error) {
            Store.dispatch({ type: "SET_SECTORS", value: { sectors: [] } });
            Store.dispatch({ type: "SET_ALL_SECTORS", value: { sectors: [] } });
        }
    }
    preloadDataAfterAuthentication = () => new Promise(async (resolve, reject) => {
        this.getAuthenticatedUser().then(() => {
            this.getRegions();
            this.getSubRegions();
            this.getSectors();
            resolve();
        }).catch((error) => reject(error))
    })
}

export default new authService();