import React from 'react';
import PropTypes from 'prop-types';
import "./style.css";
import ContentLoader from "react-content-loader";

const propTypes = {
    children: PropTypes.node,
    /** Pass in a Component to override default element */
    tag: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
        PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.string,
            PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
        ]))
    ]),
    className: PropTypes.string,
    cssModule: PropTypes.object,
    /** start loading */
    loading: PropTypes.bool,
};

const defaultProps = {
    tag: 'div',
    loading: false,
};

const TYPE_WIDTH = {
    "dot": 10,
    "large": 200,
    "extra-small": 23,
    "small": 78,
    "medium": 117,
}
const TYPE_HEIGHT = {
    "dot": 10.4,
    "large": 16,
    "extra-small": 16,
    "small": 16,
    "medium": 16,
}

const TableLoader = (props) => {
    const {
        className,
        cssModule,
        inline,
        columns,
        rowIndex,
        loading,
        tag: Tag,
        ...attributes
    } = props;

    return (
        <tbody>
            {loading ? Array(10)
                .fill("")
                .map((e, i) => {
                    const random = Math.random() * (1 - 0.7) + 0.7;

                    return (<tr style={{ backgroundColor: "#fff" }}>
                        {/* style={{ opacity: Number(2 / i).toFixed(1) }} */}
                        {columns.map((item, index) => (
                            <td style={{ padding: "0.7rem", borderWidth: 0 }}>
                                <div style={{ height: 30, overflow: "hidden", width: item.width ? item.width : TYPE_WIDTH[item.type] }}>
                                    <ContentLoader
                                        key={"ContentTableLoader" + index}
                                        height={30}
                                        width={item.width ? item.width : TYPE_WIDTH[item.type]}
                                        speed={2}
                                        primaryColor="#d9d9d9"
                                        secondaryColor="#ecebeb"
                                        {...props}
                                        style={{ opacity: Number(2 / i).toFixed(1), overflow: "hidden" }}
                                    >
                                        <rect x="" y="10" rx="6" ry="6" width={item.width ? item.width : item.type === "dot" ? TYPE_WIDTH[item.type] : TYPE_WIDTH[item.type] * random} height={TYPE_HEIGHT[item.type]} />
                                    </ContentLoader>
                                </div>

                            </td>
                        ))}

                    </tr>)
                }) :
                null}
        </tbody>


    );
};

TableLoader.propTypes = propTypes;
TableLoader.defaultProps = defaultProps;

export { TableLoader };