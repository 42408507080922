



export const StartSortStrings = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting
    // return myArray.sort(
    //     (a, b) => {
    //         return order === "ASC" ? b?.location[key] - a?.location[key] : a?.location[key] - b?.location[key];
    //     },
    // )
    if (myArray) {
        if (order === "ASC") {
            return myArray.sort(
                (a, b) => {
                    console.log("a?.items", a?.items)
                    if (a?.location[key]?.toLocaleLowerCase() < b?.location[key]?.toLocaleLowerCase()) {
                        return -1;
                    }
                    if (a?.location[key]?.toLocaleLowerCase() > b?.location[key]?.toLocaleLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
            )
        } else {
            return myArray.sort(
                (a, b) => {
                    if (a?.location[key]?.toLocaleLowerCase() < b?.location[key]?.toLocaleLowerCase()) {
                        return -1;
                    }
                    if (a?.location[key]?.toLocaleLowerCase() > b?.location[key]?.toLocaleLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
            ).reverse()
        }
    }
    return myArray;

}

export const StartSortCompareStrings = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting
    // return myArray.sort(
    //     (a, b) => {
    //         return order === "ASC" ? b?.location[key] - a?.location[key] : a?.location[key] - b?.location[key];
    //     },
    // )
    if (myArray) {
        if (order === "ASC") {
            return myArray.sort(
                (a, b) => {

                    if (a?.items[a?.items?.length - 1].period_va < b?.items[a?.items?.length - 1].period_va) {
                        return -1;
                    }
                    if (a?.items[a?.items?.length - 1].period_va > b?.items[a?.items?.length - 1].period_va) {
                        return 1;
                    }
                    return 0;
                }
            )
        } else {
            return myArray.sort(
                (a, b) => {
                    if (a?.items[a?.items?.length - 1].period_va < b?.items[a?.items?.length - 1].period_va) {
                        return -1;
                    }
                    if (a?.items[a?.items?.length - 1].period_va > b?.items[a?.items?.length - 1].period_va) {
                        return 1;
                    }
                    return 0;
                }
            ).reverse()
        }
    }
    return myArray;

}

export const StartSortCompareNumbers = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting

    // console.log("test ", myArray.sort(
    //     (a, b) => {
    //         return b?.result[key] - a?.result[key];
    //     }));
    if (myArray) {
        return myArray.sort(
            (a, b) => {
                return parseInt(b?.groupedPeriodData ?? 0) - parseInt(a?.groupedPeriodData ?? 0);
            },
        )
    }
    return myArray;

}



export const StartSortGlobalStrings = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting
    if (myArray) {
        if (order === "ASC") {
            return myArray.sort(
                (a, b) => {
                    if (a[key]?.toLocaleLowerCase() < b[key]?.toLocaleLowerCase()) {
                        return -1;
                    }
                    if (a[key]?.toLocaleLowerCase() > b[key]?.toLocaleLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
            )
        } else {
            return myArray.sort(
                (a, b) => {
                    if (a[key]?.toLocaleLowerCase() < b[key]?.toLocaleLowerCase()) {
                        return -1;
                    }
                    if (a[key]?.toLocaleLowerCase() > b[key]?.toLocaleLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
            ).reverse()
        }
    }
    return myArray;

}

export const StartSortNumbers = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting

    // console.log("test ", myArray.sort(
    //     (a, b) => {
    //         return b?.result[key] - a?.result[key];
    //     }));
    if (myArray) {
        return myArray.sort(
            (a, b) => {
                return order === "ASC" ? b?.result[key] - a?.result[key] : a?.result[key] - b?.result[key];
            },
        )
    }
    return myArray;

}
export const StartSortTotalNumbers = (myArray, key, order, myArray2) => { // data - row for sorting, array - array fo sorting, order - order of sorting

    // console.log("test ", myArray.sort(
    //     (a, b) => {
    //         return b?.result[key] - a?.result[key];
    //     }));
    if (myArray) {
        return myArray.sort(
            (a, b) => {
                let B = ((b?.result?.period_va ?? 0) / myArray2?.total?.period_va) * 100;
                let A = ((a?.result?.period_va ?? 0) / myArray2?.total?.period_va) * 100;
                return order === "ASC" ? B - A : A - B;
            },
        )
    }
    return myArray;

}



export const StartSortGlobalNumbers = (myArray, key, order) => { // data - row for sorting, array - array fo sorting, order - order of sorting

    // console.log("test ", myArray.sort(
    //     (a, b) => {
    //         return b?.result[key] - a?.result[key];
    //     }));
    if (myArray) {
        return myArray.sort(
            (a, b) => {
                return order === "ASC" ? b[key] - a[key] : a[key] - b[key];
            },
        )
    }
    return myArray;

}