import React, { Component } from 'react';
import routePath from "../values/routesPath";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from '../pages/home';
import Login from '../pages/login';
import Users from '../pages/users';
import PageNotFound from '../pages/pageNotFound';
import DAheader from '../components/DAheader/DAheader';
import ProtectedRoute from './ProtectedRoute';
import ComparisonReport from '../pages/comparisonRepport';
import MarketViewRepport from '../pages/marketViewRepport ';

class MainNavigator extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div style={{ position: "relative" }}>
                <BrowserRouter  >
                    <Switch>
                        <Route path={`${routePath.Login.list}`} exact  >{(props) => <Login {...props} />}</Route>
                        <ProtectedRoute path={`${routePath.Home.list}`} exact CustomComponent={() => <Home />} />
                        <ProtectedRoute path={`${routePath.ComparisonReport.list}`} exact CustomComponent={() => <ComparisonReport />} />
                        <ProtectedRoute path={`${routePath.marketView.list}`} exact CustomComponent={() => <MarketViewRepport />} />
                        <ProtectedRoute path={`${routePath.Users.list}`} exact CustomComponent={() => <Users />} />
                        {/* <Route path={`${routePath.Home.list}`} exact>{(props) => (<div> <DAheader /> <Home {...props} /></div>)}</Route> */}
                        {/* <Route path={`${routePath.Users.list}`} exact >{(props) => (<div> <DAheader /><Users {...props} /></div>)}</Route> */}
                        <Route path={`*`} exact >{(props) => <PageNotFound {...props} />}</Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default MainNavigator;