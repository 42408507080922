import React from 'react';
import Select from 'react-select';
import "./style.css";

const SearchSelect = ({ children, ...rest }) => {
    return (
        <Select
            name={rest?.name || "searchSelect"}
            className="basic-select"
            classNamePrefix="basic-select"
            options={rest?.options}
            defaultValue={rest?.defaultValue}
            value={rest?.value}
            onChange={rest?.onChange}
            isSearchable={true}
            isClearable={true}
            placeholder="Sélectionner"
            {...rest}

        />
    );
}

export default SearchSelect;
