import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import { StartSortGlobalStrings } from '../../helpers/sort';


const INITIAL_FIELDS = {
    data: [{ id: "MA", name: 'Maroc' }],
    loading: false,
}

class CountrySearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        // this.preload();
    }
    preload = () => {
    }
    render() {
        const { data } = this.state;
        const { onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Pays</Form.Label>
                <SearchSelect
                    name="CountrySearchSelect"
                    options={StartSortGlobalStrings(data?.map((item, index) => ({ value: item?.id, label: item?.name ?? "" })), "label", "ASC")}
                    defaultValue={{ value: "MA", label: 'Maroc' }}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }

}

export default CountrySearchSelect;
