import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import { connect } from 'react-redux';
import { StartSortGlobalStrings } from '../../helpers/sort';


const INITIAL_FIELDS = {
    data: [],
    // for making the filter
    initialData: [],
    loading: false,
    parentId: null,
}

class SubRegionsSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.getAll();
    }
    // filter data with region
    filterData = () => {
        const { parentId } = this.state;
        if (parentId) {
            this.setState({
                data: this.state.initialData?.filter((item, index) => parseInt(parentId) === parseInt(item?.regionId))
            })
        }
    }
    componentDidUpdate() {
        const { searchItems } = this.props;
        if (this.state.parentId !== searchItems?.location?.region?.value) {
            this.setState({ parentId: searchItems?.location?.region?.value }, this.filterData)
        }
    }
    render() {
        const { data } = this.state;
        const { searchItems, onChange, value, disabled } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Sous-région</Form.Label>
                <SearchSelect
                    disabled={disabled ?? false}
                    name="SubRegionsSearchSelect"
                    options={StartSortGlobalStrings(data?.map((item, index) => ({ value: item?.id, label: item?.name ?? "" })), "label", "ASC")}
                    defaultValue={searchItems?.location?.subRegion}
                    value={value ? value : searchItems?.location?.subRegion}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        try {
            const { searchItems } = this.props;
            let params = {};
            this.setState({ loading: true })
            const response = await homeDAO.getSubRegionsList(params)
            this.setState({ loading: false })
            this.setState({ data: response?.data?.data ?? [], initialData: response?.data?.data ?? [] });
            if (searchItems?.location?.region?.value) {
                this.setState({ parentId: searchItems?.location?.region?.value }, this.filterData)
            }
        } catch (error) {
            this.setState({ loading: false })
        }
    }
}
const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(SubRegionsSearchSelect);
