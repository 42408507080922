
import Store from "../store/configureStore";
import homeDAO from "../dao/homeDAO";
import moment from "moment";
import { getOrderById, myRecize } from "./helpersServices";
import comparisonDataAnalyticsService from "./comparisonDataAnalyticsService";
import savedSearchDAO from "../dao/savedSearchDAO";
class savedSearchService {
    // type params can be for map component or table compoenent
    saveMainSearch = async (callBack) => {
        try {
            const params = {}
            const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
            const label = Store.getState().SAVED_SEARCH.label;
            const report = Store.getState().SAVED_SEARCH.report;

            let data = {
                "data": {
                    "search": {
                        "report": "main",
                        "label": label,
                        "data": {
                            "items": searchItems?.items,
                            "location": searchItems?.location,
                            "analyticsType": searchItems?.analyticsType,
                            "mapDisplayFilter": searchItems?.mapDisplayFilter,
                            "groupeType": searchItems?.groupeType,
                            "sellerType": searchItems?.sellerType,
                        }
                    }
                }
            }
            const response = await savedSearchDAO.add(params, data);
            callBack();
        } catch (error) {
            console.log("error", error)
        }
    }
    saveComparisonSearch = async (callBack) => {
        try {
            const params = {}
            const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
            const label = Store.getState().SAVED_SEARCH.label;
            const report = Store.getState().SAVED_SEARCH.report;

            let data = {
                "data": {
                    "search": {
                        "report": "comparison",
                        "label": label,
                        "data": {
                            "comparisonItems": searchItems?.comparisonItems,
                            "location": searchItems?.location,
                            "analyticsType": searchItems?.analyticsType,
                            "mapDisplayFilter": searchItems?.mapDisplayFilter,
                            "groupeType": searchItems?.groupeType,
                            "sellerType": searchItems?.sellerType,
                        }
                    }
                }
            }
            const response = await savedSearchDAO.add(params, data);
            callBack();
        } catch (error) {
            console.log("error", error)
        }
    }
    saveMarketViewSearch = async (callBack) => {
        try {
            const params = {}
            const searchMarketView = Store.getState().MARKET_VIEW.searchMarketView;
            const label = Store.getState().SAVED_SEARCH.label;
            const report = Store.getState().SAVED_SEARCH.report;

            let data = {
                "data": {
                    "search": {
                        "report": "marketView",
                        "label": label,
                        "data": {
                            ...searchMarketView,
                        }
                    }
                }
            }
            const response = await savedSearchDAO.add(params, data);
            callBack();
        } catch (error) {
            console.log("error", error)
        }
    }
    deleteSearch = async (id, callBack) => {
        try {
            const params = {};
            const response = await savedSearchDAO.delete(id, params);
            callBack();
            console.log("response", response);
        } catch (error) {
            console.log("error", error)
        }
    }
    affectSavedSearch = async (item, callBack = () => { }) => {
        if (item?.data) {
            Store.dispatch({ type: "SET_ALL_SEARCH_ITEMS", value: { searchItems: item?.data } });
            callBack();
        }
    }
    affectSavedSearchMarketView = async (item, callBack = () => { }) => {
        if (item?.data) {
            Store.dispatch({ type: "SET_ALL_SEARCH_MARKET_VIEW", value: { searchMarketView: item?.data } });
            callBack();
        }
    }

}

export default new savedSearchService();