
const initialState = {
    authenticatedUser: undefined
};

function authReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_AUTHENTICATED_USER":
            nextState = {
                ...state,
                authenticatedUser: action.value,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default authReducer;