import React, { Component } from 'react';
import "./style.css";
import DAheader from '../../components/DAheader/DAheader';
import DAusers from '../../components/DAbody/Users/DAusers';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <div className="Users__container">
                    <DAusers />
                </div>
            </div>

        );
    }
}

export default Users;