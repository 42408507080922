
import React, { Component } from 'react';
import "./style.css"
import DAsearch from '../../components/DAsearch/DAsearch';
import DAmap from '../../components/DAmap/DAmap';
import DAsearchResult from '../../components/DAsearchResult/DAsearchResult';
import { connect } from 'react-redux';
import dataAnalyticsService from '../../services/dataAnalyticsService';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.configuration();
    }
    preload = () => {
        this.getAll();
    }
    configuration = () => {
        // clear all redux data for not affect other pages         
        this.props.dispatch({ type: "RESET_ALL_SEARCH_ITEMS", value: {} });
        this.props.dispatch({ type: "RESET_DATA_ANALYTICS", value: {} });
        dataAnalyticsService.setSearchFiltersFromParamsToRedux(dataAnalyticsService.normalSearch);
    }
    render() {
        return (
            <div className="Home__container">
                <div style={{ display: 'flex' }}>
                    <DAsearch type="normal" />
                    <DAmap />
                </div>
                <DAsearchResult />
            </div>
        );
    }
    getAll = async () => {

    }
}

const mapStateToProps = (state) => {
    return {}
}
export default connect(mapStateToProps)(Home);