import ApiCall from "./ApiCall";
import routesPath from "../values/routesPath";
import { CODES } from "../values/translate";
import { getOrderByName } from "../services/helpersServices";

class usersDAO {

    getAll = (params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/users`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    getElementById = (id, params) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.get(`/users/${id}`, params);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    add = (params, data) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.post(`/users`, params, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    update = (id, params, data) => new Promise(async (resolve, reject) => {
        try {
            const response = await ApiCall.patch(`/users/${id}`, params, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    disable = (id) => new Promise(async (resolve, reject) => {
        try {
            let data = {
                "data": {
                    "user": {
                        "status": "inactive"
                    }
                }
            }
            const response = await ApiCall.patch(`/users/${id}`, {}, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })
    enable = (id) => new Promise(async (resolve, reject) => {
        try {
            let data = {
                "data": {
                    "user": {
                        "status": "active"
                    }
                }
            }
            const response = await ApiCall.patch(`/users/${id}`, {}, data);
            resolve(response);
        } catch (error) {
            reject(error)
        }
    })

}
export default new usersDAO();