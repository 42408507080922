
import Store from "../store/configureStore";
import homeDAO from "../dao/homeDAO";
import moment from "moment";
import { getOrderById } from "./helpersServices";
import { StartSortStrings, StartSortCompareStrings, StartSortCompareNumbers } from "../helpers/sort";
class comparisonDataAnalyticsService {
    // type params can be for map component or table compoenent

    getRequestsParams = () => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        const comparisonItems = Store.getState().SEARCH_ITEMS.searchItems.comparisonItems;
        const globalParams = {
            "location[regionId]": searchItems?.location?.region?.value ?? "null",
            "location[subregionId]": searchItems?.location?.subRegion?.value ?? "null",
            "location[sectorId]": searchItems?.location?.sector?.value ?? "null",
            "comparativePeriod[startDate]": this.getLastPeriod()?.start,
            "comparativePeriod[endDate]": this.getLastPeriod()?.end,
            "referencePeriod[startDate]": this.getPeriod()?.start,
            "referencePeriod[endDate]": this.getPeriod()?.end,
            "sellerType": searchItems?.sellerType?.value ?? "null",
        }
        let globalParamsForEachItemsGroup = [];
        let allRequestParams = [];
        comparisonItems?.map((item, index) => {
            if (item?.items?.length !== 0) {
                globalParamsForEachItemsGroup.push({
                    ...globalParams,
                    items: item?.items?.map((item, index) => item?.value) ?? [],
                })
            }
        })
        globalParamsForEachItemsGroup.map((item, index) => {
            allRequestParams.push({
                ...item,
                "period[start]": this.getPeriod()?.start,
                "period[end]": this.getPeriod()?.end,
            })
            allRequestParams.push({
                ...item,
                "period[start]": this.getLastPeriod()?.start,
                "period[end]": this.getLastPeriod()?.end,
            })
        })
        // for grouped i use the first items group
        if (globalParamsForEachItemsGroup.length !== 0) {
            allRequestParams.push({
                ...globalParamsForEachItemsGroup[0],
                "period[start]": this.getPeriod()?.start,
                "period[end]": this.getPeriod()?.end,
                grouped: searchItems?.groupeType?.value ?? "null"
            });
            allRequestParams.push({
                ...globalParamsForEachItemsGroup[0],
                "period[start]": this.getLastPeriod()?.start,
                "period[end]": this.getLastPeriod()?.end,
                grouped: searchItems?.groupeType?.value ?? "null"
            })
        }
        return (allRequestParams)
    }

    orderMapResponse = (array) => (
        array?.map((item, index) => ({
            ...item,
            order: getOrderById(item?.location_id)
        })).sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
    )

    getDataAnalyticFor = async (callBack = () => { }) => {
        try {
            const responses = await homeDAO.allDataAnalyticsRequests([...this.getRequestsParams(),])
            let length = responses?.length;
            if (length > 0) {
                let groupedLastPeriodData = responses[length - 1]?.data?.data;
                let groupedPeriodData = responses[length - 2]?.data?.data;
                let resultForEachItem = [];
                for (let i = 0; i < length; i = i + 2) {
                    // console.log("response i", responses[i]);
                    // console.log("response i+1", responses[i + 1]);
                    let periodData = responses[i]?.data?.data;
                    let lastPeriodData = responses[i + 1]?.data?.data;
                    resultForEachItem = [...resultForEachItem, this.formatData(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData)];

                    // resultForEachItem.push(this.formatData(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData))
                }
                let renderedData;
                const comparisonItems = Store.getState().SEARCH_ITEMS.searchItems.comparisonItems;
                const groupeItems = Store.getState().DATA_ANALYTICS.groupeItems;
                // resultForEachItem.map((item, index) => {
                //     renderedData = {
                //         header: [...comparisonItems.filter((item, index) => (item?.items?.length !== 0)), { items: [{ label: groupeItems?.name ?? "" }] }],
                //         total: resultForEachItem.map((item, index) => item?.total),
                //         body: this.concatLocations(resultForEachItem),
                //     }
                // })

                // add groupedPeriodData in objet
                let groupedPeriodDataArray = this.concatLocations(resultForEachItem)?.map((item) => {
                    if (item?.items[0]) {
                        return ({
                            ...item,
                            groupedPeriodData: item?.items[0]?.groupedPeriodData ?? 0,
                        })
                    } else {
                        let item2 = item?.items?.filter((item3) => (item3 ? true : false));
                        return ({
                            ...item,
                            groupedPeriodData: item2[0]?.groupedPeriodData ?? 0,
                        })
                    }


                })
                // end add groupedPeriodData in objet

                renderedData = {
                    header: [...comparisonItems.filter((item, index) => (item?.items?.length !== 0)), { items: [{ label: groupeItems?.name ?? "" }] }],
                    total: resultForEachItem.map((item, index) => item?.total),
                    body: StartSortCompareNumbers(groupedPeriodDataArray, "name", "DESC"),
                }
                console.log("renderedData", renderedData)
                Store.dispatch({ type: "SET_COMPARISON_TABLE_DATA_ANALYTICS", value: { comparisonTableDataAnalytics: renderedData } })
            }

            // if (responses.length !== 0) {
            //     // formatting the data and calculate the analitycs 
            //     let periodData = tableResponses[0]?.data?.data;
            //     let lastPeriodData = tableResponses[1]?.data?.data;

            //     // formatting grouped data and calculate the analitycs 
            //     let groupedPeriodData = tableResponses[2]?.data?.data;
            //     let groupedLastPeriodData = tableResponses[3]?.data?.data;
            //     Store.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: this.formatData(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData) } });
            // }

            callBack();
        } catch (error) {
            console.log(error)
        }
    }
    concatLocations = (resultForEachItem) => {
        let allLocationsData = [];
        resultForEachItem.forEach((array, index) => {
            array?.analyticsData?.map((item, index2) => {
                const searchedIndex = allLocationsData.findIndex((item2, index) => parseInt(item2?.location_id) === parseInt(item.location_id));
                let object = {}
                if (searchedIndex !== -1) {
                    let tmpItems = allLocationsData[searchedIndex]?.items;
                    tmpItems[index] = item?.result;
                    object = {
                        location: item?.location,
                        location_id: item?.location_id,
                        // items: [...allLocationsData[searchedIndex]?.items, item?.result],
                        items: tmpItems,
                    }
                    allLocationsData[searchedIndex] = object
                } else {
                    let tmpItems = [];
                    tmpItems[index] = item?.result;
                    object = {
                        location: item?.location,
                        location_id: item?.location_id,
                        items: tmpItems
                    }
                    // allLocationsData[index] = object
                    allLocationsData.push(object)
                }
            })
        })
        return allLocationsData;
    }


    getDataAnalytic = (MapCallBack = () => { }, callBack = () => { }) => {
        Store.dispatch({ type: "SET_LOADING_DATA_ANALYTICS", value: { loadingDataAnalytics: true } });
        this.getDataAnalyticFor(() => {
            MapCallBack();
            callBack();
        });
        Store.dispatch({ type: "SET_LOADING_DATA_ANALYTICS", value: { loadingDataAnalytics: false } });
    }


    getGroupeItems = async () => {
        try {
            const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
            if (searchItems?.comparisonItems?.length !== 0) {
                const itemId = searchItems?.comparisonItems[0]?.items[0]?.value;
                const params = {};
                const response = await homeDAO.getGroupeItems(itemId, params);
                if (response?.data?.data) {
                    const groupeItem = response?.data?.data?.filter((item, index) => parseInt(item?.typeId) === parseInt(searchItems?.groupeType?.value))[0];
                    Store.dispatch({ type: "SET_GROUPE_ITEMS", value: { groupeItems: groupeItem } });
                } else {
                    Store.dispatch({ type: "SET_GROUPE_ITEMS", value: { groupeItems: undefined } });
                }
            }

        } catch (error) {

        }
    }


    formatData = (periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData, ) => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        let analyticsTypeIsVolume = searchItems?.analyticsType?.toLocaleLowerCase() === "volume";
        let resultData = {}
        // for total row
        // VA(n)
        let period_va_total = 0;
        if (!analyticsTypeIsVolume) {
            period_va_total = periodData?.reduce((accumulator, item) => parseFloat(accumulator) + (this.getNumFromString(item["total_value"]) ?? 0), 0);
        } else {
            period_va_total = periodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item["total_quantity"] ?? 0), 0);
        }
        // VA(n-1)
        let lastPeriod_va_total = 0;
        if (!analyticsTypeIsVolume) {
            lastPeriod_va_total = lastPeriodData?.reduce((accumulator, item) => parseFloat(accumulator) + (this.getNumFromString(item["total_value"]) ?? 0), 0);
        } else {
            lastPeriod_va_total = lastPeriodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item["total_quantity"] ?? 0), 0);
        }


        // groupedPeriodData total 
        let groupedPeriodData_total = 0;

        periodData?.forEach((item, index) => {
            // groupedPeriodData
            let mappingIndex = this.getIndexFormLocationId(groupedPeriodData, item?.location_id);

            if (mappingIndex !== -1) {
                if (!analyticsTypeIsVolume) {
                    groupedPeriodData_total = groupedPeriodData_total + (groupedPeriodData[mappingIndex]["total_value"] ? this.getNumFromString(groupedPeriodData[mappingIndex]["total_value"]) : 0);
                } else {
                    groupedPeriodData_total = groupedPeriodData_total + (groupedPeriodData[mappingIndex]["total_quantity"] ? groupedPeriodData[mappingIndex]["total_quantity"] : 0);

                }
            }
        })


        let groupedLastPeriodData_total = 0;

        lastPeriodData?.forEach((item, index) => {
            // groupedPeriodData
            let mappingIndex = this.getIndexFormLocationId(groupedLastPeriodData, item?.location_id);
            if (mappingIndex !== -1) {
                if (!analyticsTypeIsVolume) {
                    groupedLastPeriodData_total = groupedLastPeriodData_total + (groupedLastPeriodData[mappingIndex]["total_value"] ? this.getNumFromString(groupedLastPeriodData[mappingIndex]["total_value"]) : 0);
                } else {
                    groupedLastPeriodData_total = groupedLastPeriodData_total + (groupedLastPeriodData[mappingIndex]["total_quantity"] ? groupedLastPeriodData[mappingIndex]["total_quantity"] : 0);

                }
            }
        })

        // end groupedPeriodData

        resultData = {
            ...resultData,
            total: {
                // VA(n)
                period_va: analyticsTypeIsVolume ? period_va_total : parseInt(period_va_total),
                // VA(n-1)
                lestPeriod_va: analyticsTypeIsVolume ? lastPeriod_va_total : parseInt(lastPeriod_va_total),
                // Evol (VA)	
                period_evol: parseInt(period_va_total - lastPeriod_va_total),
                // Evol (%)	
                period_evol_percent: lastPeriod_va_total !== 0 ? parseFloat(((period_va_total - lastPeriod_va_total) / lastPeriod_va_total) * 100).toFixed(2) : 100,
                groupedPeriodData: groupedPeriodData_total,
                groupedLastPeriodData: groupedLastPeriodData_total,
                grouped_period_evol_percent: groupedLastPeriodData_total !== 0 ? parseFloat(((groupedPeriodData_total - groupedLastPeriodData_total) / groupedLastPeriodData_total) * 100).toFixed(2) : 100,

            },

        }
        // if the analytics for a map 
        resultData = {
            ...resultData,
            analyticsData: this.calculateDataAnalytics(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData)
        }
        return resultData;
    }
    getIndexFormLocationId = (array, locationId) => {
        return array?.findIndex((item, index) => parseInt(item?.location_id) === parseInt(locationId));
    }
    getNumFromString(string) {
        return parseFloat(String(string))
    }
    getIntNumFromString(string) {
        return parseInt(String(string))
    }
    calculateDataAnalytics = (period, lastPeriod, groupedPeriodData, groupedLastPeriodData) => {
        const mapDataAnalytics = Store.getState().DATA_ANALYTICS.mapDataAnalytics;
        let dataAnalyticsResult = [];
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        let analyticsType = searchItems?.analyticsType?.toLocaleLowerCase() === "volume" ? "total_quantity" : "total_value";
        period?.forEach((item, index) => {
            if (!dataAnalyticsResult?.some((itemTest, index) => itemTest?.location_id === item?.location_id)) {
                // get index of the item in n-1 result
                const indexInLastPeriod = lastPeriod?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // for row
                // VA(n)

                let period_va = 0;
                if (analyticsType === "total_quantity") {
                    period_va = parseInt(item[analyticsType] ?? 0);
                } else {
                    period_va = this.getIntNumFromString(item[analyticsType]) ?? 0
                }

                // VA(n-1)
                let lastPeriod_va = 0;
                if (analyticsType === "total_quantity") {
                    lastPeriod_va = indexInLastPeriod !== -1 ? parseInt(lastPeriod[indexInLastPeriod][analyticsType] ?? 0) : 0;
                } else {
                    lastPeriod_va = indexInLastPeriod !== -1 ? this.getIntNumFromString(lastPeriod[indexInLastPeriod][analyticsType]) ?? 0 : 0;
                }

                // PDM (N)
                const indexInGroupedPeriod = groupedPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);

                let groupedPeriodData_tmp = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? groupedPeriodData[indexInGroupedPeriod][analyticsType] : 0;


                // PDM (N-1)
                let lastPeriod_pdm = 0;
                const indexInGroupedLastPeriod = groupedLastPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                if (indexInGroupedLastPeriod !== -1) {
                    lastPeriod_pdm = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? (lastPeriod_va / groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType]) : 0
                }

                let groupedLastPeriodData_tmp = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] : 0;


                dataAnalyticsResult?.push({
                    location_id: item?.location_id,
                    location: item?.location,
                    period: item,
                    lastPeriod: indexInLastPeriod !== -1 ? lastPeriod[indexInLastPeriod] : null,
                    result: {
                        period_va,
                        lastPeriod_va,
                        period_evol: parseInt(period_va - lastPeriod_va),
                        period_evol_percent: lastPeriod_va !== 0 ? parseFloat(((period_va - lastPeriod_va) / lastPeriod_va) * 100).toFixed(2) : 100,
                        groupedPeriodData: groupedPeriodData_tmp,
                        groupedLastPeriodData: groupedLastPeriodData_tmp,

                    }
                })
            }
        })

        lastPeriod?.forEach((item, index) => {
            if (!dataAnalyticsResult?.some((itemTest, index) => itemTest?.location_id === item?.location_id)) {
                // get index of the item in n-1 result
                const indexInPeriod = period?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // for row
                // VA(n)
                let lastPeriod_va = 0;
                if (analyticsType === "total_quantity") {
                    lastPeriod_va = parseInt(item[analyticsType] ?? 0);
                } else {
                    lastPeriod_va = this.getIntNumFromString(item[analyticsType]) ?? 0
                }
                // VA(n-1)
                let period_va = 0;
                if (analyticsType === "total_quantity") {
                    period_va = indexInPeriod !== -1 ? parseInt(period[indexInPeriod][analyticsType] ?? 0) : 0;
                } else {
                    period_va = indexInPeriod !== -1 ? this.getIntNumFromString(period[indexInPeriod][analyticsType]) ?? 0 : 0;
                }

                // PDM (N)
                const indexInGroupedPeriod = groupedPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);

                let groupedPeriodData_tmp = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? groupedPeriodData[indexInGroupedPeriod][analyticsType] : 0;


                // PDM (N-1)
                let lastPeriod_pdm = 0;
                const indexInGroupedLastPeriod = groupedLastPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // if (indexInGroupedLastPeriod !== -1) {
                //     lastPeriod_pdm = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? (lastPeriod_va ?? 0 / groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType]) : 0
                // }
                let groupedLastPeriodData_tmp = 0;

                if (indexInGroupedLastPeriod !== -1) {
                    groupedLastPeriodData_tmp = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] : 0;
                    if (groupedLastPeriodData_tmp) {
                        lastPeriod_pdm = lastPeriod_va / groupedLastPeriodData_tmp
                    }
                }

                dataAnalyticsResult?.push({
                    location_id: item?.location_id,
                    location: item?.location,
                    period: indexInPeriod !== -1 ? period[indexInPeriod] : null,
                    lastPeriod: item,
                    result: {
                        period_va,
                        lastPeriod_va,
                        period_evol: parseInt(period_va - lastPeriod_va),
                        period_evol_percent: lastPeriod_va !== 0 ? parseFloat(((period_va - lastPeriod_va) / lastPeriod_va) * 100).toFixed(2) : 100,
                        groupedPeriodData: groupedPeriodData_tmp,
                        groupedLastPeriodData: groupedLastPeriodData_tmp,
                        grouped_period_evol_percent: groupedLastPeriodData_tmp !== 0 ? parseFloat(((groupedPeriodData_tmp - groupedLastPeriodData_tmp) / groupedLastPeriodData_tmp) * 100).toFixed(2) : 100,

                    }
                })
            }
        })

        return dataAnalyticsResult;
    }


    convertArrayToObject = (array, isForMap) => (
        {
            location_id: array[0]?.location_id,
            location: {
                id: "0",
                name: "Autre",
                regionId: "0",
            },
            period: null,
            lastPeriod: null,
            result: {
                period_va: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.period_va), 0),
                lastPeriod_va: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.lastPeriod_va), 0),
                period_evol: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.period_evol), 0),
                period_evol_percent: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.period_evol_percent), 0)).toFixed(isForMap ? 0 : 2),
                groupedPeriodData: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.groupedPeriodData), 0),
                groupedLastPeriodData: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.groupedLastPeriodData), 0),
            }
        }
    )





    getPeriod = () => {
        const date = Store.getState().SEARCH_ITEMS?.searchItems?.date;
        if (date?.referenceDate?.length !== 0) {
            return ({ start: moment(date?.referenceDate[0]).format("YYYY-MM-DD"), end: moment(date?.referenceDate[1]).format("YYYY-MM-DD") })
        }
    }
    getLastPeriod = () => {
        const date = Store.getState().SEARCH_ITEMS?.searchItems?.date;
        if (date?.comparisonDate?.length !== 0) {
            return ({ start: moment(date?.comparisonDate[0]).format("YYYY-MM-DD"), end: moment(date?.comparisonDate[1]).format("YYYY-MM-DD") })
        }
    }
    // getLastPeriod = () => {
    //     const date = Store.getState().SEARCH_ITEMS.searchItems?.date
    //     if (date?.month) {
    //         let startDate = moment(date?.month?.value?.startDate);
    //         let endDate = moment(date?.month?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else if (date?.quarter) {
    //         let startDate = moment(date?.quarter?.value?.startDate);
    //         let endDate = moment(date?.quarter?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else if (date?.semester) {
    //         let startDate = moment(date?.semester?.value?.startDate);
    //         let endDate = moment(date?.semester?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else {
    //         let startDate = moment(`${date?.year?.value}-01-01`).subtract(1, "year").format("YYYY-MM-DD");
    //         let endDate = moment(`${date?.year?.value}-01-01`).subtract(1, "year").add(12, "month").subtract(1, "day").format("YYYY-MM-DD");
    //         return { start: startDate, end: endDate };
    //     }
    // }
    getLocationObject = (location_id, isForMap = false) => {
        const locationSearch = Store.getState().SEARCH_ITEMS.searchItems?.location
        const locationArrays = Store.getState().LOCATION;
        if (!isForMap) {
            if (locationSearch?.subRegion) {
                return locationArrays?.sectors?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else if (locationSearch?.region) {
                return locationArrays?.subRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else {
                return locationArrays?.regions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            }
        } else {
            return locationArrays?.regions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
        }

    }
}
// location: {
//     region: searchItems?.location?.region ?? null,
//     subregion: searchItems?.location?.subRegion ?? null,
//     sector: searchItems?.location?.sector ?? null,
// },
export default new comparisonDataAnalyticsService();