
import Store from "../store/configureStore";
import homeDAO from "../dao/homeDAO";
import moment from "moment";
import { getOrderById, myRecize } from "./helpersServices";
import comparisonDataAnalyticsService from "./comparisonDataAnalyticsService";
class marketViewService {
    // type params can be for map component or table compoenent

    getRequestsParams = () => {
        const searchMarketView = Store.getState()?.SEARCH_MARKET_VIEW?.searchMarketView;
        const date = Store.getState()?.SEARCH_MARKET_VIEW?.searchMarketView?.date;
        // const getStartDate = () => {
        //     return `${moment(date?.endDate).format("YYYY")}-${moment(date?.startDate).format("MM")}-01`
        // }
        const getMonth = () => {
            return `${moment(date?.startDate).format("MM")}`;
        }
        // const getEndDate = () => {
        //     let daysInMonth = moment(`${moment(date?.endDate).format("YYYY")}-${moment(date?.startDate).format("MM")}`, "YYYY-MM").daysInMonth();
        //     return `${moment(date?.endDate).format("YYYY")}-${moment(date?.startDate).format("MM")}-${daysInMonth}`
        // }
        // const getEndDate = () => {
        //     let daysInMonth = moment(`${moment(date?.endDate).format("YYYY")}-${moment(date?.startDate).format("MM")}`, "YYYY-MM").daysInMonth();
        //     return `${moment(date?.endDate).format("YYYY")}-${moment(date?.startDate).format("MM")}-${daysInMonth}`
        // }
        const getYear = () => {
            return `${moment(date?.endDate).format("YYYY")}`
        }

        let globalParams = {
            "sellerType": searchMarketView?.sellerType?.value ?? "0",
        }
        if (searchMarketView?.filterType?.value === "0") {
            globalParams = {
                ...globalParams,
                "itemId": searchMarketView?.items?.value ?? "null",
            }
        }
        if (searchMarketView?.filterType?.value !== "0") {
            globalParams = {
                ...globalParams,
                "groupType": searchMarketView?.filterType?.value ?? "null",
                "groupId": searchMarketView?.groupe?.value ?? "null",
            }
        }


        return ({
            ...globalParams,
            "period[month]": getMonth(),
            "period[year]": getYear(),
        })
    }

    orderMapResponse = (array) => (
        array?.map((item, index) => ({
            ...item,
            order: getOrderById(item?.location_id)
        })).sort((a, b) => parseInt(a?.order) - parseInt(b?.order))
    )

    getMarketViewFor = async (callBack = () => { }, url = null) => {
        try {
            // request params for a table
            let tableRequestsParams = this.getRequestsParams();
            // end request params for a table
            Store.dispatch({ type: "SET_LOADING_MARKET_VIEW", value: { loadingMarketView: true } });
            const response = await homeDAO.getMarketView(tableRequestsParams, url)
            Store.dispatch({ type: "SET_STATS_MARKET_VIEW", value: { statsMarketView: response?.data?.data?.stats ?? undefined } });
            Store.dispatch({ type: "SET_TABLE_MARKET_VIEW", value: { tableMarketView: response?.data?.data?.relatedResources ?? undefined } });
            Store.dispatch({ type: "SET_META_DATA_MARKET_VIEW", value: { metadataMarketView: response?.data?.data?.metadata ?? undefined } });
            Store.dispatch({ type: "SET_LOADING_MARKET_VIEW", value: { loadingMarketView: false } });

            // const mapResponses = responses.slice(0, 4);
            // const tableResponses = responses.slice(4, 8);
            // if (mapResponses.length !== 0) {
            //     // formatting the data and calculate the analitycs 
            //     let periodData = mapResponses[0]?.data?.data;
            //     let lastPeriodData = mapResponses[1]?.data?.data;

            //     // formatting grouped data and calculate the analitycs 
            //     let groupedPeriodData = mapResponses[2]?.data?.data;
            //     let groupedLastPeriodData = mapResponses[3]?.data?.data;

            //     // order for a map
            //     let formattedPeriodData = this.orderMapResponse(periodData);
            //     let formattedLastPeriodData = this.orderMapResponse(lastPeriodData);
            //     // end order for a map
            //     Store.dispatch({ type: "SET_MAP_DATA_ANALYTICS", value: { mapDataAnalytics: this.formatData(formattedPeriodData, formattedLastPeriodData, groupedPeriodData, groupedLastPeriodData, true) } });
            //     if (tableResponses.length !== 0) {
            //         // formatting the data and calculate the analitycs 
            //         let periodData = tableResponses[0]?.data?.data;
            //         let lastPeriodData = tableResponses[1]?.data?.data;
            //         console.log("periodData", periodData);
            //         console.log("lastPeriodData", lastPeriodData);

            //         // formatting grouped data and calculate the analitycs 
            //         let groupedPeriodData = tableResponses[2]?.data?.data;
            //         let groupedLastPeriodData = tableResponses[3]?.data?.data;
            //         Store.dispatch({ type: "SET_TABLE_DATA_ANALYTICS", value: { tableDataAnalytics: this.formatData(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData) } });
            //     }
            // }

            callBack();
        } catch (error) {
            console.log(error)
        }
    }

    getMarketView = (callBack = () => { }, url = null) => {
        // this.getSellersStatsByLocation();
        this.getMarketViewFor(() => {
            callBack();
        }, url);

    }
    getSellersStatsByLocation = async () => {
        try {
            const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
            let params = {
                "sellerType": searchItems?.sellerType?.value ?? "null",
            };
            if (searchItems?.location?.region?.value) {
                params = {
                    ...params,
                    regionId: searchItems?.location?.region?.value ?? "null"
                }
            }

            const response = await homeDAO.getSellersStatsByLocation(params);
            Store.dispatch({ type: "SET_SELLERS_STATS", value: { sellersStats: response?.data?.data ? Object.values(response?.data?.data) : [] } });
        } catch (error) {
            console.log(error)
        }
    }
    getSellerStats = (locationId) => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        if (!searchItems?.location?.subRegion?.value) {
            const sellersStats = Store.getState().SELLERS_STATS?.sellersStats ?? [];
            const searchedObject = sellersStats?.filter((item, index) => parseInt(item?.locationId) === parseInt(locationId));
            if (sellersStats && searchedObject && searchedObject?.length !== 0) {
                return parseInt(searchedObject[0]?.total)
            } else {
                return 0;
            }
        } else {
            return 0;
        }

    }
    getSellerStatsTotal = () => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        if (!searchItems?.location?.subRegion?.value) {
            const sellersStats = Store.getState().SELLERS_STATS?.sellersStats ?? [];
            const filteredArray = sellersStats?.filter((item, index) => item?.locationId !== null);
            if (sellersStats && filteredArray && filteredArray?.length !== 0) {
                return filteredArray?.reduce((accumulator, item) => accumulator + parseInt(item?.total), 0)
            } else {
                return 0;
            }
        } else {
            return 0;
        }

    }
    getGroupeItems = async () => {
        try {
            const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
            if (searchItems?.items?.length !== 0) {
                const itemId = searchItems?.items[0].value;
                const params = {};
                const response = await homeDAO.getGroupeItems(itemId, params);
                if (response?.data?.data) {
                    const groupeItem = response?.data?.data?.filter((item, index) => parseInt(item?.typeId) === parseInt(searchItems?.groupeType?.value))[0];
                    Store.dispatch({ type: "SET_GROUPE_ITEMS", value: { groupeItems: groupeItem } });
                } else {
                    Store.dispatch({ type: "SET_GROUPE_ITEMS", value: { groupeItems: undefined } });
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    formatData = (periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData, isForMap = false) => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        const mapDataAnalytics = Store.getState().DATA_ANALYTICS.mapDataAnalytics;
        let analyticsTypeIsVolume = searchItems?.analyticsType?.toLocaleLowerCase() === "volume";
        let resultData = {}
        // for total row
        // VA(n)
        let period_va_total = 0;
        if (!analyticsTypeIsVolume) {
            period_va_total = periodData?.reduce((accumulator, item) => parseFloat(accumulator) + (this.getNumFromString(item["total_value"]) ?? 0), 0);
        } else {
            period_va_total = periodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item["total_quantity"] ?? 0), 0);
        }
        // VA(n-1)
        let lastPeriod_va_total = 0;
        if (!analyticsTypeIsVolume) {
            lastPeriod_va_total = lastPeriodData?.reduce((accumulator, item) => parseFloat(accumulator) + (this.getNumFromString(item["total_value"]) ?? 0), 0);
        } else {
            lastPeriod_va_total = lastPeriodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item["total_quantity"] ?? 0), 0);
        }
        // NB de Clients(n)
        let period_num_of_sellers_total = periodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item?.num_of_sellers ?? 0), 0);
        // NB de Clients(n-1)
        let lastPeriod_num_of_sellers_total = lastPeriodData?.reduce((accumulator, item) => parseInt(accumulator) + parseInt(item?.num_of_sellers ?? 0), 0);



        // groupedPeriodData total 
        let groupedPeriodData_total = 0;

        periodData?.forEach((item, index) => {
            // groupedPeriodData
            let mappingIndex = this.getIndexFormLocationId(groupedPeriodData, item?.location_id);

            if (mappingIndex !== -1) {
                if (!analyticsTypeIsVolume) {
                    groupedPeriodData_total = groupedPeriodData_total + (groupedPeriodData[mappingIndex]["total_value"] ? this.getNumFromString(groupedPeriodData[mappingIndex]["total_value"]) : 0);
                } else {
                    groupedPeriodData_total = groupedPeriodData_total + (groupedPeriodData[mappingIndex]["total_quantity"] ? groupedPeriodData[mappingIndex]["total_quantity"] : 0);

                }
            }
        })


        let groupedLastPeriodData_total = 0;

        lastPeriodData?.forEach((item, index) => {
            // groupedPeriodData
            let mappingIndex = this.getIndexFormLocationId(groupedLastPeriodData, item?.location_id);
            if (mappingIndex !== -1) {
                if (!analyticsTypeIsVolume) {
                    groupedLastPeriodData_total = groupedLastPeriodData_total + (groupedLastPeriodData[mappingIndex]["total_value"] ? this.getNumFromString(groupedLastPeriodData[mappingIndex]["total_value"]) : 0);
                } else {
                    groupedLastPeriodData_total = groupedLastPeriodData_total + (groupedLastPeriodData[mappingIndex]["total_quantity"] ? groupedLastPeriodData[mappingIndex]["total_quantity"] : 0);

                }
            }
        })

        // end groupedPeriodData

        // pdm (n)

        let period_pdm_total = 0;
        if (groupedPeriodData_total !== 0) {
            period_pdm_total = parseFloat(period_va_total / groupedPeriodData_total).toFixed(4)
        }
        // end pdm (n)
        // pdm (n-1)

        let lastPeriod_pdm_total = 0;
        if (groupedLastPeriodData_total !== 0) {
            lastPeriod_pdm_total = parseFloat(lastPeriod_va_total / groupedLastPeriodData_total).toFixed(4)
        }
        // end pdm (n-1)

        // const num_of_sellers_tmp = period_num_of_sellers_total + lastPeriod_num_of_sellers_total;
        const num_of_sellers_tmp = period_num_of_sellers_total;

        const seller_stats_tmp = this.getSellerStatsTotal();
        resultData = {
            ...resultData,
            total: {
                // VA(n)
                period_va: analyticsTypeIsVolume ? period_va_total : parseInt(period_va_total),
                // VA(n-1)
                lestPeriod_va: analyticsTypeIsVolume ? lastPeriod_va_total : parseInt(lastPeriod_va_total),
                // Evol (VA)	
                period_evol: parseInt(period_va_total - lastPeriod_va_total),
                // Evol (%)	
                period_evol_percent: lastPeriod_va_total !== 0 ? parseFloat(((period_va_total - lastPeriod_va_total) / lastPeriod_va_total) * 100).toFixed(isForMap ? 0 : 2) : 100,

                num_of_sellers: num_of_sellers_tmp,
                seller_stats_tmp,
                num_of_sellers_percent: seller_stats_tmp && seller_stats_tmp !== 0 ? parseFloat((num_of_sellers_tmp / seller_stats_tmp) * 100).toFixed(2) : 0,

                period_pdm: period_pdm_total * 100,
                lastPeriod_pdm: lastPeriod_pdm_total * 100,
                ie: lastPeriod_pdm_total !== 0 ? (period_pdm_total / lastPeriod_pdm_total) * 100 : 0,
                ip: !isForMap ? mapDataAnalytics?.total?.period_pdm !== 0 ? parseFloat(((period_pdm_total * 100) / mapDataAnalytics?.total?.period_pdm) * 100).toFixed(isForMap ? 0 : 2) : 0 : 1,


                period_va_national: !isForMap ? mapDataAnalytics?.total?.period_va : 0,
                groupedPeriodData_national: !isForMap ? mapDataAnalytics?.total?.groupedPeriodData : 0,
                period_pdm_national: !isForMap ? mapDataAnalytics?.total?.period_pdm : 0,


                groupedPeriodData: groupedPeriodData_total,
                groupedLastPeriodData: groupedLastPeriodData_total,
                grouped_period_evol_percent: groupedLastPeriodData_total !== 0 ? parseFloat(((groupedPeriodData_total - groupedLastPeriodData_total) / groupedLastPeriodData_total) * 100).toFixed(isForMap ? 0 : 2) : 100,

            },

        }
        // if the analytics for a map 
        let pdm_national = isForMap ? (period_pdm_total * 100) : undefined;
        resultData = {
            ...resultData,
            analyticsData: this.calculateDataAnalytics(periodData, lastPeriodData, groupedPeriodData, groupedLastPeriodData, pdm_national, isForMap)
        }
        return resultData;
    }
    getIndexFormLocationId = (array, locationId) => {
        return array?.findIndex((item, index) => parseInt(item?.location_id) === parseInt(locationId));
    }
    getNumFromString(string) {
        return parseFloat(String(string))
    }
    getIntNumFromString(string) {
        return parseInt(String(string))
    }
    calculateDataAnalytics = (period, lastPeriod, groupedPeriodData, groupedLastPeriodData, pdm_national, isForMap = false) => {
        const mapDataAnalytics = Store.getState().DATA_ANALYTICS.mapDataAnalytics;
        let dataAnalyticsResult = [];
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        let analyticsType = searchItems?.analyticsType?.toLocaleLowerCase() === "volume" ? "total_quantity" : "total_value";
        period?.forEach((item, index) => {
            if (!dataAnalyticsResult?.some((itemTest, index) => itemTest?.location_id === item?.location_id)) {
                // get index of the item in n-1 result
                const indexInLastPeriod = lastPeriod?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // for row
                // VA(n)

                let period_va = 0;
                if (analyticsType === "total_quantity") {
                    period_va = parseInt(item[analyticsType] ?? 0);
                } else {
                    period_va = this.getIntNumFromString(item[analyticsType]) ?? 0
                }

                // VA(n-1)
                let lastPeriod_va = 0;
                if (analyticsType === "total_quantity") {
                    lastPeriod_va = indexInLastPeriod !== -1 ? parseInt(lastPeriod[indexInLastPeriod][analyticsType] ?? 0) : 0;
                } else {
                    lastPeriod_va = indexInLastPeriod !== -1 ? this.getIntNumFromString(lastPeriod[indexInLastPeriod][analyticsType]) ?? 0 : 0;
                }
                // NB de Clients(n)
                let period_num_of_sellers = parseInt(item?.num_of_sellers ?? 0);
                // NB de Clients(n-1)
                let lastPeriod_num_of_sellers = indexInLastPeriod !== -1 ? parseInt(lastPeriod[indexInLastPeriod]["num_of_sellers"] ?? 0) : 0;


                // PDM (N)
                let period_pdm = 0;
                const indexInGroupedPeriod = groupedPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                if (indexInGroupedPeriod !== -1) {
                    period_pdm = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? (period_va / groupedPeriodData[indexInGroupedPeriod][analyticsType]) : 0;
                }
                let groupedPeriodData_tmp = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? groupedPeriodData[indexInGroupedPeriod][analyticsType] : 0;


                // PDM (N-1)
                let lastPeriod_pdm = 0;
                const indexInGroupedLastPeriod = groupedLastPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                if (indexInGroupedLastPeriod !== -1) {
                    lastPeriod_pdm = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? (lastPeriod_va / groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType]) : 0
                }

                let groupedLastPeriodData_tmp = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] : 0;

                // const num_of_sellers_tmp = period_num_of_sellers + lastPeriod_num_of_sellers;
                const num_of_sellers_tmp = period_num_of_sellers;
                const seller_stats_tmp = this.getSellerStats(String(item?.location_id));

                dataAnalyticsResult?.push({
                    location_id: item?.location_id,
                    location: item?.location,
                    period: item,
                    lastPeriod: indexInLastPeriod !== -1 ? lastPeriod[indexInLastPeriod] : null,
                    result: {
                        period_va,
                        lastPeriod_va,
                        period_evol: parseInt(period_va - lastPeriod_va),
                        period_evol_percent: lastPeriod_va !== 0 ? parseFloat(((period_va - lastPeriod_va) / lastPeriod_va) * 100).toFixed(isForMap ? 0 : 2) : 100,
                        num_of_sellers: num_of_sellers_tmp,
                        seller_stats_tmp,
                        num_of_sellers_percent: seller_stats_tmp && seller_stats_tmp !== 0 ? parseFloat((num_of_sellers_tmp / seller_stats_tmp) * 100).toFixed(2) : 0,

                        period_pdm: parseFloat(period_pdm * 100).toFixed(isForMap ? 0 : 2),
                        lastPeriod_pdm: parseFloat(lastPeriod_pdm * 100).toFixed(isForMap ? 0 : 2),
                        ie: lastPeriod_pdm !== 0 ? parseFloat((period_pdm / lastPeriod_pdm) * 100).toFixed(isForMap ? 0 : 2) : 0,
                        ip: !isForMap ? mapDataAnalytics?.total?.period_pdm !== 0 ? parseFloat(((period_pdm * 100) / mapDataAnalytics?.total?.period_pdm) * 100).toFixed(isForMap ? 0 : 2) : 0 : parseFloat(((period_pdm * 100) / pdm_national) * 100).toFixed(isForMap ? 0 : 2),


                        period_va_national: !isForMap ? mapDataAnalytics?.total?.period_va : 0,
                        groupedPeriodData_national: !isForMap ? mapDataAnalytics?.total?.groupedPeriodData : 0,
                        period_pdm_national: !isForMap ? mapDataAnalytics?.total?.period_pdm : 0,


                        groupedPeriodData: groupedPeriodData_tmp,
                        groupedLastPeriodData: groupedLastPeriodData_tmp,
                        grouped_period_evol_percent: groupedLastPeriodData_tmp !== 0 ? parseFloat(((groupedPeriodData_tmp - groupedLastPeriodData_tmp) / groupedLastPeriodData_tmp) * 100).toFixed(isForMap ? 0 : 2) : 100,

                    }
                })
            }
        })
        lastPeriod?.forEach((item, index) => {
            if (!dataAnalyticsResult?.some((itemTest, index) => itemTest?.location_id === item?.location_id)) {
                // get index of the item in n-1 result
                const indexInPeriod = period?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // for row
                // VA(n)
                let lastPeriod_va = 0;
                if (analyticsType === "total_quantity") {
                    lastPeriod_va = parseInt(item[analyticsType] ?? 0);
                } else {
                    lastPeriod_va = this.getIntNumFromString(item[analyticsType]) ?? 0
                }
                // VA(n-1)
                let period_va = 0;
                if (analyticsType === "total_quantity") {
                    period_va = indexInPeriod !== -1 ? parseInt(period[indexInPeriod][analyticsType] ?? 0) : 0;
                } else {
                    period_va = indexInPeriod !== -1 ? this.getIntNumFromString(period[indexInPeriod][analyticsType]) ?? 0 : 0;
                }
                // NB de Clients(n)
                let lastPeriod_num_of_sellers = parseInt(item?.num_of_sellers ?? 0);
                // NB de Clients(n-1)
                let period_num_of_sellers = indexInPeriod !== -1 ? parseInt(period[indexInPeriod]["num_of_sellers"] ?? 0) : 0;


                // PDM (N)
                let period_pdm = 0;
                const indexInGroupedPeriod = groupedPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                if (indexInGroupedPeriod !== -1) {
                    period_pdm = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? (period_va ?? 0 / groupedPeriodData[indexInGroupedPeriod][analyticsType]) : 0
                }
                let groupedPeriodData_tmp = groupedPeriodData && groupedPeriodData[indexInGroupedPeriod] && groupedPeriodData[indexInGroupedPeriod][analyticsType] ? groupedPeriodData[indexInGroupedPeriod][analyticsType] : 0;


                // PDM (N-1)
                let lastPeriod_pdm = 0;
                const indexInGroupedLastPeriod = groupedLastPeriodData?.findIndex((itemFind, index) => itemFind?.location_id === item?.location_id);
                // if (indexInGroupedLastPeriod !== -1) {
                //     lastPeriod_pdm = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? (lastPeriod_va ?? 0 / groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType]) : 0
                // }
                let groupedLastPeriodData_tmp = 0;

                if (indexInGroupedLastPeriod !== -1) {
                    groupedLastPeriodData_tmp = groupedLastPeriodData && groupedLastPeriodData[indexInGroupedLastPeriod] && groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] ? groupedLastPeriodData[indexInGroupedLastPeriod][analyticsType] : 0;
                    if (groupedLastPeriodData_tmp) {
                        lastPeriod_pdm = lastPeriod_va / groupedLastPeriodData_tmp
                    }
                }
                // const num_of_sellers_tmp = period_num_of_sellers + lastPeriod_num_of_sellers;
                const num_of_sellers_tmp = period_num_of_sellers;
                const seller_stats_tmp = this.getSellerStats(String(item?.location_id));

                dataAnalyticsResult?.push({
                    location_id: item?.location_id,
                    location: item?.location,
                    period: indexInPeriod !== -1 ? period[indexInPeriod] : null,
                    lastPeriod: item,
                    result: {
                        period_va,
                        lastPeriod_va,
                        period_evol: parseInt(period_va - lastPeriod_va),
                        period_evol_percent: lastPeriod_va !== 0 ? parseFloat(((period_va - lastPeriod_va) / lastPeriod_va) * 100).toFixed(isForMap ? 0 : 2) : 100,
                        num_of_sellers: num_of_sellers_tmp,
                        seller_stats_tmp,
                        num_of_sellers_percent: seller_stats_tmp && seller_stats_tmp !== 0 ? parseFloat((num_of_sellers_tmp / seller_stats_tmp) * 100).toFixed(2) : 0,

                        period_pdm: parseFloat(period_pdm * 100).toFixed(isForMap ? 0 : 2),
                        lastPeriod_pdm: parseFloat(lastPeriod_pdm * 100).toFixed(isForMap ? 0 : 2),
                        ie: lastPeriod_pdm !== 0 ? parseFloat((period_pdm / lastPeriod_pdm) * 100).toFixed(isForMap ? 0 : 2) : 0,
                        ip: !isForMap ? mapDataAnalytics?.total?.period_pdm !== 0 ? parseFloat(((period_pdm * 100) / mapDataAnalytics?.total?.period_pdm) * 100).toFixed(isForMap ? 0 : 2) : 0 : parseFloat(((period_pdm * 100) / pdm_national) * 100).toFixed(isForMap ? 0 : 2),

                        period_va_national: !isForMap ? mapDataAnalytics?.total?.period_va : 0,
                        groupedPeriodData_national: !isForMap ? mapDataAnalytics?.total?.groupedPeriodData : 0,
                        period_pdm_national: !isForMap ? mapDataAnalytics?.total?.period_pdm : 0,


                        groupedPeriodData: groupedPeriodData_tmp,
                        groupedLastPeriodData: groupedLastPeriodData_tmp,
                        grouped_period_evol_percent: groupedLastPeriodData_tmp !== 0 ? parseFloat(((groupedPeriodData_tmp - groupedLastPeriodData_tmp) / groupedLastPeriodData_tmp) * 100).toFixed(isForMap ? 0 : 2) : 100,

                    }
                })
            }
        })

        return dataAnalyticsResult;
    }


    convertArrayToObject = (array, isForMap) => (
        {
            location_id: array[0]?.location_id,
            location: {
                id: "0",
                name: "Autre",
                regionId: "0",
            },
            period: null,
            lastPeriod: null,
            result: {
                period_va: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.period_va), 0),
                lastPeriod_va: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.lastPeriod_va), 0),
                period_evol: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.period_evol), 0),
                period_evol_percent: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.period_evol_percent), 0)).toFixed(isForMap ? 0 : 2),
                num_of_sellers: array.reduce((accumulator, item) => accumulator + parseInt(item?.result?.num_of_sellers), 0),
                period_pdm: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.period_pdm), 0)).toFixed(isForMap ? 0 : 2),
                lastPeriod_pdm: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.lastPeriod_pdm), 0)).toFixed(isForMap ? 0 : 2),
                ie: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.ie), 0)).toFixed(isForMap ? 0 : 2),
                ip: parseFloat(array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.ip), 0)).toFixed(isForMap ? 0 : 2),

                period_va_national: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.period_va_national), 0),
                groupedPeriodData_national: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.groupedPeriodData_national), 0),
                period_pdm_national: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.period_pdm_national), 0),


                groupedPeriodData: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.groupedPeriodData), 0),
                groupedLastPeriodData: array.reduce((accumulator, item) => accumulator + parseFloat(item?.result?.groupedLastPeriodData), 0),
            }
        }
    )





    getPeriod = () => {
        const date = Store.getState()?.SEARCH_MARKET_VIEW?.searchMarketView?.date;
        if (date?.referenceDate?.length !== 0) {
            return ({ start: moment(date?.referenceDate[0]).format("YYYY-MM-DD"), end: moment(date?.referenceDate[1]).format("YYYY-MM-DD") })
        }
    }
    getLastPeriod = () => {
        const date = Store.getState()?.SEARCH_MARKET_VIEW?.searchMarketView?.date;
        if (date?.comparisonDate?.length !== 0) {
            return ({ start: moment(date?.comparisonDate[0]).format("YYYY-MM-DD"), end: moment(date?.comparisonDate[1]).format("YYYY-MM-DD") })
        }
    }
    // getLastPeriod = () => {
    //     const date = Store.getState().SEARCH_ITEMS.searchItems?.date
    //     if (date?.month) {
    //         let startDate = moment(date?.month?.value?.startDate);
    //         let endDate = moment(date?.month?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else if (date?.quarter) {
    //         let startDate = moment(date?.quarter?.value?.startDate);
    //         let endDate = moment(date?.quarter?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else if (date?.semester) {
    //         let startDate = moment(date?.semester?.value?.startDate);
    //         let endDate = moment(date?.semester?.value?.endDate);
    //         return { start: startDate.subtract(1, "year").format("YYYY-MM-DD"), end: endDate.subtract(1, "year").format("YYYY-MM-DD") };
    //     } else {
    //         let startDate = moment(`${date?.year?.value}-01-01`).subtract(1, "year").format("YYYY-MM-DD");
    //         let endDate = moment(`${date?.year?.value}-01-01`).subtract(1, "year").add(12, "month").subtract(1, "day").format("YYYY-MM-DD");
    //         return { start: startDate, end: endDate };
    //     }
    // }
    getLocationObject = (location_id, isForMap = false) => {
        const locationSearch = Store.getState().SEARCH_ITEMS.searchItems?.location
        const locationArrays = Store.getState().LOCATION;
        if (!isForMap) {
            if (locationSearch?.subRegion) {
                return locationArrays?.sectors?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else if (locationSearch?.region) {
                return locationArrays?.subRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            } else {
                return locationArrays?.regions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
            }
        } else {
            return locationArrays?.regions?.filter((item, index) => (parseInt(item?.id) === parseInt(location_id)))
        }

    }


    setSearchFiltersToParams = (history, path) => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        if (history && searchItems) {
            // const btoa = window.btoa(unescape(encodeURIComponent(JSON.stringify(searchItems))))
            const string = unescape(encodeURIComponent(JSON.stringify(searchItems)));

            // history.push(`${path}?q=${btoa}`)
            history.push(`${path}?q=${string}`)

        }
    }
    setSearchFiltersFromParamsToRedux = (callBack = () => { }) => {
        const url = window.location.href;
        const params = new URL(url).searchParams;
        const searchFilters = params?.get("q");
        if (searchFilters && searchFilters?.length !== 0) {
            // const atob = decodeURIComponent(escape(window.atob(searchFilters)));
            const string = decodeURIComponent(escape(searchFilters));
            Store.dispatch({ type: "SET_ALL_SEARCH_ITEMS", value: { searchItems: JSON.parse(string) } });
            callBack();
        }
    }
    normalSearch = () => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        if (searchItems?.items && searchItems?.items?.length !== 0 && searchItems?.groupeType && searchItems?.groupeType?.value && searchItems?.groupeType?.value?.length !== 0) {
            this.getDataAnalytic(myRecize, () => {
            })
            this.getGroupeItems();
        }
    }
    comparisonSearch = () => {
        const searchItems = Store.getState().SEARCH_ITEMS.searchItems;
        const { comparisonItems } = searchItems;
        console.log("mimoun test", searchItems)

        if (comparisonItems?.length !== 0 && comparisonItems[0]?.items?.length !== 0 && searchItems?.groupeType && searchItems?.groupeType?.value && searchItems?.groupeType?.value?.length !== 0) {
            console.log("omparisonDataAnalyticsService.getDataAnalytic")
            comparisonDataAnalyticsService.getDataAnalytic(() => { }, () => {
            })
            comparisonDataAnalyticsService.getGroupeItems();
        }
    }
}
// location: {
//     region: searchItems?.location?.region ?? null,
//     subregion: searchItems?.location?.subRegion ?? null,
//     sector: searchItems?.location?.sector ?? null,
// },
export default new marketViewService();