import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import moment from 'moment';
import { connect } from 'react-redux';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
}

class YearSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.getAll();
    }
    render() {
        const { data } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Année</Form.Label>
                <SearchSelect
                    name="YearSearchSelect"
                    options={data}
                    defaultValue={searchItems?.date?.year}
                    value={searchItems?.date?.year}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        const years = []
        const dateStart = moment()
        const dateEnd = moment().subtract(41, 'y');
        while (dateEnd.diff(dateStart, 'years') <= 0) {
            years.push(dateStart.format('YYYY'))
            dateStart.subtract(1, 'year')
        }
        this.setState({ data: years.map((item, index) => ({ value: item, label: item })) })
    }
}

const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(YearSearchSelect);

