
export default {
    Users: {
        add: "Le nouvel utilisateur a été ajouté avec succès",
        update: "L'utilisateur a été modifié avec succès",
        disable: "voulez-vous vraiment désactivé ce utilisateur",
        enable: "voulez-vous vraiment activé ce utilisateur",
    },
    Home: {
        add: "Le produit a été ajouté au panier avec succès",
        delete: "Le produit a été supprimé avec succès",
        update: "La quantité du produit a été modifiée avec succès",
        agreeGeneraleCondition: "Merci d'accepter les conditions générales de vente",
        notAvailableInStock: "Ce produit n'est pas disponible dans le stock",
        searchRequiredItem: "Ce champ est requis"
    },
    Login: {
        authenticatedFailure: "Veuillez vous identifier pour pouvoir finaliser votre commande",
        validationMessages: {
            requiredEmail: "E-mail est requis",
            requiredPassword: "Mot de passe est requis",
            emailValid: "E-mail non valide"
        }
    },
    Common: {
        addSavedSearch: "La recherche a été ajoutée avec succès",
        deleteSavedSearch: "La recherche a été supprimée avec succès",
        affectedSavedSearch: "La recherche a été affectée avec succès",

    }
}