
import moment from "moment";


const initialState = {
    "report": "main",
    "label": "",
};

function savedSearchReducer(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_SAVED_SEARCH_LABEL":
            nextState = {
                ...state,
                label: action?.value?.label
            };
            return nextState || state;
        case "SET_SAVED_SEARCH_REPORT":
            nextState = {
                ...state,
                report: action?.value?.report
            };
            return nextState || state;
        case "SET_SAVED_SEARCH":
            nextState = {
                ...action?.value
            };
            return nextState || state;
        case "RESET_SAVED_SEARCH":
            nextState = {
                ...initialState,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default savedSearchReducer;
