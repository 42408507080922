import React, { Component } from "react";
import DefaultDatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
// import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";
import { HiXCircle } from "react-icons/hi";
import { Form, Button, Row, Col, Table } from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

registerLocale("fr", fr);

class CustomDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { disabled, value, onChange, onClick, id, placeholder, type, label, className, name } = this.props;
        return (
            <Form.Group controlId="formBasicNom">
                <Form.Label>{label}</Form.Label>
                <Field
                    disabled={disabled ?? false}
                    onClick={onClick}
                    className={`input NUinput ${className}`}
                    type={type}
                    name={name}
                    autoFocus={this.props.autoFocus}
                    onChange={onChange}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    autoComplete="off"
                />
                <ErrorMessage name={name ?? "input"}>{(errorMsg) => <Error message={errorMsg}></Error>}</ErrorMessage>
                {/* <Form.Control className="NUinput" placeholder="Nom  ici .." /> */}
            </Form.Group>
        );
    }
}
const Error = ({ message }) => (
    <div className="error-area">
        <HiXCircle style={{ marginRight: 2 }} color="#da3025" size={17} />
        <span className="error-message">{message}</span>
    </div>
);

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { disabled, error, id, label, selected, onChange, showTimeSelect, timeFormat, timeIntervals, timeCaption, dateFormat, todayButton, popperPlacement, locale, useWeekdaysShort, style, showYearDropdown, filterDate, minDate, maxDate, name } = this.props;
        const DateComponent = ({ value, onClick }) => <CustomDatePicker disabled={disabled ?? false} name={name} disabled={disabled ? disabled : false} error={error ? error : false} onClick={onClick} value={value} id={id ? id : "date_id"} label={label ? label : "Date"}></CustomDatePicker>;
        return (
            <DefaultDatePicker
                disabled={disabled ? disabled : false}
                filterDate={filterDate && filterDate}
                selected={selected}
                onChange={onChange}
                style={style ? style : { width: "100%" }}
                showTimeSelect={showTimeSelect === false ? showTimeSelect : true}
                timeFormat={timeFormat ? timeFormat : "HH:mm"}
                timeIntervals={timeIntervals ? timeIntervals : 15}
                timeCaption={timeCaption ? timeCaption : "Heure"}
                dateFormat={dateFormat ? dateFormat : "yyyy-MM-dd HH:mm"}
                todayButton={todayButton ? todayButton : "Aujourd'hui"}
                popperPlacement={"auto"}
                // popperModifiers={{
                //     offset: {
                //         enabled: true,
                //         offset: "5px, 10px",
                //     },
                //     preventOverflow: {
                //         enabled: true,
                //         escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                //         boundariesElement: "viewport",
                //     },
                // }}
                customInput={<DateComponent />}
                // customInput={<div>test</div>}
                // renderDayContents={renderDayContents}
                locale={locale ? locale : "fr"}
                useWeekdaysShort={useWeekdaysShort ? useWeekdaysShort : true}
                // isClearable
                showYearDropdown={showYearDropdown === false ? showYearDropdown : true}
                todayButton="Aujourd'hui"
                minDate={minDate && minDate}
                maxDate={new Date()}
            />
        );
    }
}
export default DatePicker;
