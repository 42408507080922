import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import "./GroupeItems.css";
import { GrClose } from "react-icons/gr";
import { IconContext } from "react-icons";
import { Table } from 'react-bootstrap'
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import homeDAO from '../../dao/homeDAO';
import { StartSortGlobalStrings } from '../../helpers/sort';


class GroupeItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setSearchModalIsOpen: false,
            data: [],
            loading: false,
            orderBy: 0,
            key: "",
        };
    }

    // ===================state setters ============
    setData = (value, callback) => this.setState({ data: value }, callback);
    setLoading = (value, callback) => this.setState({ loading: value }, callback);
    // =======================end====================== 
    componentDidMount() {

    }


    getAllData = async () => {
        try {
            this.setLoading(true);
            const { groupeItems, searchItems } = this.props;
            const response = await homeDAO.getItemsByGroup(searchItems?.groupeType?.value, groupeItems?.id, {});
            this.setData(StartSortGlobalStrings(response?.data?.data ?? [], "name", "ASC"))
            this.setLoading(false);
        } catch (error) {
            this.setLoading(false);
        }
    }

    openSearchModeal = () => {
        document.querySelector("body").style.overflowY = "scroll"
        this.setState({
            setSearchModalIsOpen: true,
        }, this.getAllData)
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        })
    }

    getOrderBy() {
        const { orderBy } = this.state;
        switch (orderBy) {
            case 0:
                return 1;
                break;
            case 1:
                return -1;
                break;
            case -1:
                return 1;
                break;
            default:
                return 0;
                break;
        }
    }
    getThOrder = (paramKey) => {
        const { key, orderBy } = this.state;
        if (key.toLocaleLowerCase() === paramKey.toLocaleLowerCase()) {
            if (orderBy === -1) {
                return "up"
            } else if (orderBy === 1) {
                return "down"
            } else {
                return "none"
            }
        } else {
            return "none"
        }
    }
    getThActive = (paramKey) => {
        const { key, orderBy } = this.state;
        return key.toLocaleLowerCase() === paramKey.toLocaleLowerCase() && (orderBy === 1 || orderBy === -1)
    }

    sortStrings() {
        const { data } = this.state;
        const { key, orderBy } = this.state;
        this.setData(StartSortGlobalStrings(data, key, orderBy === 1 ? "ASC" : "DESC"))
    }
    render() {
        const { groupeItems, searchItems } = this.props;
        const { data } = this.state;

        return (
            <div className="GroupeItems__container">
                {groupeItems?.name && <><span style={{ display: "inline-block", marginLeft: 4 }}>{" (Groupe:"}</span><button type="button" onClick={this.openSearchModeal} className="btn btn-link GroupeItems__btn">{groupeItems?.name ? groupeItems?.name : ""}</button><span>)</span></>}
                <Modal
                    isOpen={this.state.setSearchModalIsOpen}
                    onAfterOpen={() => { }}
                    onRequestClose={this.closeSearchModal}
                    ariaHideApp={false}
                    style={{}}
                    className="SearchModal"
                    contentLabel="Example Modal">
                    <div className="SearchForm__container GroupeItems__modal">
                        <div className="headerSearchModal">
                            <h2 >{`Item(s) du Groupe : ${groupeItems?.name ? groupeItems?.name : ""}`}</h2>
                            <IconContext.Provider value={{ color: "#FF5050", className: "closeBtnSvg" }}>
                                <button onClick={this.closeSearchModal}>
                                    <GrClose />
                                </button>
                            </IconContext.Provider>
                        </div>
                        <hr />
                        <div className="tableFixHead">
                            <Table striped bordered   >
                                <thead>
                                    <tr>
                                        <Th order={this.getThOrder("name")} isActive={this.getThActive("name")} onClick={() => this.setState({ key: "name", orderBy: this.getOrderBy() }, this.sortStrings)}>Nom</Th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((item, index) => {
                                            return (
                                                <tr key={item?.location_id} style={{ textAlign: 'right' }}>
                                                    <td style={{ textAlign: "left" }}>{item?.name ?? ""}</td>
                                                </tr>
                                            );

                                        })
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const Th = ({ order, isActive, children, ...attributes }) => {
    return (
        <th className={`Th text-left${isActive ? " table-th-is-active" : ""}`} {...attributes}>
            {children}
            <span className={`table-order-container${isActive ? " table-order-is-active" : ""}`}>
                {order !== "down" ? <a className="sprite-table-up-order"><FiChevronUp size={12} /></a> : <a></a>}
                {order !== "up" ? <a className="sprite-table-down-order"><FiChevronDown size={12} /></a> : <a></a>}
            </span>
        </th>

    )
}

const mapStateToProps = (state) => {
    return ({
        mapDataAnalytics: state.DATA_ANALYTICS.mapDataAnalytics,
        loadingMapDataAnalytics: state.DATA_ANALYTICS.loadingMapDataAnalytics,
        tableDataAnalytics: state.DATA_ANALYTICS.tableDataAnalytics,
        loadingTableDataAnalytics: state.DATA_ANALYTICS.loadingTableDataAnalytics,
        loadingDataAnalytics: state.DATA_ANALYTICS.loadingDataAnalytics,
        searchItems: state.SEARCH_ITEMS.searchItems,
        groupeItems: state.DATA_ANALYTICS.groupeItems,

    })
}
export default connect(mapStateToProps)(GroupeItems);

