import React, { Component } from 'react'
import './searchForm.css'
import { Form, Col } from 'react-bootstrap'
import { GrClose } from "react-icons/gr";
import { IconContext } from "react-icons";
import SelectItemsInput from '../../selectItemsInput/SelectItemsInput';
import RegionsSearchSelect from '../../regionsSearchSelect/RegionsSearchSelect';
import SubRegionsSearchSelect from '../../subRegionsSearchSelect';
import SectorsSearchSelect from '../../sectorsSearchSelect';
import CountrySearchSelect from '../../countrySearchSelect';
import YearSearchSelect from '../../yearSearchSelect';
import QuarterSearchSelect from '../../quarterSearchSelect';
import SemesterSearchSelect from '../../semesterSearchSelect';
import MonthSearchSelect from '../../monthSearchSelect';
import { connect } from 'react-redux';
import dataAnalyticsService from '../../../services/dataAnalyticsService';
import { myRecize } from '../../../services/helpersServices';
import messageText from '../../../values/messageText';
import DateRangePicker from '../../dateRangePicker';
import HashLoader from "react-spinners/HashLoader";
import moment from "moment";
import GroupeType from '../../groupeType';
import { getRandomInt } from '../../../helpers/common';
import comparisonDataAnalyticsService from '../../../services/comparisonDataAnalyticsService';
import SellerType from '../../sellerType/SellerType';
import SelectItemsInputComparison from '../../selectItemsInputComparison/SelectItemsInputComparison';
import routesPath from '../../../values/routesPath';
import { withRouter } from 'react-router-dom';
import savedSearchService from '../../../services/savedSearchService';
import SavedSearchForm from '../../savedSearchForm/SavedSearchForm';

const FIELDS = {
    selectItemError: null,
    selectGroupeTypeError: null,
}
class SearchForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            CatSelected: [
                'Cat1', 'Cat2', 'Cat3'
            ],
            ifVolume: true,
            ...FIELDS,
            loading: false,
            comparisonSelectItemsInput: [],
        }
    }

    checkAddComparisonSelectItemsInput = () => {
        const { comparisonSelectItemsInput } = this.state;
        return comparisonSelectItemsInput.length < 3;
    }
    addComparisonSelectItemsInput = () => {
        const { comparisonSelectItemsInput } = this.state;
        if (comparisonSelectItemsInput.length < 3) {
            let i = comparisonSelectItemsInput.length;
            this.setState({ comparisonSelectItemsInput: [...comparisonSelectItemsInput, { key: getRandomInt(10000) }] })
        }
    }
    removeComparisonSelectItemsInput = (key) => {
        const { comparisonSelectItemsInput } = this.state;
        this.setState({ comparisonSelectItemsInput: comparisonSelectItemsInput.filter((item, index) => item.key !== key) });
        setTimeout(() => this.props.dispatch({ type: "REMOVE_ITEM_IN_COMPARISON_ITEMS", value: { key: key } }), 0)

    }


    componentDidMount() {
        var inputNewCat = document.getElementById("inputNewCat");
        // inputNewCat.addEventListener("keyup", (event) => {
        //     if (event.which == 13) {
        //         this.setState({
        //             CatSelected: [...this.state.CatSelected, inputNewCat.value]
        //         })
        //         inputNewCat.value = ""
        //     }
        // });

        this.boolVolum();
        const { searchItems } = this.props;
        if (searchItems?.analyticsType?.toLocaleLowerCase() === "valeur") {
            this.ValeurClick();
        } else {
            this.volumClick();
        }
        // if the type is comparison full the comparisonSelectItemsInput inputs with items
        const { type } = this.props;
        if (type === "comparison") {
            const { comparisonItems } = this.props.searchItems;
            if (comparisonItems.length !== 0) {
                this.setState({ comparisonSelectItemsInput: comparisonItems.map((item, index) => ({ key: item?.key ?? "" })) });
            } else {
                this.setState({ comparisonSelectItemsInput: [{ key: getRandomInt(10000) }] })
            }

        }
    }
    boolVolum = () => {

        const a = document.querySelector(".backActiv")
        //a.style.transform = "translate(50px)"
        if (this.state.ifVolume) {
            a.style.left = "0"
            document.querySelector("#volume").style.color = "#fff"
            document.querySelector("#valeur").style.color = "#000"
        } else {
            a.style.right = "0"
        }
    }
    onKeyPress = (evt) => {
        evt.preventDefault();
    }



    removeCat = (element) => {
        let x = this.state.CatSelected
        let y = x.filter(item => item != element)
        this.setState({
            CatSelected: x.filter(item => item != element)
        })
        //console.log(this.state.CatSelected);

    }

    volumClick = () => {
        this.setState({ ifVolume: true })
        document.querySelector(".backActiv").style.right = ""
        document.querySelector(".backActiv").style.left = "0"
        document.querySelector("#volume").style.color = "#fff"
        document.querySelector("#valeur").style.color = "#000";
        this.props.dispatch({ type: "SET_ANALYTICS_TYPE", value: { analyticsType: "volume" } })
    }
    ValeurClick = () => {
        this.setState({ ifVolume: false })
        document.querySelector(".backActiv").style.left = ""
        document.querySelector(".backActiv").style.right = "0"
        document.querySelector("#volume").style.color = "#000"
        document.querySelector("#valeur").style.color = "#fff"
        this.props.dispatch({ type: "SET_ANALYTICS_TYPE", value: { analyticsType: "valeur" } })
    }
    componentDidUpdate(prevProps) {
        if (prevProps.searchItems.comparisonItems !== this.props.searchItems.comparisonItems) {
            let aleatoryItem = null;
            this.props.searchItems.comparisonItems.forEach((item) => {
                item?.items?.forEach(item => {
                    if (item?.groups) {
                        aleatoryItem = item;
                        return;
                    }
                })
            })
            if (!aleatoryItem) {
                this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: [] } });
            }
        }

    }
    render() {
        const { searchItems } = this.props;
        const { date } = this.props.searchItems;
        const { selectItemError, selectGroupeTypeError } = this.state;
        const { loadingDataAnalytics } = this.props;
        const { loading } = this.state;
        const { type } = this.props;
        const { comparisonSelectItemsInput } = this.state;
        console.log("searchItems", searchItems)
        return (
            <div className="SearchForm__container">
                <div className="headerSearchModal">
                    <h2 >Nouvelle recherche</h2>
                    <IconContext.Provider value={{ color: "#FF5050", className: "closeBtnSvg" }}>
                        <button onClick={this.props.close}>
                            <GrClose />
                        </button>
                    </IconContext.Provider>
                </div>
                <hr />
                <div style={{ maxHeight: 491, overflow: "scroll", }}>
                    <Form className="searchForm" onSubmit={this.onKeyPress}>
                        <Form.Row style={{ marginBottom: 15 }}>
                            {type === "normal" && <Col>
                                <GroupeType
                                    onChange={this.selectGroupeType}
                                    error={selectGroupeTypeError}
                                />
                                {/* send groupeType in item */}
                                {searchItems?.groupeType?.value && <SelectItemsInput
                                    type={type}
                                    // onChange={this.selectItems}
                                    onChange={(selectedOption, callback) => this.selectItems(selectedOption, null, callback)}
                                    error={selectItemError}
                                />}
                                {/* end send groupeType in item */}
                                <SellerType
                                    onChange={this.selectSellerType}
                                    error={null}
                                />

                            </Col>}
                            {type === "comparison" && <Col>
                                <GroupeType
                                    onChange={this.selectGroupeType}
                                    error={selectGroupeTypeError}
                                />
                                <SellerType
                                    onChange={this.selectSellerType}
                                    error={null}
                                />
                                {searchItems?.groupeType?.value && comparisonSelectItemsInput.map((item, index) => {
                                    return (
                                        <SelectItemsInputComparison
                                            label={"Item(s) - " + (index + 1)}
                                            type={type}
                                            // the first select box we can't delete it
                                            isCreatable={index === 0 ? false : true}
                                            onClear={() => this.removeComparisonSelectItemsInput(item.key)}
                                            key={String(item.key)}
                                            valueKey={String(item.key)}
                                            onChange={(selectedOption, callback) => this.selectItems(selectedOption, item.key, callback)}
                                            // the first select box is required
                                            error={index === 0 ? selectItemError : null}
                                        />
                                    )
                                })}
                                {this.checkAddComparisonSelectItemsInput() && <div className="SearchForm__add-comparison-btn-container">
                                    <button onClick={this.addComparisonSelectItemsInput} className="SearchForm__add-comparison-btn">Ajouter une comparaison</button>
                                </div>}
                            </Col>}
                            <Col>
                                <CountrySearchSelect
                                    onChange={this.selectCountry}
                                />

                                <RegionsSearchSelect
                                    onChange={this.selectRegion}
                                />

                                <SubRegionsSearchSelect
                                    onChange={this.selectSubRegion}
                                />

                                <SectorsSearchSelect
                                    onChange={this.selectSector}
                                />
                            </Col>
                            <Col>
                                <DateRangePicker
                                    label="Période de référence"
                                    onChange={this.setReferenceDate}
                                    value={date?.referenceDate}
                                />
                                <DateRangePicker
                                    label="Période de comparaison"
                                    onChange={this.setComparisonDate}
                                    value={date?.comparisonDate}
                                />


                                {/* <YearSearchSelect
                                onChange={this.selectYear}
                            />
                            <SemesterSearchSelect
                                targetedYear={searchItems?.date?.year?.value}
                                onChange={this.selectSemester}
                            />

                            <QuarterSearchSelect
                                targetedYear={searchItems?.date?.year?.value}
                                onChange={this.selectQuarter}
                            />
                            <MonthSearchSelect
                                targetedDate={searchItems?.date?.quarter?.value}
                                onChange={this.selectMonth}
                            /> */}

                            </Col>

                        </Form.Row>
                        <Form.Row className="SearchForm__btns-container">
                            <Col md={4}>
                                <div className="boolCountaner">
                                    <div className="backActiv"></div>
                                    <button id="volume" onClick={this.volumClick} style={{ left: '0' }}>Volume</button>
                                    <button id="valeur" onClick={this.ValeurClick} style={{ right: '0' }}>Valeur</button>
                                </div>
                            </Col>
                            <Col md={8}>
                                <button disabled={loading} onClick={this.onSubmit} id="submit">{loading ? <HashLoader color={"#fff"} loading={true} size={20} /> : "Appliquer"}</button>
                                <SavedSearchForm type={type} />
                                {/* <button type="button" disabled={loading} onClick={() => savedSearchService.saveSearch()} id="saveSearch">{loading ? <HashLoader color={"#fff"} loading={true} size={20} /> : "Enregister cette recherche"}</button> */}

                            </Col>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        )
    }
    selectItems = (selectedOption, key, callBack) => {
        const { type, searchItems } = this.props;
        const { selectItemError } = this.state;
        if (selectItemError) this.setState({ selectItemError: null })
        if (type === "normal") {
            this.props.dispatch({ type: "SET_ITEMS", value: { items: selectedOption } });
            // for adding groups
            if (selectedOption && selectedOption?.length !== 0) {
                this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: selectedOption[0]?.groups ?? [] } });
            } else {
                if (selectedOption?.length === 0) {
                    this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: [] } });
                }
            }
        } else if (type === "comparison" && key) {
            const findIndex = searchItems.comparisonItems.findIndex((item) => item.key === key);
            if (findIndex !== -1) {
                setTimeout(() => this.props.dispatch({ type: "UPDATE_ITEM_IN_COMPARISON_ITEMS", value: { key: key, newItems: selectedOption } }), 0)
            } else {
                setTimeout(() => this.props.dispatch({ type: "ADD_ITEM_IN_COMPARISON_ITEMS", value: { key: key, items: selectedOption } }), 0)
            }
            // for adding groups
            if (selectedOption && selectedOption?.length !== 0) {
                this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: selectedOption[0]?.groups ?? [] } });
            } else {
                if (selectedOption?.length === 0) {
                    let aleatoryItem = null;
                    searchItems.comparisonItems.forEach((item) => {
                        item?.items?.forEach(item => {
                            if (item?.groups) {
                                aleatoryItem = item;
                                return;
                            }
                        })
                    })
                    if (aleatoryItem) {
                        this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: aleatoryItem?.groups } });
                    } else {
                        this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: [] } });
                    }

                }
            }
        } else {

        }
        setTimeout(callBack, 0)
    }
    selectGroupeType = (selectedOption, ) => {
        const { searchItems, type } = this.props;

        const { selectGroupeTypeError } = this.state;
        if (selectGroupeTypeError) this.setState({ selectGroupeTypeError: null })
        this.props.dispatch({ type: "SET_GROUPE_TYPES", value: { groupeType: selectedOption } })

        if (type === "normal") {
            // reset the items values and groups because are based on groupe type 
            if (selectedOption?.value !== searchItems?.groupeType?.value) {
                this.props.dispatch({ type: "SET_ITEMS", value: { items: [] } });
                this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: [] } });
            }
            // end reset the items values and groups because are based on groupe type 
        } else if (type === "comparison") {
            // reset the items values and groups because are based on groupe type 
            if (selectedOption?.value !== searchItems?.groupeType?.value) {
                this.props.dispatch({ type: "RESET_ALL_COMPARISON_ITEMS", value: {} });
                this.props.dispatch({ type: "SET_GROUPS_ITEM", value: { groups: [] } });
                this.setState({ comparisonSelectItemsInput: [{ key: getRandomInt(10000) }] })
            }
            // end reset the items values and groups because are based on groupe type 
        } else {

        }

    }
    selectSellerType = (selectedOption) => {
        this.props.dispatch({ type: "SET_SELLER_TYPES", value: { sellerType: selectedOption } })
    }
    selectCountry = (selectedOption) => {
        const { searchItems } = this.props;
        this.props.dispatch({ type: "SET_LOCATION_COUNTRY", value: { country: selectedOption } })
        // reset the fields
        if (selectedOption?.value !== searchItems?.location?.country?.value) {
            this.props.dispatch({ type: "SET_LOCATION_REGION", value: { region: null } })
            this.props.dispatch({ type: "SET_LOCATION_SUB_REGION", value: { subRegion: null } })
            this.props.dispatch({ type: "SET_LOCATION_SECTOR", value: { sector: null } })
        }
    }
    selectRegion = (selectedOption) => {
        const { searchItems } = this.props;
        this.props.dispatch({ type: "SET_LOCATION_REGION", value: { region: selectedOption } })
        // reset the fields
        if (selectedOption?.value !== searchItems?.location?.region?.value) {
            this.props.dispatch({ type: "SET_LOCATION_SUB_REGION", value: { subRegion: null } })
            this.props.dispatch({ type: "SET_LOCATION_SECTOR", value: { sector: null } })
        }
    }
    selectSubRegion = (selectedOption) => {
        const { searchItems } = this.props;

        this.props.dispatch({ type: "SET_LOCATION_SUB_REGION", value: { subRegion: selectedOption } })
        // reset the fields
        if (selectedOption?.value !== searchItems?.location?.subRegion?.value) {
            this.props.dispatch({ type: "SET_LOCATION_SECTOR", value: { sector: null } })
        }
    }
    selectSector = (selectedOption) => {
        const { searchItems } = this.props;
        this.props.dispatch({ type: "SET_LOCATION_SECTOR", value: { sector: selectedOption } })
    }


    selectQuarter = (selectedOption) => {
        const { searchItems } = this.props;

        this.props.dispatch({ type: "SET_DATE_QUARTER", value: { quarter: selectedOption } })

        // reset the fields
        if (selectedOption?.value !== searchItems?.date?.quarter?.value) {
            this.props.dispatch({ type: "SET_DATE_MONTH", value: { month: null } })
        }
    }

    setComparisonDate = (value) => {
        const { searchItems } = this.props;
        this.props.dispatch({ type: "SET_COMPARISON_DATE", value: { comparisonDate: value } })
    }
    setReferenceDate = (value) => {
        this.props.dispatch({ type: "SET_REFERENCE_DATE", value: { referenceDate: value } });
        this.props.dispatch({ type: "SET_COMPARISON_DATE", value: { comparisonDate: [moment(value[0]).subtract(1, "year"), moment(value[1]).subtract(1, 'year')], } });


    }
    onSubmit = () => {
        const { type } = this.props;
        if (type === "normal") {
            this.normalSearch();
        } else if (type === "comparison") {
            this.comparisonSearch();
        } else {

        }
    }
    normalSearch = async () => {
        const { searchItems } = this.props;
        if (searchItems?.items && searchItems?.items?.length !== 0 && searchItems?.groupeType && searchItems?.groupeType?.value && searchItems?.groupeType?.value?.length !== 0) {
            this.setState({ loading: true });
            dataAnalyticsService.getDataAnalytic(myRecize, () => {
                this.setState({ loading: false });
                this.props.closeFromApply();
            })
            dataAnalyticsService.setSearchFiltersToParams(this.props?.history, routesPath?.Home?.list)
            dataAnalyticsService.getGroupeItems();
        } else {
            if (!searchItems?.items || searchItems?.items?.length === 0) {
                this.setState({ selectItemError: { message: messageText?.Home?.searchRequiredItem } })
            }
            if (!searchItems?.groupeType || !searchItems?.groupeType?.value || searchItems?.groupeType?.value?.length === 0) {
                this.setState({ selectGroupeTypeError: { message: messageText?.Home?.searchRequiredItem } })
            }
        }
    }
    comparisonSearch = async () => {
        const { comparisonItems } = this.props.searchItems;
        const { searchItems } = this.props;
        if (comparisonItems?.length !== 0 && comparisonItems[0]?.items?.length !== 0 && searchItems?.groupeType && searchItems?.groupeType?.value && searchItems?.groupeType?.value?.length !== 0) {
            this.setState({ loading: true });
            comparisonDataAnalyticsService.getDataAnalytic(() => { }, () => {
                this.setState({ loading: false })
                this.props.closeFromApply()
            })
            dataAnalyticsService.setSearchFiltersToParams(this.props?.history, routesPath?.ComparisonReport?.list)
            comparisonDataAnalyticsService.getGroupeItems();

        } else {
            if (comparisonItems?.length === 0 || comparisonItems[0]?.items?.length === 0) {
                this.setState({ selectItemError: { message: messageText?.Home?.searchRequiredItem } })
            }
            if (!searchItems?.groupeType || !searchItems?.groupeType?.value || searchItems?.groupeType?.value?.length === 0) {
                this.setState({ selectGroupeTypeError: { message: messageText?.Home?.searchRequiredItem } })
            }
        }
    }
}
const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems,
        loadingDataAnalytics: state.DATA_ANALYTICS.loadingDataAnalytics,
    })
}

export default connect(mapStateToProps)(withRouter(SearchForm));