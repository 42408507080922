import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import homeDAO from '../../dao/homeDAO';
import { Form } from 'react-bootstrap'
import "./style.css";
import moment from 'moment';
import { connect } from 'react-redux';


const INITIAL_FIELDS = {
    data: [],
    loading: false,
}

class QuarterSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
            targetedYear: undefined,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.setState({ targetedYear: this.props.targetedYear }, this.getAll)
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.targetedYear !== this.props?.targetedYear) {
            this.preload();
        }
    }
    render() {
        const { data } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Trimestre</Form.Label>
                <SearchSelect
                    name="YearSearchSelect"
                    options={data}
                    defaultValue={searchItems?.date?.quarter}
                    value={searchItems?.date?.quarter}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        const { targetedYear } = this.props;

        if (targetedYear) {
            let startDate = moment(`${targetedYear}-01-01`);
            let endDate = moment(`${targetedYear}-01-01`);
            endDate.add(12, "month").subtract(1, "day").format("YYYY-MM-DD");
            let quarter = [];
            while (endDate.diff(startDate, 'month') > 0) {
                quarter.push({ startDate: startDate.format("YYYY-MM-DD"), endDate: startDate.add(3, 'month').subtract(1, "day").format("YYYY-MM-DD") });
                startDate.add(1, 'day');
            }
            this.setState({ data: quarter.map((item, index) => ({ value: item, label: `${item?.startDate} / ${item?.endDate}` })) })
        } else {
            this.setState({ data: [] })
        }

    }
}

const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(QuarterSearchSelect);

