import React, { Component } from 'react';
import LoginComponent from '../../components/DAbody/Login/Login'
import "./style.css";
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <LoginComponent />
        );
    }
}

export default Login;