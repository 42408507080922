
const initialState = {
    sellersStats: [],
    locationId: 0,
};

function sellersStatsByLocation(state = initialState, action) {
    let nextState;
    switch (action.type) {
        case "SET_SELLERS_STATS":
            nextState = {
                ...state,
                sellersStats: action.value.sellersStats,
            };
            return nextState || state;
        case "SET_LOCATION_ID":
            nextState = {
                ...state,
                locationId: action.value.locationId,
            };
            return nextState || state;
        default:
            return state;
    }
}
export default sellersStatsByLocation;