import React, { Component } from 'react'
import { BsPlus } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import Modal from 'react-modal';
import './DAusers.css'
import { Button, Row, Col, Table } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import { BsFillEyeFill } from 'react-icons/bs'
import { FaRegTrashAlt } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from "react-icons/io"
import $ from "jquery";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { IconContext } from "react-icons";
import FlashMsg from './DAuserFlashMsg/FlashMsg'
import usersDAO from '../../../dao/usersDAO';
import searchFaild from "../../../img/searchFailed.png";
import ErrorImg from "../../../img/error.png";
import HashLoader from "react-spinners/HashLoader";
import messageText from '../../../values/messageText';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../input";
import SelectInput from "../../selectInput";
import Checkbox from "../../checkbox";
import DatePicker from "../../datePicker";
import SubRegionsSearchSelect from "../../subRegionsSearchSelect";
import moment from "moment";
import { notify } from '../../flashMessage/FlashMessage';
import Store from "../../../store/configureStore";

const STATUS = {
    "active": <p className="userActif">actif</p>,
    "inactive": <p className="userInactif">inactif</p>
}
const CHECK = {
    "1": <p className="userActif">Oui</p>,
    "0": <p className="userInactif">Non</p>
}
export default class DAusers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSearchModalOpen: false,
            setSearchModalIsOpen: false,
            type: 'password',
            firstRow: 0,
            pageTable: 1,
            showMessage: false,
            data: [],
            loading: false,
            error: "",
            disableLoading: false,
        }
    }


    componentDidMount() {
        $(document).on('click', '#tableBtnForward', (e) => {
            let x = this.state.firstRow + 5
            let y = this.state.pageTable + 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
        $(document).on('click', '#tableBtnBack', (e) => {
            let x = this.state.firstRow - 5
            let y = this.state.pageTable - 1
            this.setState({
                firstRow: x,
                pageTable: y
            })
        })
        this.preload();
    }

    openSearchModeal = () => {
        this.setState({
            setSearchModalIsOpen: true
        })
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        })
    }


    handleClick = () => this.setState(({ type }) => ({
        type: type === 'text' ? 'password' : 'text'
    }))


    handleFlashMsg = (handlerVar) => {

    }
    preload = () => {
        this.getAllUsers(true);
    }
    getAllUsers = (loading) => this.setState({ loading: loading, error: "" }, async () => {
        try {
            const params = {};
            const response = await usersDAO.getAll(params);
            this.setState({ loading: false, data: Object.values(response?.data?.data?.reverse()) })
        } catch (error) {
            this.setState({ loading: false, error: error.message })
        }
    })
    toggleDisable = (user) => this.setState({ disableLoading: true }, async () => {
        var r = window.confirm(user?.status === "active" ? messageText?.Users?.disable : messageText?.Users?.enable);
        if (r) {
            try {
                if (user?.status === "active") {
                    await usersDAO.disable(user?.id)
                } else {
                    await usersDAO.enable(user?.id)
                }
                this.setState({ disableLoading: false })
                // for refresh
                this.getAllUsers(false)
            } catch (error) {
                this.setState({ disableLoading: false })
            }
        }
    })
    render() {
        const { data } = this.state;
        const { loading, error } = this.state;
        return (
            <div className='usersCard'>

                <div className="usersTitleCountainer">
                    <h2 className="DAusersCardTitle">Liste des utilisateurs</h2>
                </div>
                <AddUserModal showMessage={this.handleFlashMsg} refresh={() => this.getAllUsers(false)} />
                <hr />


                <div style={{ padding: '0% 2%', }}>

                    <Table striped className="usersTable"   >
                        <thead>
                            <tr>
                                <th className="text-left">Nom</th>
                                <th className="text-left">Prénom</th>
                                <th className="text-left">Email</th>
                                <th className="text-left">Est admin</th>
                                <th className="text-left">Est propriétaire</th>
                                <th className="text-left">Statut</th>
                                <th className="text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !loading && error?.length === 0 && data?.length !== 0 && data?.map((DAuser) => {
                                    return (
                                        <tr key={DAuser?.id ?? ""} style={{ textAlign: 'left' }}>
                                            <td>{DAuser?.lastName ?? ""}</td>
                                            <td>{DAuser?.firstName ?? ""}</td>
                                            <td>{DAuser?.email ?? ""}</td>
                                            <td>{DAuser?.isAdmin ? CHECK[DAuser?.isAdmin] : ""}</td>
                                            <td>{DAuser?.isOwner ? CHECK[DAuser?.isOwner] : ""}</td>
                                            <td>{DAuser.status ? STATUS[DAuser?.status] : ""}</td>
                                            <td>
                                                {DAuser.status === "active" ? <UpdateUserModal refresh={() => this.getAllUsers(false)} id={DAuser?.id ?? null} /> : <IconContext.Provider value={{ color: "transparent", size: '15px' }}>
                                                    <button disabled={true} style={{ backgroundColor: "transparent", cursor: "auto" }} onClick={this.openSearchModeal} className="editUserTable clickable"><FiEdit /></button>
                                                </IconContext.Provider>}
                                                {DAuser.status === "active" ? <IconContext.Provider value={{ color: "#FF5050", size: '15px' }}>
                                                    <button onClick={() => this.toggleDisable(DAuser)} className="deleteUserTable clickable"><FaRegTrashAlt /></button>
                                                </IconContext.Provider> :
                                                    <IconContext.Provider value={{ color: "#4AD295", size: '15px' }}>
                                                        <button onClick={() => this.toggleDisable(DAuser)
                                                        } className="enableUserTable clickable"><IoIosCheckmarkCircle size={20} /></button>
                                                    </IconContext.Provider>}
                                            </td>
                                        </tr>
                                    );

                                })
                            }
                            {!loading && error?.length === 0 && data?.length !== 0 && <tr className="paginationRow" style={{ textAlign: 'right' }}>
                                <td className="paginationCol" colSpan="10" style={{ border: '0px' }}>
                                    {/* <div className="paginationCountainer">
                                        {this.state.firstRow == 0
                                            ? <button disabled className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                            : <button className="tableBtnBack" id="tableBtnBack"><IoIosArrowBack values={{ color: 'red', size: '50px' }} /></button>
                                        }

                                        <div className="tableNbr">{this.state.pageTable}</div>

                                        {this.usersData.slice(this.state.firstRow, this.state.firstRow + 5).length < 5
                                            ? <button disabled className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                            : <button className="tableBtnForward" id="tableBtnForward" ><IoIosArrowForward /></button>
                                        }
                                    </div> */}
                                </td>
                            </tr>}
                        </tbody>
                    </Table>
                    {loading && <Loading />}
                    {!loading && error?.length === 0 && data?.length === 0 && <NoResultCas />}

                    {!loading && error?.length !== 0 && <Error message={error} />}

                </div>

                {this.state.showMessage && <FlashMsg />}

            </div >
        )
    }
}



const NoResultCas = () => (
    <div className="NoResultCas__container">
        <img
            src={searchFaild}
            className="NoResultCas__img"
        />
        <h1 style={{ color: "#1b1a1a" }} className="NoResultCas__title">Ooops !!</h1>
        <p style={{ color: "#1b1a1a" }} className="NoResultCas__desc">Aucun utilisateur correspondant à votre recherche n’a été trouvé.</p>
    </div>
)

const Loading = () => (<div className="Loading__container">
    <HashLoader color={"#337ab7"} loading={true} size={60} />
</div>)

const Error = ({ message, ...props }) => (
    <div className="NoResultCas__container">
        <img
            src={ErrorImg}
            className="NoResultCas__img"
        />
        <h1 style={{ color: "#1b1a1a" }} className="NoResultCas__title">Ooops !!</h1>
        <p style={{ color: "#1b1a1a" }} className="NoResultCas__desc">{message}</p>
    </div>
)

const FIELDS = {
    "isAdmin": false,
    "email": "",
    "password": "",
    "firstName": "",
    "lastName": "",
    "title": "",
    "phone": "",
    "mobile": "",
    "fax": "",
    "website": "",
    "birthdate": "",
    "address": "",
    "city": "",
    "state": "",
    "postalCode": "",
    "country": "MA",
    "status": { label: "Active", value: "active" }
}
const phoneRegExp = /(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}/
var websiteRegExp = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

const validationSchema = Yup.object({
    email: Yup.string().email("Votre E-mail n'est pas valide").required("Ce champ est requis"),
    firstName: Yup.string().required("Ce champ est requis"),
    lastName: Yup.string().required("Ce champ est requis"),
    password: Yup.string().required("Ce champ est requis"),
    phone: Yup.string().matches(phoneRegExp, "Votre numéro de téléphone n'est pas valide"),
    mobile: Yup.string().matches(phoneRegExp, "Votre numéro de téléphone n'est pas valide"),
    website: Yup.string().matches(websiteRegExp, "Votre site web n'est pas valide"),

});
class AddUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setSearchModalIsOpen: false,
            initialValues: {
                ...FIELDS,
                error: "",
            }
        };
    }
    openSearchModeal = () => {
        this.setState({
            error: "",
            setSearchModalIsOpen: true
        })
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        })
    }


    handleClick = () => this.setState(({ type }) => ({
        type: type === 'text' ? 'password' : 'text'
    }))
    onSubmit = (values, { setSubmitting }) => this.setState({ error: "" }, async () => {
        try {
            const data = {
                "data": {
                    "user": {
                        "isAdmin": values?.isAdmin ? values?.isAdmin ? 1 : 0 : 0,
                        "email": values?.email ?? null,
                        "password": values?.password ?? null,
                        "firstName": values?.firstName ?? null,
                        "lastName": values?.lastName ?? null,
                        "title": values?.title?.value ?? null,
                        "phone": values?.phone ?? null,
                        "mobile": values?.mobile ?? null,
                        "fax": values?.fax ?? null,
                        "website": values?.website ?? null,
                        "birthdate": values?.birthdate ? moment(values?.birthdate).format("YYYY-MM-DD") : null,
                        "address": values?.address ?? null,
                        "city_id": values?.city?.value ?? null,
                        "state": values?.state ?? null,
                        "postalCode": values?.postalCode ?? null,
                        "country": values?.country ?? null,
                    }
                }
            }
            const response = await usersDAO.add({}, data);
            this.closeSearchModal();
            // this.props.showMessage()
            this.props.refresh(true);
            notify({ msg: messageText?.Users?.add, type: "success" });
        } catch (error) {
            setSubmitting(false)
            this.setState({ error: error?.message })
        }
    })
    render() {
        const { initialValues } = this.state;
        const { error } = this.state;
        return (
            <div style={{ width: "45%" }} className="BtnSearchModalCountainer">
                <button type="button" onClick={this.openSearchModeal} className="btn btn-link"><BsPlus style={{ fontSize: '25px', }} />Nouvel utilisateur</button>
                <Modal
                    isOpen={this.state.setSearchModalIsOpen}
                    onAfterOpen={() => { }}
                    onRequestClose={this.closeSearchModal}
                    ariaHideApp={false}
                    style={{}}
                    className="NewUserModal"
                    contentLabel="Example Modal">
                    <div className="headerSearchModal">
                        <h2 style={{ fontSize: '20px' }} >Nouvel utilisateur</h2>
                        <button onClick={this.closeSearchModal}><IoIosClose color="rgb(255, 80, 80)" size={25} /></button>
                    </div>
                    <hr />
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={this.onSubmit}>
                        {/* login form */}
                        {
                            (formik) => {
                                return (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Nom  ici .." id="lastNameId" name="lastName" label="Nom" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Prénom ici …" id="firstNameId" name="firstName" label="Prénom" />
                                                <SelectInput
                                                    disabled={formik?.isSubmitting}
                                                    name="title"
                                                    id={"titleId"}
                                                    label="Titre"
                                                    placeholder="Sélectionner..."
                                                    value={formik?.values?.title}
                                                    onChange={(option) => { formik?.setFieldValue("title", option) }}
                                                    options={[{ label: "Femme", value: "female" }, { label: "Monsieur", value: "male" }]}
                                                />
                                                <Input disabled={formik?.isSubmitting} placeholder="Mobile ici …" id="mobileId" name="mobile" label="Mobile" />
                                                <DatePicker
                                                    disabled={formik?.isSubmitting}
                                                    placeholder="Date de naissance ici …"
                                                    selected={formik?.values?.birthdate}
                                                    onChange={(date) => {
                                                        formik?.setFieldValue("birthdate", date)
                                                    }}
                                                    label="Date de naissance"
                                                    id="birthdateId"
                                                    dateFormat="yyyy-MM-dd"
                                                    showTimeSelect={false}
                                                />
                                                <Input disabled={formik?.isSubmitting} type="textarea" placeholder="Address ici …" id="addressId" name="address" label="Address" />

                                                {/* <Input placeholder="Date de naissance ici …" id="birthdateId" name="birthdate" label="Date de naissance" required /> */}
                                            </Col>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Email ici …" id="emailId" name="email" label="Email" />
                                                <Input disabled={formik?.isSubmitting} type="password" placeholder="Mot de passe ici …" id="passwordId" name="password" label="Mot de passe" />
                                                <SelectInput
                                                    disabled={formik?.isSubmitting}
                                                    name="status"
                                                    id={"statusId"}
                                                    label="Statut"
                                                    placeholder="Sélectionner..."
                                                    value={formik?.values?.status}
                                                    onChange={(option) => { formik?.setFieldValue("status", option) }}
                                                    options={[{ label: "Active", value: "active" }, { label: "Inactif", value: "inactive" }]}
                                                />
                                                <Checkbox disabled={formik?.isSubmitting} checked={formik?.values?.isAdmin} onChange={() => { formik?.setFieldValue("isAdmin", !formik?.values?.isAdmin) }} label="Est Admin" id="isAdmin" />
                                            </Col>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Téléphone (fix) ici …" id="phoneId" name="phone" label="Téléphone (fix)" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Fax ici …" id="faxId" name="fax" label="Fax" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Site WEB ici …" id="websiteId" name="website" label="Site WEB" />
                                                <Input disabled={true} disable={true} placeholder="Pays ici …" id="countryId" name="country" label="Pays" />
                                                <SubRegionsSearchSelect
                                                    disabled={formik?.isSubmitting}
                                                    onChange={(selectedOption) => { formik?.setFieldValue("city", selectedOption) }}
                                                    value={formik?.values?.city}
                                                />
                                                <Input disabled={formik?.isSubmitting} placeholder="code postal ici …" id="postalId" name="postalCode" label="Code postal" />
                                                <Input disabled={formik?.isSubmitting} placeholder="State ici …" id="StateId" name="state" label="State" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ display: "flex", alignItems: "center", }}>
                                                <p className="modalErrorMessage">{error}</p>
                                            </Col>
                                            <Col>
                                                <Button disabled={formik?.isSubmitting} type="submit" variant="primary" className="NUsubmit" >{formik?.isSubmitting ? <HashLoader color={"#fff"} loading={true} size={22} /> : "Valider"}</Button>
                                            </Col>
                                        </Row>
                                    </Form>)
                            }
                        }
                    </Formik>
                    {/* <Row>
                            <Col>
                                <Form.Group controlId="formBasicNom">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control className="NUinput" placeholder="Nom  ici .." />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="formBasicPrenom">
                                    <Form.Label>Prénom</Form.Label>
                                    <Form.Control className="NUinput" placeholder="Prénom ici …" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className="NUinput" type="email" placeholder="Email ici …" />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Mot de passe</Form.Label>
                                    <Form.Control className="NUinput" type={this.state.type} placeholder="Mot de passe ici …" />
                                    <span className="password__show clickable" onClick={this.handleClick}>
                                        <IconContext.Provider value={{ color: "#E0E0E6" }}>
                                            <BsFillEyeFill />
                                        </IconContext.Provider>
                                    </span>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formGridState">
                                    <Form.Label>Statut</Form.Label>
                                    <Form.Control className="NUinput" as="select" defaultValue="Choose...">
                                        <option>Actif</option>
                                        <option>Inactif</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row> */}

                </Modal>
            </div>
        );
    }

}

const updateValidationSchema = Yup.object({
    email: Yup.string().email("Votre E-mail n'est pas valide").required("Ce champ est requis"),
    firstName: Yup.string().required("Ce champ est requis"),
    lastName: Yup.string().required("Ce champ est requis"),
    phone: Yup.string().matches(phoneRegExp, "Votre numéro de téléphone n'est pas valide"),
    mobile: Yup.string().matches(phoneRegExp, "Votre numéro de téléphone n'est pas valide"),
    website: Yup.string().matches(websiteRegExp, "Votre site web n'est pas valide"),

});
class UpdateUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setSearchModalIsOpen: false,
            initialValues: {
                ...FIELDS,
                error: "",
            }
        };
    }
    openSearchModeal = () => {
        this.setState({
            error: "",
            setSearchModalIsOpen: true
        })
        setTimeout(this.get, 300)
    }

    closeSearchModal = () => {
        this.setState({
            setSearchModalIsOpen: false
        })
    }
    get = async () => {
        const { id } = this.props;
        if (id) {
            try {
                const response = await usersDAO.getElementById(id, {});
                const data = response?.data?.data;
                const locationArrays = Store.getState().LOCATION;
                this.setState(prevState => ({
                    ...prevState,
                    initialValues: {
                        ...prevState?.initialValues,
                        "isAdmin": data?.isAdmin ? data?.isAdmin === "1" ? true : false : false,
                        "email": data?.email ?? "",
                        "firstName": data?.firstName ?? "",
                        "lastName": data?.lastName ?? "",
                        "title": data?.title === "male" ? { label: "Monsieur", value: "male" } : { label: "Femme", value: "female" },
                        "phone": data?.phone ?? "",
                        "mobile": data?.mobile ?? "",
                        "fax": data?.fax ?? "",
                        "website": data?.website ?? "",
                        "birthdate": data?.birthdate ? new Date(Date.parse(data?.birthdate)) : "",
                        "address": data?.address ?? "",
                        "city": data?.cityId ? locationArrays?.subRegions?.filter((item, index) => (parseInt(item?.id) === parseInt(data?.cityId))).map((item, index) => ({ value: item?.id, label: item?.name }))[0] : "",
                        "state": data?.state ?? "",
                        "postalCode": data?.postalCode ?? "",
                        "country": "MA",
                        "status": data?.status === "active" ? { label: "Active", value: "active" } : { label: "Inactif", value: "inactive" }
                    }
                }))
            } catch (error) {
                console.log(error)
            }
        }
    }
    componentDidMount() {

    }

    handleClick = () => this.setState(({ type }) => ({
        type: type === 'text' ? 'password' : 'text'
    }))
    onSubmit = (values, { setSubmitting }) => this.setState({ error: "" }, async () => {
        const { id } = this.props;
        if (id) {
            try {
                const data = {
                    "data": {
                        "user": {
                            "isAdmin": values?.isAdmin ? values?.isAdmin ? 1 : 0 : 0,
                            "email": values?.email ?? null,
                            "firstName": values?.firstName ?? null,
                            "lastName": values?.lastName ?? null,
                            "title": values?.title?.value ?? null,
                            "phone": values?.phone ?? null,
                            "mobile": values?.mobile ?? null,
                            "fax": values?.fax ?? null,
                            "website": values?.website ?? null,
                            "birthdate": values?.birthdate ? moment(values?.birthdate).format("YYYY-MM-DD") : null,
                            "address": values?.address ?? null,
                            "cityId": values?.city?.value ?? null,
                            "state": values?.state ?? null,
                            "postalCode": values?.postalCode ?? null,
                            "country": values?.country ?? null,
                        }
                    }
                }
                const response = await usersDAO.update(id, {}, data);
                this.closeSearchModal();
                // this.props.showMessage()
                this.props.refresh(true);
                notify({ msg: messageText?.Users?.update, type: "success" });
            } catch (error) {
                setSubmitting(false)
                this.setState({ error: error?.message })
            }
        }
    });

    render() {
        const { initialValues } = this.state;
        const { error } = this.state;

        return (
            <div className="BtnUserModalCountainer">
                <IconContext.Provider value={{ color: "#63C2D1", size: '15px' }}>
                    <button onClick={this.openSearchModeal} className="editUserTable clickable"><FiEdit /></button>
                </IconContext.Provider>
                <Modal
                    isOpen={this.state.setSearchModalIsOpen}
                    onAfterOpen={() => { }}
                    onRequestClose={this.closeSearchModal}
                    ariaHideApp={false}
                    style={{}}
                    className="NewUserModal"
                    contentLabel="Example Modal">

                    <div className="headerSearchModal">
                        <h2 style={{ fontSize: '20px' }} ></h2>
                        <button onClick={this.closeSearchModal}><IoIosClose color="rgb(255, 80, 80)" size={25} /></button>
                    </div>
                    <hr />
                    <Formik enableReinitialize initialValues={this.state.initialValues} validationSchema={updateValidationSchema} onSubmit={this.onSubmit}>
                        {/* login form */}
                        {
                            (formik) => {
                                return (
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Nom  ici .." id="lastNameId" name="lastName" label="Nom" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Prénom ici …" id="firstNameId" name="firstName" label="Prénom" />
                                                <SelectInput
                                                    disabled={formik?.isSubmitting}
                                                    name="title"
                                                    id={"titleId"}
                                                    label="Titre"
                                                    placeholder="Sélectionner..."
                                                    value={formik?.values?.title}
                                                    onChange={(option) => { formik?.setFieldValue("title", option) }}
                                                    options={[{ label: "Femme", value: "female" }, { label: "Monsieur", value: "male" }]}
                                                />
                                                <Input disabled={formik?.isSubmitting} placeholder="Mobile ici …" id="mobileId" name="mobile" label="Mobile" />
                                                <DatePicker
                                                    disabled={formik?.isSubmitting}
                                                    placeholder="Date de naissance ici …"
                                                    selected={formik?.values?.birthdate}
                                                    onChange={(date) => {
                                                        formik?.setFieldValue("birthdate", date)
                                                    }}
                                                    label="Date de naissance"
                                                    id="birthdateId"
                                                    dateFormat="yyyy-MM-dd"
                                                    showTimeSelect={false}
                                                />
                                                <Input disabled={formik?.isSubmitting} type="textarea" placeholder="Address ici …" id="addressId" name="address" label="Address" />

                                                {/* <Input placeholder="Date de naissance ici …" id="birthdateId" name="birthdate" label="Date de naissance" required /> */}
                                            </Col>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Email ici …" id="emailId" name="email" label="Email" />
                                                {/* <Input disabled={formik?.isSubmitting} type="password" placeholder="Mot de passe ici …" id="passwordId" name="password" label="Mot de passe" /> */}
                                                <Checkbox disabled={formik?.isSubmitting} checked={formik?.values?.isAdmin} onChange={() => { formik?.setFieldValue("isAdmin", !formik?.values?.isAdmin) }} label="Est Admin" id="isAdmin" />
                                            </Col>
                                            <Col>
                                                <Input disabled={formik?.isSubmitting} placeholder="Téléphone (fix) ici …" id="phoneId" name="phone" label="Téléphone (fix)" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Fax ici …" id="faxId" name="fax" label="Fax" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Site WEB ici …" id="websiteId" name="website" label="Site WEB" />
                                                <Input disabled={formik?.isSubmitting} placeholder="Pays ici …" id="countryId" name="country" label="Pays" />
                                                <SubRegionsSearchSelect
                                                    disabled={formik?.isSubmitting}
                                                    onChange={(selectedOption) => { formik?.setFieldValue("city", selectedOption) }}
                                                    value={formik?.values?.city}
                                                />
                                                <Input disabled={formik?.isSubmitting} placeholder="code postal ici …" id="postalId" name="postalCode" label="Code postal" />
                                                <Input disabled={formik?.isSubmitting} placeholder="State ici …" id="StateId" name="state" label="State" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ display: "flex", alignItems: "center", }}>
                                                <p className="modalErrorMessage">{error}</p>
                                            </Col>
                                            <Col>
                                                <Button disabled={formik?.isSubmitting} type="submit" variant="primary" className="NUsubmit" >{formik?.isSubmitting ? <HashLoader color={"#fff"} loading={true} size={22} /> : "Valider"}</Button>
                                            </Col>
                                        </Row>                                    </Form>)
                            }
                        }
                    </Formik>

                </Modal>
            </div>
        );
    }

}
