import React, { Component } from 'react';
import Modal from 'react-modal';
import "./SavedSearchForm.css";
import { GrClose } from "react-icons/gr";
import { IconContext } from "react-icons";
import Input from '../input';
import { Form, } from 'react-bootstrap'
import * as Yup from "yup";
import HashLoader from "react-spinners/HashLoader";
import savedSearchService from '../../services/savedSearchService';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import messageText from '../../values/messageText';
import { notify } from '../flashMessage/FlashMessage';
import { refreshSavedSearch } from '../DAsearch/DAsearch';
import { refreshSavedSearchComparison } from '../DAsearch/ComparisonDAsearch';

const initialValues = {

}
const validationSchema = Yup.object({

});

class SavedSearchForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            savedSearchLabelError: null,
        };
    }
    closeSearchModal = () => {
        this.setState({ open: false, })
    }

    onSubmit = () => {
        const { label } = this.props;
        if (label && label.length) {
            this.setState({ loading: true });
            switch (this.props.type) {
                case "comparison":
                    savedSearchService.saveComparisonSearch(() => {
                        refreshSavedSearchComparison();
                        notify({ msg: messageText?.Common?.addSavedSearch, type: "success" });
                        this.setState({ loading: false });
                        this.closeSearchModal();
                    });
                    break;
                case "normal":
                    savedSearchService.saveMainSearch(() => {
                        refreshSavedSearch();
                        notify({ msg: messageText?.Common?.addSavedSearch, type: "success" });
                        this.setState({ loading: false });
                        this.closeSearchModal();
                    });
                    break;
                case "marketView":
                    savedSearchService.saveMarketViewSearch(() => {
                        refreshSavedSearch();
                        notify({ msg: messageText?.Common?.addSavedSearch, type: "success" });
                        this.setState({ loading: false });
                        this.closeSearchModal();
                    });
                    break;
                default:
                    savedSearchService.saveMainSearch(() => {
                        refreshSavedSearch();
                        notify({ msg: messageText?.Common?.addSavedSearch, type: "success" });
                        this.setState({ loading: false });
                        this.closeSearchModal();
                    });
                    break;
            }


        } else {
            if (!label || label.length === 0) {
                this.setState({ savedSearchLabelError: { message: messageText?.Home?.searchRequiredItem } })
            }
        }
    }
    selectSavedSearchLabel = (selectedOption) => {
        const { savedSearchLabelError } = this.state;
        if (savedSearchLabelError) this.setState({ savedSearchLabelError: null })
        this.props.dispatch({ type: "SET_SAVED_SEARCH_LABEL", value: { label: selectedOption.target.value } })
    }

    render() {
        const { open, loading, savedSearchLabelError } = this.state;
        const { label } = this.props;
        return (
            <div>
                <button onClick={() => this.setState({ open: true }, () => this.props.dispatch({ type: "RESET_SAVED_SEARCH", value: {} }))} id="saveSearch" type="button">Enregister cette recherche</button>
                <Modal
                    isOpen={open}
                    onAfterOpen={() => { }}
                    onRequestClose={this.closeSearchModal}
                    ariaHideApp={false}
                    style={{}}
                    className="SavedSearchForm"
                    contentLabel="Example Modal">
                    <div className="headerSearchModal">
                        <h2 >Enregistrer la recherche</h2>
                        <IconContext.Provider value={{ color: "#FF5050", className: "closeBtnSvg" }}>
                            <button onClick={this.closeSearchModal}>
                                <GrClose />
                            </button>
                        </IconContext.Provider>
                    </div>
                    <hr />

                    <div className="SavedSearch__form" onSubmit={this.onKeyPress}>
                        <Form.Group controlId="formBasicNom">
                            <Form.Label>Donnez un nom à votre recherche</Form.Label>
                            <input onChange={this.selectSavedSearchLabel} value={label} placeholder="Nom de la recherche…" id="searchLabelId" name="searchLabel" className={`input NUinput`} />
                            {savedSearchLabelError && <span className="SelectItemsInput__error">{savedSearchLabelError?.message ?? ""}</span>}
                        </Form.Group>
                    </div>
                    <div>
                        <button disabled={loading} onClick={this.onSubmit} id="submit">{loading ? <HashLoader color={"#fff"} loading={true} size={20} /> : "Enregistrer"}</button>
                    </div>

                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return ({
        "report": state.SAVED_SEARCH.report,
        "label": state.SAVED_SEARCH.label,
    })
}

export default connect(mapStateToProps)(withRouter(SavedSearchForm));
