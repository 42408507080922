import React, { Component } from "react";


import ee from "event-emitter";
import FlashMsg from "../DAbody/Users/DAuserFlashMsg/FlashMsg";

const emitter = new ee();

export const notify = ({ msg, type }) => {
    emitter.emit("notification", { msg, type });
};
class FlashMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: 0,
            message: { msg: "", type: "" },
        };
        this.timeout = null;
        emitter.on("notification", ({ msg, type }) => {
            this.onShow({ msg, type });
        });
    }
    componentDidMount() { }

    onShow({ msg, type }) {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.setState({ show: false }, () => {
                this.timeout = setTimeout(() => {
                    this.showNotification({ msg, type });
                }, 300);
            });
        } else {
            this.showNotification({ msg, type });
        }
    }
    showNotification = ({ msg, type }) => {
        this.setState(
            {
                show: true,
                message: { msg: typeof msg === "string" ? msg : "Dev error", type },
            },
            () => {
                this.timeout = setTimeout(() => {
                    this.setState({ show: false });
                }, 5000);
            }
        );
    };
    render() {
        const { show, message } = this.state;
        return show ? <FlashMsg message={message?.msg} /> : <div></div>
    }
}

export default FlashMessage;
