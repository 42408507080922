import React, { Component } from 'react';
import SearchSelect from '../searchSelect';
import { Form } from 'react-bootstrap'
import "./style.css";
import { connect } from 'react-redux';
import homeDAO from '../../dao/homeDAO';
import { StartSortGlobalStrings } from '../../helpers/sort';

const INITIAL_FIELDS = {
    data: [],
    loading: false,
}
class RegionsSearchSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_FIELDS,
        };
    }
    componentDidMount() {
        this.preload();
    }
    preload = () => {
        this.getAll();
    }
    render() {
        const { data, value } = this.state;
        const { searchItems, onChange } = this.props;
        return (
            <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Région</Form.Label>
                <SearchSelect
                    name="RegionsSearchSelect"
                    options={StartSortGlobalStrings(data?.map((item, index) => ({ value: item?.id, label: item?.name ?? "" })), "label", "ASC")}
                    defaultValue={searchItems?.location?.region}
                    value={searchItems?.location?.region}
                    onChange={onChange}
                />
            </Form.Group>
        );
    }
    getAll = async () => {
        try {
            let params = {};
            this.setState({ loading: true })
            const response = await homeDAO.getRegionsList(params);
            this.setState({ loading: false })
            this.setState({ data: response?.data?.data ?? [] })
        } catch (error) {

            this.setState({ loading: false })
        }
    }
}


const mapStateToProps = (state) => {
    return ({
        searchItems: state.SEARCH_ITEMS.searchItems
    })
}

export default connect(mapStateToProps)(RegionsSearchSelect);

